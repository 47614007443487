import { type Address } from "viem";
import { Box, Flex, HStack } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { Button, EthTokenIcon, InformationCircleIcon, NumberInput, Popover, Text, TooltipText } from "@looksrare/uikit";
import type { Currency, LiquidityPoolContract } from "@looksrare/yolo-games-gql-typegen/src/generated/gql/graphql";
import {
  type BigIntish,
  formatToSignificant,
  formatUsd,
  useGetCurrencyConfig,
  toDecimals,
  multiplyWeiByNumber,
  fromDecimals,
  useDebouncedValue,
} from "@looksrare/utils";
import { lpConfig } from "@/config";
import { useGetTokenBalanceInfoForLP, useUserLiquidityPoolActionPreview } from "@/views/Liquidity/hooks";
import { DepositFeeTooltipText, SlippageTooltipText } from "../../TooltipText";
import { useDepositWithdrawStore } from "./store";
import { DropletEstimateDisplay, Info, InfoTitle } from "./shared";
import { useInputValidation } from "./hooks";

interface DepositInputViewProps {
  isEthPool: boolean;
  connectedAddress: Address;
  currency: Currency;
  contract: LiquidityPoolContract;
  dailyDroplets?: BigIntish;
  depositFeeBps: number;
  minDepositVum: BigIntish;
  maxDepositVum: BigIntish;
  assetsBalanceVum: BigIntish;
  pendingDepositsAmountVum: BigIntish;
  assetsLimitVum: BigIntish;
  finalizationIncentiveEthWei: BigIntish;
}

export const DepositInputView = ({
  isEthPool,
  connectedAddress,
  currency,
  contract,
  dailyDroplets,
  depositFeeBps,
  minDepositVum,
  maxDepositVum,
  assetsBalanceVum,
  pendingDepositsAmountVum,
  assetsLimitVum,
  finalizationIncentiveEthWei,
}: DepositInputViewProps) => {
  const { t } = useTranslation();

  const [inputValue, setInputValue, startDepositGrantApproval] = useDepositWithdrawStore((state) => [
    state.inputValue,
    state.setInputValue,
    state.startDepositGrantApproval,
  ]);
  const getCurrencyConfig = useGetCurrencyConfig();
  const { icon: Icon = EthTokenIcon } = getCurrencyConfig(currency.address);

  const { isLoading, tokenBalanceVum, tokenBalanceUsd } = useGetTokenBalanceInfoForLP(
    isEthPool,
    currency.address,
    currency.decimals
  );

  const inputVum = !!inputValue ? toDecimals(inputValue, currency.decimals) : 0n;
  const feeDisplayAsPercentage = depositFeeBps / 10_000;
  const feeVum = multiplyWeiByNumber(inputVum, feeDisplayAsPercentage);

  const assetLimit = BigInt(assetsLimitVum || 0n);
  const assetBalance = BigInt(assetsBalanceVum || 0n);
  const pendingDepositsAmount = BigInt(pendingDepositsAmountVum || 0n);
  const remainingDepositableBalance = assetLimit - (assetBalance + pendingDepositsAmount);

  const { isValidInput, warningText } = useInputValidation({
    inputValueVum: inputVum,
    minVum: minDepositVum,
    maxVum: maxDepositVum,
    assetsLimitVum,
    pendingDepositsAmountVum,
    assetsBalanceVum,
    tokenBalanceVum,
    tokenDecimals: currency.decimals,
    tokenSymbol: currency.symbol,
    displayDecimals: lpConfig[contract].displayDecimals,
  });

  const debouncedInputValue = useDebouncedValue(inputVum, 500);
  const actionPreviewQuery = useUserLiquidityPoolActionPreview({
    address: connectedAddress,
    contract,
    actionType: "DEPOSIT",
    assetsAmountVum: debouncedInputValue,
    enabled: inputValue !== "",
  });
  const current24hDroplets = !!dailyDroplets ? Number(dailyDroplets) : 0;
  const estimated24hDroplets = (() => {
    if (actionPreviewQuery.isSuccess) {
      return Number(actionPreviewQuery.data?.dailyDroplets);
    }
    return current24hDroplets;
  })();

  const handleSetMaxValue = () => {
    if (isEthPool) {
      // Subtract the incentive from the max amount they can enter
      const finalizationIncentiveEthFee = BigInt(finalizationIncentiveEthWei);
      const adjustedTokenBalance = multiplyWeiByNumber(tokenBalanceVum, 0.9);
      setInputValue(fromDecimals(adjustedTokenBalance - finalizationIncentiveEthFee, { decimals: currency.decimals }));
      return;
    }
    setInputValue(fromDecimals(tokenBalanceVum, { decimals: currency.decimals }));
  };

  const handleInputChange = (newValue: string) => {
    setInputValue(newValue);
  };

  const handleClick = async () => {
    startDepositGrantApproval();
  };

  return (
    <Box>
      <Box position="relative" height={12} mb={4}>
        <Box position="absolute" left={4} top="50%" transform="translateY(-50%)">
          <Icon boxSize={5} />
        </Box>
        <NumberInput size="lg" value={inputValue} onTextChange={handleInputChange} px="52px" />
        <Button
          size="xs"
          variant="ghost"
          color="white"
          position="absolute"
          right={4}
          top="50%"
          transform="translateY(-50%)"
          onClick={handleSetMaxValue}
          isLoading={isLoading}
          isDisabled={tokenBalanceVum === 0n}
        >
          {t("Max")}
        </Button>
      </Box>
      {!!warningText && (
        <Text textStyle="detail" color="text-error" pb={2}>
          {warningText}
        </Text>
      )}
      <Info>
        <InfoTitle>{t("In Wallet")}</InfoTitle>
        <Box>
          <HStack spacing={2} justifyContent="end">
            <Text textStyle="detail">
              {formatToSignificant(tokenBalanceVum, lpConfig[contract].displayDecimals, {
                decimals: currency.decimals,
              })}
            </Text>
            <Icon boxSize={4} />
          </HStack>
          {isEthPool && (
            <Text textStyle="helper" color="text-03" textAlign="right">
              {formatUsd(tokenBalanceUsd)}
            </Text>
          )}
        </Box>
      </Info>
      <Info borderBottom="1px solid" borderBottomColor="border-01">
        <InfoTitle>{t("liq::Est. {{timePeriodDisplay}} Droplets", { timePeriodDisplay: "24h" })}</InfoTitle>
        <DropletEstimateDisplay
          current24hDroplets={current24hDroplets}
          estimated24hDroplets={estimated24hDroplets}
          isLoading={actionPreviewQuery.isFetching}
        />
      </Info>
      <Info borderBottom="1px solid" borderBottomColor="border-01">
        <Popover
          variant="tooltip"
          label={
            <TooltipText>
              <Flex justifyContent="space-between">
                <span>{t("liq::Max Total {{symbol}} in Pool", { symbol: currency.symbol })}:</span>
                <Box textAlign="right" pl={4}>
                  {formatToSignificant(assetLimit, lpConfig[contract].displayDecimals, {
                    decimals: currency.decimals,
                  })}
                </Box>
              </Flex>
              <Flex justifyContent="space-between">
                <span>{t("liq::Current Total", { symbol: currency.symbol })}:</span>
                <Box textAlign="right" pl={4}>
                  {formatToSignificant(assetBalance, lpConfig[contract].displayDecimals, {
                    decimals: currency.decimals,
                  })}
                </Box>
              </Flex>
            </TooltipText>
          }
        >
          <HStack>
            <InfoTitle>{t("liq::Max Deposit")}</InfoTitle>
            <InformationCircleIcon boxSize={4} color="text-03" />
          </HStack>
        </Popover>
        <HStack spacing={2} justifyContent="end">
          <Text textStyle="detail">
            {formatToSignificant(remainingDepositableBalance, lpConfig[contract].displayDecimals, {
              decimals: currency.decimals,
            })}
          </Text>
          <Icon boxSize={4} />
        </HStack>
      </Info>
      <Info>
        <Popover variant="tooltip" label={<DepositFeeTooltipText feeDisplay={`${feeDisplayAsPercentage * 100}%`} />}>
          <HStack>
            <InfoTitle>{t("liq::Deposit Fee ({{fee}})", { fee: `${feeDisplayAsPercentage * 100}%` })}</InfoTitle>
            <InformationCircleIcon boxSize={4} color="text-03" />
          </HStack>
        </Popover>
        <Box>
          <HStack spacing={2} justifyContent="end">
            <Text textStyle="detail">{`-${formatToSignificant(feeVum, lpConfig[contract].displayDecimals, {
              decimals: currency.decimals,
            })}`}</Text>
            <Icon boxSize={4} />
          </HStack>
        </Box>
      </Info>
      <Info mb={4}>
        <Popover variant="tooltip" label={<SlippageTooltipText />}>
          <HStack>
            <InfoTitle>{t("liq::Est. Total Deposit")}</InfoTitle>
            <InformationCircleIcon boxSize={4} color="text-03" />
          </HStack>
        </Popover>
        <Box>
          <HStack spacing={2} justifyContent="end">
            <Text textStyle="detail">
              {formatToSignificant(inputVum - feeVum, lpConfig[contract].displayDecimals, {
                decimals: currency.decimals,
              })}
            </Text>
            <Icon boxSize={4} />
          </HStack>
        </Box>
      </Info>
      <Button isDisabled={!isValidInput} isLoading={isLoading} width="100%" onClick={handleClick}>
        {t("Deposit")}
      </Button>
    </Box>
  );
};
