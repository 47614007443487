import { FC, PropsWithChildren } from "react";
import { useTranslation } from "next-i18next";
import { Address } from "viem";
import {
  Box,
  Center,
  Flex,
  FlexProps,
  Grid,
  GridItem,
  GridProps,
  HStack,
  IconButton,
  Skeleton,
  SkeletonCircle,
} from "@chakra-ui/react";
import {
  BackToStartIcon,
  Chevron,
  JumpToEndIcon,
  VerifiedIcon,
  UsernameDisplay,
  Text,
  ProfileAvatar,
} from "@looksrare/uikit";
import { useAnimatedLayerStyle } from "@looksrare/utils";
import { ImageData } from "@/types";
import { LeaderboardSortInput } from "@/queries/points";
import { Rank, RankBackground } from "@/views/Rewards/components/rank";

/**
 * @note Despite looking like a sort in the UI, the ButtonToggle affects which query we use to fetch data.
 * When it is a LeaderboardSortInput, we use the season leaderboard. If "EPOCH", we use the epoch leaderboard with a default sort applied by BE.
 */
export enum LeaderboardOption {
  SEASON_POINTS_DAILY_RANK_ASC,
  SEASON_POINTS_RANK_ASC,
  EPOCH,
}

/**
 * Table grid definitions for Header & Row
 */
const headerTemplateAreas = {
  base: "'rank avatar points'",
  lg: "'rank avatar dailyPoints points'",
};
const headerTemplateColumns = {
  base: "24px 1fr 1fr",
  lg: "50px 240px 2fr 1fr",
};

// The flipped "dailyPoints" and "points" gridAreas are unfortunate. The alternative is to have a messier jsx structure, where the
// "top-right" and "bottom-right" cells are conditionally displaying points or 24h points & vice versa.
// The intent is to keep the jsx shared between desktop, mobile-24h-sort, and mobile-total-sort. On mobile, the "primary" points value is
// in the top-right, and the "secondary" points value is in the bottom-right with an additional text descriptor.
const rowTemplateAreas = (sort: LeaderboardSortInput = LeaderboardSortInput.DAILY_RANK_ASC) => ({
  base: `'rank avatar name ${
    sort === LeaderboardSortInput.DAILY_RANK_ASC ? "dailyPoints" : "points"
  }' 'rank avatar name ${sort === LeaderboardSortInput.DAILY_RANK_ASC ? "points" : "dailyPoints"}'`,
  lg: "'rank avatar name dailyPoints points'",
});
const rowTemplateColumns = {
  base: "32px 32px 1fr 1fr",
  lg: "50px 40px 192px 2fr 1fr",
};

const hideOnMobile = {
  base: "none",
  lg: "block",
};

const hideOnDesktop = {
  base: "block",
  lg: "none",
};

export const Header = () => {
  const { t } = useTranslation();
  return (
    <Grid
      gridTemplateAreas={headerTemplateAreas}
      gridTemplateColumns={headerTemplateColumns}
      gridColumnGap={2}
      width="100%"
      p={4}
    >
      <GridItem gridArea="rank">
        <Text textStyle="detail" color="text-03" width="100%" textAlign="center">
          #
        </Text>
      </GridItem>
      <GridItem gridArea="avatar">
        <Text textStyle="detail" color="text-03">
          {t("Profile")}
        </Text>
      </GridItem>

      <GridItem display={hideOnMobile} gridArea="dailyPoints">
        <Text textStyle="detail" color="text-03" textAlign="right">
          {t("24h Points")}
        </Text>
      </GridItem>
      <GridItem display={hideOnMobile} gridArea="points" pr={4}>
        <Text textStyle="detail" color="text-03" textAlign="right">
          {t("Total Points")}
        </Text>
      </GridItem>

      {/* Mobile only */}
      <GridItem gridArea="points" display={hideOnDesktop}>
        <Text textStyle="detail" color="text-03" textAlign="right">
          {t("Points")}
        </Text>
      </GridItem>
    </Grid>
  );
};

const RowDataCell: FC<PropsWithChildren<FlexProps>> = ({ children, ...props }) => {
  return (
    <Flex alignItems="center" height="100%" width="100%" {...props}>
      {children}
    </Flex>
  );
};

interface RowProps extends GridProps {
  rank?: number;
  accountAddress: Address;
  pointsTotal: string;
  points24h: string;
  isConnectedAccount?: boolean;
  name?: string;
  addressIsVerified?: boolean;
  image?: ImageData;
  isMobile: boolean;
  sort: LeaderboardSortInput;
  isAvatarPrivate?: boolean;
}
export const Row = ({
  rank,
  accountAddress,
  pointsTotal,
  points24h,
  isConnectedAccount = false,
  name,
  addressIsVerified = false,
  image,
  isMobile,
  sort,
  isAvatarPrivate = false,
  ...props
}: RowProps) => {
  const { t } = useTranslation();

  const isTopThreeRank = !!rank && rank <= 3;
  const pointsTotalAnimatedLayerStyle = useAnimatedLayerStyle(Number(pointsTotal));
  const points24hAnimatedLayerStyle = useAnimatedLayerStyle(Number(points24h));

  // the text content and style of the "points" and "dailyPoints" cells shift depending on the sort when in mobile
  const points24hText = (() => {
    if (!isMobile) {
      return points24h;
    }
    return sort === LeaderboardSortInput.DAILY_RANK_ASC ? points24h : t("24h: {{value}}", { value: points24h });
  })();
  const pointsTotalText = (() => {
    if (!isMobile) {
      return pointsTotal;
    }
    return sort === LeaderboardSortInput.SEASON_RANK_ASC ? pointsTotal : t("Total: {{value}}", { value: pointsTotal });
  })();
  const primaryTextStyle = {
    textStyle: "body",
    bold: true,
    color: "text-01",
  };
  const secondaryTextStyle = {
    textStyle: "helper",
    bold: false,
    color: "text-03",
  };

  return (
    <Grid
      gridTemplateAreas={rowTemplateAreas(sort)}
      gridTemplateColumns={rowTemplateColumns}
      gridColumnGap={{ base: 3, lg: 2 }}
      py={isTopThreeRank ? 6 : 2}
      layerStyle="clickable"
      px={4}
      position="relative"
      overflow="hidden"
      {...props}
    >
      {isTopThreeRank && (
        <Box position="absolute" top="-256px" left="-256px">
          <RankBackground rank={rank} />
        </Box>
      )}
      <GridItem gridArea="rank">
        <RowDataCell>
          <Rank rank={rank} textProps={primaryTextStyle} />
        </RowDataCell>
      </GridItem>

      <GridItem gridArea="avatar">
        <Flex alignItems="center" height="100%">
          <ProfileAvatar
            src={image?.src}
            address={accountAddress}
            size={{ base: 32, md: 40 }}
            isPrivate={isAvatarPrivate}
          />
        </Flex>
      </GridItem>

      <GridItem gridArea="name">
        <RowDataCell>
          {isConnectedAccount ? (
            <Text color="text-01" bold>
              {t("You")}
            </Text>
          ) : (
            <UsernameDisplay
              address={accountAddress}
              name={name}
              textStyle={{ base: "detail", lg: "body" }}
              bold
              noOfLines={1}
            />
          )}
          {addressIsVerified && <VerifiedIcon boxSize={4} ml={1} />}
        </RowDataCell>
      </GridItem>

      {/* this gridArea is the "top-right" on mobile */}
      <GridItem gridArea="dailyPoints">
        <RowDataCell justifyContent="flex-end">
          <Text
            textStyle={{ base: "helper", lg: "body" }}
            bold
            color={{ base: "text-03", lg: "text-01" }}
            textAlign="right"
            layerStyle={points24hAnimatedLayerStyle}
            {...(isMobile && (sort === LeaderboardSortInput.DAILY_RANK_ASC ? primaryTextStyle : secondaryTextStyle))}
          >
            {points24hText}
          </Text>
        </RowDataCell>
      </GridItem>

      {/* this gridArea is the "bottom-right" on mobile */}
      <GridItem gridArea="points" pr={{ base: 0, lg: 4 }}>
        <RowDataCell justifyContent="flex-end">
          <Text
            textAlign="right"
            bold
            layerStyle={pointsTotalAnimatedLayerStyle}
            {...(isMobile && (sort === LeaderboardSortInput.DAILY_RANK_ASC ? secondaryTextStyle : primaryTextStyle))}
          >
            {pointsTotalText}
          </Text>
        </RowDataCell>
      </GridItem>
    </Grid>
  );
};

export const SkeletonRow = () => {
  return (
    <Grid
      gridTemplateAreas={rowTemplateAreas()}
      gridTemplateColumns={rowTemplateColumns}
      gridColumnGap={{ base: 3, lg: 2 }}
      py={2}
      layerStyle="clickable"
      px={4}
    >
      <GridItem gridArea="rank">
        <RowDataCell justifyContent="center">
          <Skeleton width="50%" height="50%" />
        </RowDataCell>
      </GridItem>

      <GridItem gridArea="avatar">
        <Flex alignItems="center" height="100%">
          <SkeletonCircle size={{ base: "32px", lg: "40px" }} />
        </Flex>
      </GridItem>

      <GridItem gridArea="name" alignItems="center" display="flex">
        <Skeleton width="60%" height="50%" />
      </GridItem>

      <GridItem gridArea="dailyPoints">
        <RowDataCell justifyContent="flex-end">
          <Skeleton height="50%" width={{ base: "50%", lg: "35%" }} />
        </RowDataCell>
      </GridItem>

      <GridItem gridArea="points">
        <RowDataCell justifyContent="flex-end" pr={{ base: 0, lg: 4 }}>
          <Skeleton height="50%" width={{ base: "30%", lg: "40%" }} />
        </RowDataCell>
      </GridItem>
    </Grid>
  );
};

interface FooterProps extends FlexProps {
  currentPageNumber: number;
  totalPagesNumber: number;
  goToPage: (pageNumber: number) => void;
}

export const Footer = ({ currentPageNumber, totalPagesNumber, goToPage, ...props }: FooterProps) => {
  const { t } = useTranslation();
  return (
    <Center {...props}>
      <HStack spacing={1} py={3}>
        <IconButton
          aria-label="season-leaderboard-first-page"
          data-id="season-leaderboard-first-page"
          size="sm"
          variant="outline"
          colorScheme="secondary"
          isDisabled={currentPageNumber === 1}
          onClick={() => goToPage(1)}
        >
          <BackToStartIcon />
        </IconButton>
        <IconButton
          aria-label="season-leaderboard-prev-page"
          data-id={`season-leaderboard-to-prev-page-${currentPageNumber - 1}`}
          size="sm"
          variant="outline"
          colorScheme="secondary"
          isDisabled={currentPageNumber === 1}
          onClick={() => goToPage(currentPageNumber - 1)}
        >
          <Chevron direction="left" />
        </IconButton>
        <Text color="text-03" textStyle="detail" px={1}>
          {t("Page {{currentPage}} of {{totalPages}}", {
            currentPage: currentPageNumber,
            totalPages: totalPagesNumber,
          })}
        </Text>
        <IconButton
          aria-label="season-leaderboard-next-page"
          data-id={`season-leaderboard-to-next-page-${currentPageNumber + 1}`}
          size="sm"
          variant="outline"
          colorScheme="secondary"
          isDisabled={currentPageNumber === totalPagesNumber}
          onClick={() => goToPage(currentPageNumber + 1)}
        >
          <Chevron direction="right" />
        </IconButton>
        <IconButton
          aria-label="season-leaderboard-last-page"
          data-id="season-leaderboard-last-page"
          size="sm"
          variant="outline"
          colorScheme="secondary"
          isDisabled={currentPageNumber === totalPagesNumber}
          onClick={() => goToPage(totalPagesNumber)}
        >
          <JumpToEndIcon />
        </IconButton>
      </HStack>
    </Center>
  );
};
