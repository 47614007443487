import { useEffect } from "react";
import { usePublicClient, useWalletClient } from "wagmi";
import { useTranslation } from "next-i18next";
import { Box } from "@chakra-ui/react";
import { type BigIntish, NoPublicClientError, sleep } from "@looksrare/utils";
import { Button, Text, TransactionStep, TransactionStepRow, useHandleModalStep } from "@looksrare/uikit";
import type { Currency, LiquidityPoolContract } from "@looksrare/yolo-games-gql-typegen/src/generated/gql/graphql";
import { LiquidityPoolRouterAbi } from "@looksrare/config";
import { getDefaultChain } from "@/utils";
import { gameAddresses } from "@/config";
import { DepositWithdrawStep } from "../types";
import { useDepositWithdrawStore } from "../store";
import { FinalizationAlert } from "../FinalizationAlert";

interface FinalizeWithdrawalStepProps {
  contract: LiquidityPoolContract;
  currency: Currency;
  finalizationIncentiveEthWei: BigIntish;
  onFinalizeSuccess: () => void;
}

export const FinalizeWithdrawalStep = ({
  contract,
  currency,
  finalizationIncentiveEthWei,
  onFinalizeSuccess,
}: FinalizeWithdrawalStepProps) => {
  const { t } = useTranslation();
  const [step, inputValue, stepStatus, startInitiateWithdraw, setFailStatus, confirmWithdraw] = useDepositWithdrawStore(
    (state) => [
      state.step,
      state.inputValue,
      state.stepStatus[DepositWithdrawStep.FINALIZE_WITHDRAWAL],
      state.startWithdrawalFinalization,
      state.setFailStatus,
      state.confirmWithdraw,
    ]
  );

  const { data: walletClient } = useWalletClient();
  const publicClient = usePublicClient();

  const finalizeWithdraw = useHandleModalStep({
    onSubmit: async () => {
      if (!walletClient) {
        throw Error("No wallet client found");
      }
      if (!publicClient) {
        throw NoPublicClientError;
      }
      startInitiateWithdraw();

      const [account] = await walletClient.getAddresses();
      const chainId = getDefaultChain();
      const addresses = gameAddresses[chainId];

      const { request } = await publicClient.simulateContract({
        address: addresses.LIQUIDITY_POOL_ROUTER,
        abi: LiquidityPoolRouterAbi,
        functionName: "finalizeRedemption",
        args: [account],
        account,
      });
      const hash = await walletClient.writeContract(request);
      await publicClient.waitForTransactionReceipt({ hash });
    },
    onSuccess: async () => {
      await sleep(2_000);
      onFinalizeSuccess();
      confirmWithdraw();
    },
    onError: () => {
      setFailStatus(DepositWithdrawStep.FINALIZE_WITHDRAWAL);
    },
  });

  const handleRetryFinalizeWithdraw = () => {
    finalizeWithdraw.handleSubmit();
  };

  useEffect(() => {
    if (step === DepositWithdrawStep.FINALIZE_WITHDRAWAL) {
      finalizeWithdraw.handleSubmit({ callOnlyOnce: true });
    }
  }, [step, finalizeWithdraw, inputValue]);

  return (
    <TransactionStep
      status={stepStatus.timingStatus}
      title={t("liq::Finalize & Reclaim Fee")}
      width="100%"
      collapse={stepStatus.timingStatus !== "current"}
      bodyWrapperProps={{ pl: 0, pt: 0 }}
    >
      <Box py={6} px={4} bg="ui-01" borderRadius="container">
        <Text textStyle="detail" mb={4}>
          {t("Waiting for you to confirm in wallet")}
        </Text>
        <TransactionStepRow
          status={stepStatus.status}
          transactionHash={stepStatus.hash}
          text={t("liq::Finalize Withdrawal ")}
          mb={2}
        />
        {stepStatus.hasError && (
          <Button width="100%" size="sm" mt={4} onClick={handleRetryFinalizeWithdraw}>
            {t("Retry")}
          </Button>
        )}
      </Box>
      <FinalizationAlert
        contract={contract}
        finalizationIncentiveEthWei={finalizationIncentiveEthWei}
        currency={currency}
      />
    </TransactionStep>
  );
};
