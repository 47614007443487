import { useTranslation } from "next-i18next";
import { useCallback } from "react";
import { useAccount } from "wagmi";
import { formatEther } from "viem";
import { formatCompactNumber } from "@looksrare/utils";
import { DailyQuestCode, UserMilestoneLevel } from "@/types/points";

type RangeOfNumbers<
  Start extends number,
  End extends number,
  Result extends number[] = []
> = Result["length"] extends End ? Result[number] : RangeOfNumbers<Start, End, [...Result, Result["length"]]>;

type StripSuffix<T extends string> = T extends `${infer P}_${RangeOfNumbers<1, 100>}` ? P : T;

/**
 * Matches a string such as `STRING_STRING_1` and replaces trailing numeric tail i.e `_1`.
 */
export const stripLastUnderscore = <T extends string>(value: T): StripSuffix<T> => {
  return value.replace(/_\d+$/, "") as StripSuffix<T>;
};

/**
 * Manage translations for milestone level title, description, and levelString
 */
export const useGetMilestoneLevelText = () => {
  const { t } = useTranslation();

  return useCallback(
    (level: UserMilestoneLevel) => {
      const levelNumber = level?.code?.split("_").pop();
      const codeWithoutLevel = stripLastUnderscore(level.code);

      switch (codeWithoutLevel) {
        case "YOLO_ROUND":
          return {
            title: t("Lvl {{number}}", { number: levelNumber }),
            levelString: t("Lvl. {{number}}", { number: levelNumber }),
            description: t("Play {{number}} rounds.", {
              number: formatCompactNumber(Number(level.goal)),
            }),
          };

        case "YOLO_WIN":
          return {
            title: t("Lvl {{number}}", { number: levelNumber }),
            levelString: t("Lvl. {{number}}", { number: levelNumber }),
            description: t("Win a total of {{number}} rounds.", {
              number: formatCompactNumber(Number(level.goal)),
            }),
          };

        case "YOLO_FUTURE_ROUND":
          return {
            title: t("Lvl {{number}}", { number: levelNumber }),
            levelString: t("Lvl. {{number}}", { number: levelNumber }),
            description: t("Join {{number}} future rounds.", {
              number: formatCompactNumber(Number(level.goal)),
            }),
          };

        case "YOLO_ETH":
          return {
            title: t("Lvl {{number}}", { number: levelNumber }),
            levelString: t("Lvl. {{number}}", { number: levelNumber }),
            description: t("Enter with {{number}} cumulative ETH.", {
              number: formatEther(BigInt(level.goal)),
            }),
          };

        case "POINTS":
          return {
            title: t("Lvl {{number}}", { number: levelNumber }),
            levelString: t("Lvl. {{number}}", { number: levelNumber }),
            description: t("Collect {{number}} points.", {
              number: formatCompactNumber(Number(level.goal)),
            }),
          };

        case "PTB_WIN":
          return {
            title: t("Lvl {{number}}", { number: levelNumber }),
            levelString: t("Lvl. {{number}}", { number: levelNumber }),
            description: t("Win {{number}} rounds of {{game}}.", {
              number: formatCompactNumber(Number(level.goal)),
              game: "PTB",
            }),
          };

        case "PTB_WIN_STREAK":
          return {
            title: t("Lvl {{number}}", { number: levelNumber }),
            levelString: t("Lvl. {{number}}", { number: levelNumber }),
            description: t("Win {{number}} rounds of PTB in a row.", {
              number: formatCompactNumber(Number(level.goal)),
            }),
          };

        case "PTB_FUTURE_ROUND":
          return {
            title: t("Lvl {{number}}", { number: levelNumber }),
            levelString: t("Lvl. {{number}}", { number: levelNumber }),
            description: t("Enter {{number}} future rounds in PTB.", {
              number: formatCompactNumber(Number(level.goal)),
            }),
          };

        case "PTB_ETH":
          return {
            title: t("Lvl {{number}}", { number: levelNumber }),
            levelString: t("Lvl. {{number}}", { number: levelNumber }),
            description: t("Enter PTB with {{number}} ETH.", {
              number: formatEther(BigInt(level.goal)),
            }),
          };

        case "MORD_ROUND":
          return {
            title: t("Lvl {{number}}", { number: levelNumber }),
            levelString: t("Lvl. {{number}}", { number: levelNumber }),
            description: t("Participate in {{number}} rounds of MOON or DOOM", {
              number: formatCompactNumber(Number(level.goal)),
            }),
          };

        case "MORD_WIN":
          return {
            title: t("Lvl {{number}}", { number: levelNumber }),
            levelString: t("Lvl. {{number}}", { number: levelNumber }),
            description: t("Win {{number}} rounds of MOON or DOOM.", {
              number: formatCompactNumber(Number(level.goal)),
            }),
          };

        case "MORD_WIN_STREAK":
          return {
            title: t("Lvl {{number}}", { number: levelNumber }),
            levelString: t("Lvl. {{number}}", { number: levelNumber }),
            description: t("Win {{number}} rounds of MOON or DOOM in a row.", {
              number: formatCompactNumber(Number(level.goal)),
            }),
          };

        case "MORD_ETH":
          return {
            title: t("Lvl {{number}}", { number: levelNumber }),
            levelString: t("Lvl. {{number}}", { number: levelNumber }),
            description: t("Enter MOON or DOOM with {{number}} ETH", {
              number: formatEther(BigInt(level.goal)),
            }),
          };

        case "FLIPPER_ETH":
          return {
            title: t("Lvl {{number}}", { number: levelNumber }),
            levelString: t("Lvl. {{number}}", { number: levelNumber }),
            description: t("Play Flipper with {{number}} ETH or YOLO equivalent.", {
              number: formatEther(BigInt(level.goal)),
            }),
          };

        case "FLIPPER_ROUND":
          return {
            title: t("Lvl {{number}}", { number: levelNumber }),
            levelString: t("Lvl. {{number}}", { number: levelNumber }),
            description: t("Play {{number}} rounds of Flipper.", {
              number: formatCompactNumber(Number(level.goal)),
            }),
          };

        case "FLIPPER_WIN":
          return {
            title: t("Lvl {{number}}", { number: levelNumber }),
            levelString: t("Lvl. {{number}}", { number: levelNumber }),
            description: t("Win {{number}} rounds of Flipper.", {
              number: formatCompactNumber(Number(level.goal)),
            }),
          };

        case "FLIPPER_WIN_STREAK":
          return {
            title: t("Lvl {{number}}", { number: levelNumber }),
            levelString: t("Lvl. {{number}}", { number: levelNumber }),
            description: t("Win {{number}} consecutive rounds of Flipper.", {
              number: formatCompactNumber(Number(level.goal)),
            }),
          };

        case "QUANTUM_ETH":
          return {
            title: t("Lvl {{number}}", { number: levelNumber }),
            levelString: t("Lvl. {{number}}", { number: levelNumber }),
            description: t("Play Quantum with {{number}} ETH or YOLO equivalent.", {
              number: formatEther(BigInt(level.goal)),
            }),
          };

        case "QUANTUM_ROUND":
          return {
            title: t("Lvl {{number}}", { number: levelNumber }),
            levelString: t("Lvl. {{number}}", { number: levelNumber }),
            description: t("Play {{number}} rounds of Quantum.", {
              number: formatCompactNumber(Number(level.goal)),
            }),
          };

        case "QUANTUM_WIN":
          return {
            title: t("Lvl {{number}}", { number: levelNumber }),
            levelString: t("Lvl. {{number}}", { number: levelNumber }),
            description: t("Win {{number}} rounds of Quantum.", {
              number: formatCompactNumber(Number(level.goal)),
            }),
          };

        case "QUANTUM_WIN_STREAK":
          return {
            title: t("Lvl {{number}}", { number: levelNumber }),
            levelString: t("Lvl. {{number}}", { number: levelNumber }),
            description: t("Win {{number}} consecutive rounds of Quantum.", {
              number: formatCompactNumber(Number(level.goal)),
            }),
          };

        case "DONT_FALL_IN_ETH":
          return {
            title: t("Lvl {{number}}", { number: levelNumber }),
            levelString: t("Lvl. {{number}}", { number: levelNumber }),
            description: t("Play Don't Fall In with {{number}} ETH or YOLO equivalent.", {
              number: formatEther(BigInt(level.goal)),
            }),
          };

        case "DONT_FALL_IN_ROUND":
          return {
            title: t("Lvl {{number}}", { number: levelNumber }),
            levelString: t("Lvl. {{number}}", { number: levelNumber }),
            description: t("Play {{number}} rounds of Don't Fall In.", {
              number: formatCompactNumber(Number(level.goal)),
            }),
          };

        case "DONT_FALL_IN_WIN":
          return {
            title: t("Lvl {{number}}", { number: levelNumber }),
            levelString: t("Lvl. {{number}}", { number: levelNumber }),
            description: t("Win {{number}} rounds of Don't Fall In.", {
              number: formatCompactNumber(Number(level.goal)),
            }),
          };

        case "DONT_FALL_IN_WIN_STREAK":
          return {
            title: t("Lvl {{number}}", { number: levelNumber }),
            levelString: t("Lvl. {{number}}", { number: levelNumber }),
            description: t("Win {{number}} consecutive rounds of Don't Fall In.", {
              number: formatCompactNumber(Number(level.goal)),
            }),
          };

        case "LASER_BLAST_ETH":
          return {
            title: t("Lvl {{number}}", { number: levelNumber }),
            levelString: t("Lvl. {{number}}", { number: levelNumber }),
            description: t("Play LaserBlast with {{number}} ETH or YOLO equivalent.", {
              number: formatEther(BigInt(level.goal)),
            }),
          };

        case "LASER_BLAST_ROUND":
          return {
            title: t("Lvl {{number}}", { number: levelNumber }),
            levelString: t("Lvl. {{number}}", { number: levelNumber }),
            description: t("Play {{number}} rounds of LaserBlast.", {
              number: formatCompactNumber(Number(level.goal)),
            }),
          };

        case "USERNAME":
          return {
            title: t("Set User Name"),
            levelString: t("Objective #{{number}}", { number: 1 }),
          };
        case "AVATAR":
          return {
            title: t("Set Profile Image"),
            levelString: t("Objective #{{number}}", { number: 2 }),
          };
        case "BIO":
          return {
            title: t("Write a Bio"),
            levelString: t("Objective #{{number}}", { number: 3 }),
          };
        case "TWITTER":
          return {
            title: t("Link {{social}}", { social: "X" }),
            levelString: t("Objective #{{number}}", { number: 4 }),
          };
        case "DISCORD":
          return {
            title: t("Link {{social}}", { social: "Discord" }),
            levelString: t("Objective #{{number}}", { number: 5 }),
          };
        case "PROFILE_SETUP":
          return {
            title: t("Completion Bonus"),
            levelString: t("Objective #{{number}}", { number: 6 }),
          };
        default:
          return {
            title: "",
            levelString: "",
          };
      }
    },
    [t]
  );
};

/**
 * Get translated daily quest task and icon
 */
export const useGetDailyQuestMeta = () => {
  const { t } = useTranslation();

  return useCallback(
    (code?: DailyQuestCode, goal?: number) => {
      switch (code) {
        case "MORD_ENTER_0_5_ETH":
          return { task: t("Enter 0.5 ETH across uncanceled multiplayer MOON or DOOM rounds") };
        case "MORD_WIN_5":
          return { task: t("Win {{count}} rounds of uncanceled multiplayer MOON or DOOM", { count: goal }) };
        case "PTB_ENTER_0_5_ETH":
          return { task: t("Enter 0.5 ETH across uncanceled Poke The Bear rounds") };
        case "PTB_WIN_10":
          return { task: t("Win {{count}} rounds of Poke The Bear", { count: goal }) };
        case "YOLO_WIN_1":
          return { task: t("Win a round of YOLO or YOLO Limited") };
        case "YOLO_ENTER_0_25_ETH":
          return { task: t("Enter the equivalent of 0.25 ETH across uncanceled YOLO rounds") };
        case "DONT_FALL_IN_ENTER_0_5_ETH":
          return { task: t("Enter the equivalent of 0.5 ETH across Don’t Fall In rounds") };
        case "DONT_FALL_IN_WIN_5":
          return {
            task: t(
              "Win {{count}} tile flips without losing in Don't Fall In with a minimum entry and multiplier of {{ethAmount}} and {{multiplier}} respectively. If you play below the minimum entry or multiplier, it will not impact your current streak",
              {
                count: goal,
                ethAmount: "0.025 ETH",
                multiplier: "1.5x",
              }
            ),
          };
        case "FLIPPER_ENTER_0_5_ETH":
          return { task: t("Enter the equivalent of 0.5 ETH across Flipper rounds") };
        case "FLIPPER_WIN_5":
          return {
            task: t("Win {{count}} Flipper rounds with at least {{ethAmount}} per round", {
              count: goal,
              ethAmount: "0.025 ETH",
            }),
          };
        case "LASER_BLAST_ENTER_0_5_ETH":
          return { task: t("Enter the equivalent of 0.5 ETH across LaserBlast rounds") };
        case "QUANTUM_ENTER_0_5_ETH":
          return { task: t("Enter the equivalent of 0.5 ETH across Quantum rounds") };
        case "QUANTUM_WIN_5":
          return {
            task: t(
              "Win {{count}} rounds of Quantum with a minimum entry and multiplier of {{ethAmount}} and {{multiplier}} respectively",
              {
                count: goal,
                ethAmount: "0.025 ETH",
                multiplier: "1.5x",
              }
            ),
          };
        case "COMPLETION_BONUS":
        default:
          return { task: t("Completion Bonus") };
      }
    },
    [t]
  );
};

export type MilestoneState = "wallet-disconnected" | "ready-to-start" | "in-progress" | "ready-to-claim" | "claimed";
/**
 * Parse the milestone's levels to determine the current state of the milestone.
 * @note "Missions", like Profile Setup, are a special case, and are not handled here.
 * @note Daily quests _are_ handled here, they behave the same as milestones
 */
export const useGetMilestoneState = () => {
  const { isConnected } = useAccount();

  return useCallback(
    (levels: UserMilestoneLevel[]): MilestoneState => {
      // find the first level that has not been claimed
      const level = levels.find((l) => !l.claimedAt);

      if (!isConnected || level?.milestone === "COMING_SOON" || levels.length === 0) {
        return "wallet-disconnected";
      }

      if (!level) {
        // all levels have been claimed
        return "claimed";
      }

      const { progress, createdAt } = level;

      // When createdAt is defined - the milestone has been created on the BE and can be claimed
      if (createdAt) {
        return "ready-to-claim";
      }

      if (BigInt(progress) > 0) {
        return "in-progress";
      }

      return "ready-to-start";
    },
    [isConnected]
  );
};
