import { useEffect, useRef } from "react";
import { Box, useToken } from "@chakra-ui/react";
import Highcharts from "highcharts";
import { LiquidityPoolInterval } from "@looksrare/yolo-games-gql-typegen";
import { liquidityChartLabelFormatter } from "@/utils";
import { defaultHighchartsOptions } from "./shared";

interface LiquidityPoolValueChartProps {
  chartData: number[][];
  color: string;
  timeInterval: LiquidityPoolInterval;
}

export const LiquidityPoolValueChart = ({ chartData, color, timeInterval }: LiquidityPoolValueChartProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [primaryColor, textPlaceholder, border01, ui01, text01] = useToken("colors", [
    color,
    "text-placeholder",
    "border-01",
    "ui-01",
    "text-01",
  ]);

  useEffect(() => {
    if (ref.current) {
      const priceValues = chartData.map(([, price]) => price).sort((a, b) => a - b);

      const options: Highcharts.Options = {
        chart: {
          type: "area",
          marginLeft: 0,
        },
        tooltip: {
          formatter: function () {
            return this.y?.toString();
          },
          backgroundColor: ui01,
          borderColor: border01,
          borderWidth: 1,
          borderRadius: 4,
          style: {
            color: text01,
            fontWeight: "bold",
          },
        },
        xAxis: {
          type: "datetime",
          gridLineColor: border01,
          gridLineWidth: 1,
          lineWidth: 0,
          tickWidth: 0,
          labels: {
            formatter: (input) => liquidityChartLabelFormatter(input, timeInterval),
            style: { color: textPlaceholder },
          },
        },
        yAxis: {
          opposite: true,
          gridLineColor: border01,
          gridLineWidth: 1,
          lineWidth: 0,
          tickWidth: 0,
          floor: priceValues[0] * 0.999,
          ceiling: priceValues[priceValues.length - 1] * 1.01,
          labels: {
            style: { color: textPlaceholder },
          },
        },
        series: [
          {
            type: "area",
            data: chartData,
            marker: { enabled: false },
            lineColor: primaryColor,
            fillColor: `${primaryColor}40`,
          },
        ],
      };
      Highcharts.chart(ref.current, Highcharts.merge(defaultHighchartsOptions, options));
    }
  }, [ref, chartData, primaryColor, border01, ui01, text01, textPlaceholder, timeInterval]);

  return <Box ref={ref} />;
};
