import { Grid, Stack } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { Text } from "@looksrare/uikit";
import { SignedUserGuard } from "@/components";
import { SideMenu } from "./components/SideMenu";
import { TakeABreakContent } from "./components/TakeABreakContent";

export const TakeABreakView = () => {
  const { t } = useTranslation();
  return (
    <Grid gridTemplateColumns={{ base: "minmax(0, 1fr)", md: "320px minmax(0, 1fr)" }} gridGap={8} my={8}>
      <SideMenu />

      <Stack spacing={8} maxW={{ base: "100%", md: "512px" }} borderLeft="1px solid" borderLeftColor="border-01" p={8}>
        <Text as="h1" textStyle="display-03" letterSpacing="-2%" bold>
          {t("Take a Break")}
        </Text>

        <SignedUserGuard>{(user, address) => <TakeABreakContent address={address} user={user} />}</SignedUserGuard>
      </Stack>
    </Grid>
  );
};
