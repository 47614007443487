import { useEffect, useState } from "react";
import { useAccount } from "wagmi";
import { Box, Flex } from "@chakra-ui/react";
import { JwtScope, isAuthorized, useOnAccountChange } from "@looksrare/utils";
import { useAddAffiliateMutation } from "@/queries/referral";
import { useToast } from "@/hooks";
import { useHasUserReferrer, useUser } from "@/queries/user";
import { Header } from "./components/Header";
import { EnterCodeStep } from "./steps/EnterCodeStep";
import { SocialsStep } from "./steps/SocialsStep";
import { ClaimStep } from "./steps/ClaimStep";

enum OnboardingStep {
  ENTER_CODE = "ENTER_CODE",
  SOCIALS = "SOCIALS",
  CLAIM = "CLAIM",
}

/**
 * Top level component manages state for the onboarding process.
 * Note this page relies on the user's wallet being connected, we cannot rely on server side data.
 */
export const Onboarding = () => {
  const { address } = useAccount();
  const isConnectedWalletAuthorized = isAuthorized(address, JwtScope.LongLived);
  const [step, setStep] = useState<OnboardingStep>(OnboardingStep.ENTER_CODE);
  useOnAccountChange(() => {
    setStep(OnboardingStep.ENTER_CODE);
  });
  const [validatedInput, setValidatedInput] = useState("");
  const { toast } = useToast();
  const { data: user } = useUser(address!, { enabled: !!address });
  const { data: userReferrerData } = useHasUserReferrer(address!);
  const hasReferrerAddress = userReferrerData?.hasPendingOrConfirmedReferrer || false;

  const addAffiliateMutation = useAddAffiliateMutation({
    // @todo-yg Toast is here for dev & QA. Remove for prod
    onSuccess: () => {
      toast({
        title: "Referral linked",
        status: "success",
      });
    },
  });
  const { mutate: addAffiliate } = addAffiliateMutation;

  /**
   * Once we have a valid input, move on from ENTER_CODE to SOCIALS step
   */
  useEffect(() => {
    if (hasReferrerAddress || (validatedInput && step === OnboardingStep.ENTER_CODE)) {
      if (user?.twitterUsername && user?.discordUsername && hasReferrerAddress) {
        setStep(OnboardingStep.CLAIM);
      } else {
        setStep(OnboardingStep.SOCIALS);
      }
    }
  }, [step, validatedInput, hasReferrerAddress, user]);

  /**
   * Once we have a valid input and a connected wallet, we call addAffiliate
   */
  useEffect(() => {
    if (validatedInput && isConnectedWalletAuthorized) {
      addAffiliate(validatedInput);
    }
  }, [validatedInput, isConnectedWalletAuthorized, addAffiliate]);

  return (
    <Box minHeight="100dvh" bgGradient="linear-gradient(225.68deg, #171D26 15%, #000000 85%)">
      <Header />
      <Flex width="100%" justifyContent="center" py={24}>
        {step === OnboardingStep.ENTER_CODE && <EnterCodeStep setValidatedInput={setValidatedInput} />}
        {step === OnboardingStep.SOCIALS && (
          <SocialsStep
            onComplete={() => {
              setStep(OnboardingStep.CLAIM);
            }}
          />
        )}
        {step === OnboardingStep.CLAIM && <ClaimStep />}
      </Flex>
    </Box>
  );
};
