import { useMemo } from "react";
import { Flex, Stack } from "@chakra-ui/react";
import { ContentfulHeroSlide, ContentfulHomeGameCard } from "@/types/contentful";
import { AboutUs, Footer, HeroCarousel, LogoAndSocials, PlatformStats, PopularGames } from "./components";
import { HeroCarouselSlideProps } from "./components/HeroCarousel/components/HeroCarouselSlide";
import { PopularGameCardProps } from "./components/PopularGameCard";
import { RecentWins } from "./components/RecentWins";

interface HomeViewProps {
  heroSlides: ContentfulHeroSlide[];
  homeGameCards: ContentfulHomeGameCard[];
}

export const HomeView = ({ heroSlides, homeGameCards }: HomeViewProps) => {
  const mappedHeroSlides: HeroCarouselSlideProps[] = useMemo(() => {
    return heroSlides.map(({ title, description, link, image, blurImage }) => ({
      title,
      description,
      href: link,
      imageSrc: image.url,
      blurImageSrc: blurImage.url,
    }));
  }, [heroSlides]);

  const mappedGameCards: PopularGameCardProps[] = useMemo(() => {
    return homeGameCards.map(({ gameId, name, description, link, image, blurImage }) => ({
      gameId,
      title: name,
      description,
      href: link,
      imageSrc: image.url,
      blurImageSrc: blurImage.url,
    }));
  }, [homeGameCards]);

  return (
    <Flex flexDirection="column" alignItems="center" width="100%" maxWidth="1600px" mx="auto">
      <Stack spacing={{ base: 9, lg: 12 }} py={{ base: 12, lg: 16 }} width="100%">
        {mappedHeroSlides.length > 0 && <HeroCarousel slides={mappedHeroSlides} />}
        <LogoAndSocials />
        {mappedGameCards.length > 0 && <PopularGames cards={mappedGameCards} />}
        <PlatformStats />
        <RecentWins />
        <AboutUs />
      </Stack>
      <Footer />
    </Flex>
  );
};
