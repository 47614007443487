import { type FC } from "react";
import { Box, Flex, HStack, Spinner, type BoxProps } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { EmptyStateIcon, IconProps, Text } from "@looksrare/uikit";
import { formatNumberToLocale, formatUsd } from "@looksrare/utils";
import type { LiquidityPoolInterval } from "@looksrare/yolo-games-gql-typegen";
import { useTimeIntervalLabels } from "../../hooks";
import { ValuePercText } from "./shared";

interface ChartBoxProps extends BoxProps {
  isLoading: boolean;
  hasData: boolean;
  chartTitle: string;
  timeInterval: LiquidityPoolInterval;
  currencySymbol: string;
  currencyIcon?: FC<IconProps>;
  intervalPctChange?: number;
  intervalTotal?: number;
  valueVumUsd?: number;
  enableRelativeText?: boolean;
}

export const ChartBox = ({
  isLoading,
  hasData,
  chartTitle,
  timeInterval,
  currencySymbol,
  currencyIcon: CurrencyIcon,
  intervalPctChange,
  intervalTotal,
  valueVumUsd = 0,
  enableRelativeText = false,
  children,
  ...props
}: ChartBoxProps) => {
  const { t } = useTranslation();
  const intervalLabels = useTimeIntervalLabels();
  const timeIntervalLabel = intervalLabels.find((label) => label[0] === timeInterval) || intervalLabels[0];
  const intervalTotalValue = Number(intervalTotal ?? 0);

  const { textColor, prefix }: { textColor: string; prefix: string } = (() => {
    if (enableRelativeText) {
      if (intervalTotalValue > 0) {
        return { textColor: "support-success", prefix: "+" };
      }

      if (intervalTotalValue < 0) {
        return { textColor: "text-error", prefix: "" };
      }
    }

    if (hasData) {
      return { textColor: "body", prefix: "" };
    }

    return { textColor: "text-03", prefix: "" };
  })();

  return (
    <Box
      border="1px solid"
      borderColor="border-01"
      borderRadius="dialog"
      bg="ui-bg"
      p={4}
      position="relative"
      {...props}
    >
      <Text textStyle="helper" color="text-03" {...props}>
        {chartTitle}
      </Text>
      <HStack spacing={2}>
        {CurrencyIcon && <CurrencyIcon boxSize={5} />}
        <Text textStyle="display-body" bold title={currencySymbol} color={textColor}>
          {hasData ? `${prefix}${formatNumberToLocale(intervalTotalValue, 0, 2)}` : "—"}
        </Text>
        {valueVumUsd > 0 && (
          <Text textStyle="helper" color="text-03">
            {formatUsd(valueVumUsd)}
          </Text>
        )}
      </HStack>
      <ValuePercText percentage={hasData ? intervalPctChange : 0} timeIntevalLabel={timeIntervalLabel[1]} />
      <Box minHeight="256px" position="relative">
        {(() => {
          if (isLoading) {
            return (
              <Flex
                position="absolute"
                alignItems="center"
                justifyContent="center"
                height="100%"
                width="100%"
                top={0}
                left={0}
              >
                <Spinner color="text-03" />
              </Flex>
            );
          }

          if (hasData) {
            return children;
          }

          return (
            <Flex
              position="absolute"
              alignItems="center"
              justifyContent="center"
              height="100%"
              width="100%"
              top={0}
              left={0}
              flexDirection="column"
            >
              <EmptyStateIcon boxSize={16} color="text-disabled" />
              <Text color="text-disabled" bold>
                {t("No Data")}
              </Text>
            </Flex>
          );
        })()}
      </Box>
    </Box>
  );
};
