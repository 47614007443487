import { useTranslation } from "next-i18next";
import { formatDate, intervalToDuration } from "date-fns";
import { Box, Flex, HStack, BoxProps, VStack } from "@chakra-ui/react";
import { HelpIcon, IconProps, Popover, Text, TextProps } from "@looksrare/uikit";
import { formatDuration } from "@looksrare/utils";
import { fonts } from "@/theme/typography";

interface HeaderCardProps extends BoxProps {
  icon: React.FC<IconProps>;
  title: string;
  explanation: string;
  description: string;
  accentColor: TextProps["color"];
  variant: "epoch" | "season";
  startDate: Date;
  endDate?: Date;
}

export const HeaderCard = ({
  icon: Icon,
  title,
  explanation,
  description,
  accentColor,
  variant,
  startDate,
  endDate,
  ...props
}: HeaderCardProps) => {
  const { t } = useTranslation();
  const isEpochCard = variant === "epoch";
  const timeRemaining = (() => {
    if (!endDate) {
      return;
    }

    const startAsUtcString = new Date().toUTCString(); // Current date time as UTC string
    const durationUntilEndTime = intervalToDuration({
      start: new Date(startAsUtcString),
      end: endDate,
    });
    return formatDuration(durationUntilEndTime, {
      format: ["months", "days", "hours", "minutes"],
      shortLabels: true,
    });
  })();

  return (
    <Box borderColor="border-01" borderWidth="1px" borderRadius="container" overflow="hidden" {...props}>
      <VStack position="relative" spacing={4} p={4} pt={8} backgroundColor="ui-01">
        <VStack spacing={2}>
          <Icon boxSize={12} />
          <Text textStyle="heading-03" color="text-02" bold>
            {title}
          </Text>
        </VStack>

        <VStack spacing={2}>
          <Text
            fontFamily={fonts.heading}
            textStyle="display-03"
            fontSize="28px"
            textTransform="uppercase"
            color={accentColor}
            bold
          >
            {description}
          </Text>
          <Flex gap={1}>
            {timeRemaining ? (
              <Text textStyle="detail" bold color="text-03" textTransform="uppercase">
                {timeRemaining}
              </Text>
            ) : (
              <Box bg="rgba(0,0,0,0.25)" backdropFilter="blur(21px)" borderRadius="mini">
                <Text textStyle="detail" color="text-01" whiteSpace="nowrap" filter="auto" blur="21px">
                  XXD XXH XXM
                </Text>
              </Box>
            )}
            <Text textStyle="detail" bold color="text-03">
              {t("Remain")}
            </Text>
          </Flex>
        </VStack>

        <Popover
          label={
            <Text textStyle="detail" color="text-inverse">
              {explanation}
            </Text>
          }
          renderInPortal
          placement="top"
        >
          <Flex position="absolute" top={2} right={2}>
            <HelpIcon boxSize={5} color="text-03" />
          </Flex>
        </Popover>
      </VStack>
      <Flex p={4} borderTop="1px solid" borderColor="border-01" backgroundColor="ui-bg">
        <Flex flexDirection={{ base: "column", xl: "row" }} gap={2} alignItems="center" width="100%">
          <HStack spacing={2}>
            <Text textStyle="detail" bold color="text-03" whiteSpace="nowrap">
              {isEpochCard ? t("Epoch Start:") : t("Season Start:")}
            </Text>
            <Text textStyle="detail" color="text-03" whiteSpace="nowrap">
              {formatDate(startDate, "HH:mm aa, LLL d, yyyy")}
            </Text>
          </HStack>
          <HStack spacing={2}>
            <Text textStyle="detail" color="text-03" whiteSpace="nowrap" display={{ base: "none", xl: "block" }}>
              |
            </Text>
            <Text textStyle="detail" bold color="text-03" whiteSpace="nowrap">
              {isEpochCard ? t("Epoch End:") : t("Season End:")}
            </Text>
            {endDate ? (
              <Text textStyle="detail" color="text-03" whiteSpace="nowrap">
                {formatDate(endDate, "HH:mm aa, LLL d, yyyy")}
              </Text>
            ) : (
              <Box width="100%" position="relative">
                <Box bg="rgba(0,0,0,0.25)" backdropFilter="blur(21px)" borderRadius="mini">
                  <Text textStyle="detail" color="text-01" whiteSpace="nowrap" filter="auto" blur="21px">
                    X:XX PM, Xxx. 2024
                  </Text>
                </Box>
              </Box>
            )}
          </HStack>
        </Flex>
      </Flex>
    </Box>
  );
};
