import { Flex } from "@chakra-ui/react";
import { Text } from "@looksrare/uikit";

interface InputCellProps {
  value?: string;
  isFocused?: boolean;
  isError?: boolean;
  isSuccess?: boolean;
}

export const InputCell = ({
  value = "",
  isFocused = false,
  isError = true,
  isSuccess = false,
  ...props
}: InputCellProps) => {
  const boxShadow = (() => {
    if (isError) {
      return "0px 0px 14px 0px rgba(250, 77, 86, 1)";
    }
    if (isSuccess) {
      return "0px 0px 14px 0px rgba(255, 255, 255, 0.35)";
    }
    return "";
  })();

  return (
    <Flex
      width={{ base: "54px", md: "64px" }}
      height={{ base: "72px", md: "96px" }}
      border="1px solid"
      borderRadius="button"
      alignItems="center"
      cursor="text"
      justifyContent="center"
      borderColor={isFocused ? "focus" : "border-01"}
      boxShadow={boxShadow}
      {...props}
    >
      {value && <Text textStyle="display-03">{value}</Text>}
      {isFocused && !value && (
        <Text
          backgroundColor="text-03"
          fontWeight="normal"
          animation="blink 1s infinite"
          width="1px"
          height="60%"
          sx={{
            "@keyframes blink": {
              "0%, 100%": {
                backgroundColor: "transparent",
              },
              "50%": {
                backgroundColor: "white",
              },
            },
          }}
        />
      )}
    </Flex>
  );
};
