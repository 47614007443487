import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import type { StateSetter } from "@looksrare/utils";
import { StepTimingStatus } from "@looksrare/uikit";
import { DepositWithdrawStep, type InitialAction, type StepsWithActions, type StepData } from "./types";

interface DepositWithdrawActions {
  setInputValue: StateSetter<string>;
  setInputTab: StateSetter<DepositWithdrawStep.DEPOSIT_INPUT | DepositWithdrawStep.WITHDRAWAL_INPUT>;
  setFailStatus: StateSetter<StepsWithActions>;
  startInput: (initialStep: InitialAction) => void;
  // Deposit
  startDepositGrantApproval: () => void;
  startDepositApproval: () => void;
  startInitiateDeposit: () => void;
  startDepositTimelock: () => void;
  startDepositFinalization: () => void;
  confirmDeposit: () => void;
  // Withdraw
  startWithdrawalGrantApproval: () => void;
  startWithdrawalTokenApproval: () => void;
  startInitiateWithdraw: () => void;
  startWithdrawalTimelock: () => void;
  startWithdrawalFinalization: () => void;
  confirmWithdraw: () => void;
  reset: () => void;
}

interface State {
  inputValue: string;
  step: DepositWithdrawStep;
  stepStatus: Record<StepsWithActions, StepData & { timingStatus: StepTimingStatus }>;
}

const getInitialState = (): State => ({
  inputValue: "",
  step: DepositWithdrawStep.IDLE,
  stepStatus: {
    [DepositWithdrawStep.DEPOSIT_GRANT_APPROVAL]: { timingStatus: "current", status: "pending", hasError: false },
    [DepositWithdrawStep.DEPOSIT_APPROVE_TOKEN]: { timingStatus: "future", status: "pending", hasError: false },
    [DepositWithdrawStep.INITIATE_DEPOSIT]: { timingStatus: "future", status: "wait", hasError: false },
    [DepositWithdrawStep.DEPOSIT_TIMELOCK]: { timingStatus: "future", status: "wait", hasError: false },
    [DepositWithdrawStep.FINALIZE_DEPOSIT]: { timingStatus: "future", status: "wait", hasError: false },
    [DepositWithdrawStep.WITHDRAWAL_GRANT_APPROVAL]: { timingStatus: "future", status: "wait", hasError: false },
    [DepositWithdrawStep.WITHDRAWAL_APPROVE_TOKEN]: { timingStatus: "future", status: "wait", hasError: false },
    [DepositWithdrawStep.INITIATE_WITHDRAWAL]: { timingStatus: "future", status: "wait", hasError: false },
    [DepositWithdrawStep.WITHDRAWAL_TIMELOCK]: { timingStatus: "future", status: "wait", hasError: false },
    [DepositWithdrawStep.FINALIZE_WITHDRAWAL]: { timingStatus: "future", status: "wait", hasError: false },
  },
});

export const useDepositWithdrawStore = create(
  immer<State & DepositWithdrawActions>((set) => ({
    ...getInitialState(),
    startInput: (initialStep) => {
      set((state) => {
        state.step = initialStep;
      });
    },
    startDepositGrantApproval: () => {
      set((state) => {
        state.step = DepositWithdrawStep.DEPOSIT_GRANT_APPROVAL;
        state.stepStatus[DepositWithdrawStep.DEPOSIT_GRANT_APPROVAL] = {
          timingStatus: "current",
          status: "pending",
          hasError: false,
        };
        state.stepStatus[DepositWithdrawStep.DEPOSIT_APPROVE_TOKEN] = {
          timingStatus: "current",
          status: "wait",
          hasError: false,
        };
        state.stepStatus[DepositWithdrawStep.INITIATE_DEPOSIT].timingStatus = "future";
        state.stepStatus[DepositWithdrawStep.DEPOSIT_TIMELOCK].timingStatus = "future";
        state.stepStatus[DepositWithdrawStep.FINALIZE_DEPOSIT].timingStatus = "future";
      });
    },
    startDepositApproval: () => {
      set((state) => {
        state.step = DepositWithdrawStep.DEPOSIT_APPROVE_TOKEN;

        state.stepStatus[DepositWithdrawStep.DEPOSIT_GRANT_APPROVAL] = {
          timingStatus: "current",
          status: "done",
          hasError: false,
        };
        state.stepStatus[DepositWithdrawStep.DEPOSIT_APPROVE_TOKEN] = {
          timingStatus: "current",
          status: "pending",
          hasError: false,
        };
        state.stepStatus[DepositWithdrawStep.INITIATE_DEPOSIT].timingStatus = "future";
        state.stepStatus[DepositWithdrawStep.DEPOSIT_TIMELOCK].timingStatus = "future";
        state.stepStatus[DepositWithdrawStep.FINALIZE_DEPOSIT].timingStatus = "future";
      });
    },
    startInitiateDeposit: () => {
      set((state) => {
        state.step = DepositWithdrawStep.INITIATE_DEPOSIT;

        state.stepStatus[DepositWithdrawStep.DEPOSIT_GRANT_APPROVAL].timingStatus = "past";
        state.stepStatus[DepositWithdrawStep.DEPOSIT_APPROVE_TOKEN].timingStatus = "past";
        state.stepStatus[DepositWithdrawStep.FINALIZE_DEPOSIT].timingStatus = "future";

        state.stepStatus[DepositWithdrawStep.INITIATE_DEPOSIT] = {
          timingStatus: "current",
          status: "pending",
          hasError: false,
        };
        state.stepStatus[DepositWithdrawStep.DEPOSIT_TIMELOCK] = {
          timingStatus: "current",
          status: "wait",
          hasError: false,
        };
      });
    },
    startDepositTimelock: () => {
      set((state) => {
        state.step = DepositWithdrawStep.DEPOSIT_TIMELOCK;

        state.stepStatus[DepositWithdrawStep.DEPOSIT_GRANT_APPROVAL].timingStatus = "past";
        state.stepStatus[DepositWithdrawStep.DEPOSIT_APPROVE_TOKEN].timingStatus = "past";
        state.stepStatus[DepositWithdrawStep.FINALIZE_DEPOSIT].timingStatus = "future";

        state.stepStatus[DepositWithdrawStep.INITIATE_DEPOSIT] = {
          timingStatus: "current",
          status: "done",
          hasError: false,
        };
        state.stepStatus[DepositWithdrawStep.DEPOSIT_TIMELOCK] = {
          timingStatus: "current",
          status: "pending",
          hasError: false,
        };
      });
    },
    startDepositFinalization: () => {
      set((state) => {
        state.step = DepositWithdrawStep.FINALIZE_DEPOSIT;

        state.stepStatus[DepositWithdrawStep.DEPOSIT_GRANT_APPROVAL].timingStatus = "past";
        state.stepStatus[DepositWithdrawStep.DEPOSIT_APPROVE_TOKEN].timingStatus = "past";
        state.stepStatus[DepositWithdrawStep.INITIATE_DEPOSIT].timingStatus = "past";

        state.stepStatus[DepositWithdrawStep.FINALIZE_DEPOSIT] = {
          timingStatus: "current",
          status: "pending",
          hasError: false,
        };
      });
    },
    startWithdrawalGrantApproval: () => {
      set((state) => {
        state.step = DepositWithdrawStep.WITHDRAWAL_GRANT_APPROVAL;

        state.stepStatus[DepositWithdrawStep.WITHDRAWAL_GRANT_APPROVAL] = {
          timingStatus: "current",
          status: "pending",
          hasError: false,
        };
        state.stepStatus[DepositWithdrawStep.WITHDRAWAL_APPROVE_TOKEN] = {
          timingStatus: "current",
          status: "wait",
          hasError: false,
        };
        state.stepStatus[DepositWithdrawStep.INITIATE_WITHDRAWAL].timingStatus = "future";
        state.stepStatus[DepositWithdrawStep.WITHDRAWAL_TIMELOCK].timingStatus = "future";
      });
    },
    startWithdrawalTokenApproval: () => {
      set((state) => {
        state.step = DepositWithdrawStep.WITHDRAWAL_APPROVE_TOKEN;

        state.stepStatus[DepositWithdrawStep.WITHDRAWAL_GRANT_APPROVAL] = {
          timingStatus: "current",
          status: "done",
          hasError: false,
        };
        state.stepStatus[DepositWithdrawStep.WITHDRAWAL_APPROVE_TOKEN] = {
          timingStatus: "current",
          status: "pending",
          hasError: false,
        };
        state.stepStatus[DepositWithdrawStep.INITIATE_WITHDRAWAL].timingStatus = "future";
        state.stepStatus[DepositWithdrawStep.WITHDRAWAL_TIMELOCK].timingStatus = "future";
      });
    },
    startInitiateWithdraw: () => {
      set((state) => {
        state.step = DepositWithdrawStep.INITIATE_WITHDRAWAL;
        state.stepStatus[DepositWithdrawStep.WITHDRAWAL_APPROVE_TOKEN] = {
          timingStatus: "past",
          status: "done",
          hasError: false,
        };

        state.stepStatus[DepositWithdrawStep.INITIATE_WITHDRAWAL] = {
          timingStatus: "current",
          status: "pending",
          hasError: false,
        };
        state.stepStatus[DepositWithdrawStep.WITHDRAWAL_TIMELOCK].status = "wait";
      });
    },
    startWithdrawalTimelock: () => {
      set((state) => {
        state.step = DepositWithdrawStep.WITHDRAWAL_TIMELOCK;
        state.stepStatus[DepositWithdrawStep.WITHDRAWAL_APPROVE_TOKEN] = {
          timingStatus: "past",
          status: "done",
          hasError: false,
        };
        state.stepStatus[DepositWithdrawStep.INITIATE_WITHDRAWAL] = {
          timingStatus: "current",
          status: "done",
          hasError: false,
        };
        state.stepStatus[DepositWithdrawStep.WITHDRAWAL_TIMELOCK] = {
          timingStatus: "current",
          status: "pending",
          hasError: false,
        };
      });
    },
    startWithdrawalFinalization: () => {
      set((state) => {
        state.step = DepositWithdrawStep.FINALIZE_WITHDRAWAL;
        state.stepStatus[DepositWithdrawStep.WITHDRAWAL_APPROVE_TOKEN] = {
          timingStatus: "past",
          status: "done",
          hasError: false,
        };
        state.stepStatus[DepositWithdrawStep.INITIATE_WITHDRAWAL] = {
          timingStatus: "past",
          status: "done",
          hasError: false,
        };
        state.stepStatus[DepositWithdrawStep.WITHDRAWAL_TIMELOCK] = {
          timingStatus: "past",
          status: "done",
          hasError: false,
        };
        state.stepStatus[DepositWithdrawStep.FINALIZE_WITHDRAWAL] = {
          timingStatus: "current",
          status: "pending",
          hasError: false,
        };
      });
    },
    confirmDeposit: () => {
      set((state) => {
        state.step = DepositWithdrawStep.DEPOSIT_CONFIRMATION;
      });
    },
    confirmWithdraw: () => {
      set((state) => {
        state.step = DepositWithdrawStep.WITHDRAWAL_CONFIRMATION;
      });
    },
    setFailStatus: (step) => {
      set((state) => {
        state.stepStatus[step].hasError = true;
      });
    },
    setInputTab: (newState) => {
      set((state) => {
        state.step = newState;
        state.inputValue = "";
      });
    },
    setInputValue: (newState) => {
      set((state) => {
        state.inputValue = newState;
      });
    },
    reset: () => {
      set((state) => {
        return {
          ...state,
          ...getInitialState(),
        };
      });
    },
  }))
);
