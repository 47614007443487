import { useState } from "react";
import { Box, BoxProps, StackDivider, Stack, useBreakpointValue } from "@chakra-ui/react";
import { useAccount } from "wagmi";
import times from "lodash/times";
import { formatNumberToLocale, isAddressEqual } from "@looksrare/utils";
import { ETH_AVG_BLOCK_TIME_MS } from "@looksrare/config";
import { TableFooter } from "@looksrare/uikit";
import {
  LISTING_SEASON_PER_PAGE,
  useCurrentEpochLeaderboard,
  useCurrentPointsSeasonLeaderboard,
  useUserSeasonPoints,
} from "@/hooks/points";
import { useUser } from "@/queries/user";
import { LeaderboardSortInput } from "@/queries/points";
import { Header, LeaderboardOption, Row, SkeletonRow } from "@/views/Rewards/components/SeasonLeaderboard/shared";

interface SeasonLeaderboardProps extends BoxProps {
  tableOption: LeaderboardOption;
}

export const SeasonLeaderboard = ({ tableOption, ...props }: SeasonLeaderboardProps) => {
  // map UI sort options to API sort options
  const sort = {
    [LeaderboardOption.SEASON_POINTS_DAILY_RANK_ASC]: LeaderboardSortInput.DAILY_RANK_ASC,
    [LeaderboardOption.EPOCH]: LeaderboardSortInput.SEASON_RANK_ASC,
    [LeaderboardOption.SEASON_POINTS_RANK_ASC]: LeaderboardSortInput.SEASON_RANK_ASC,
  }[tableOption];

  const isMobile = useBreakpointValue({ base: true, lg: false });
  const { address: connectedAddress } = useAccount();
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  // limit table to 300 rows total, across all pages
  const maxRowsInTable = 300;
  const offset = (currentPageNumber - 1) * LISTING_SEASON_PER_PAGE;

  // +1 for the user row that is inserted at the top
  const skeletonRowCount = LISTING_SEASON_PER_PAGE + 1;

  const { data: seasonLeaderboard, isLoading: isLoadingLeaderboard } = useCurrentPointsSeasonLeaderboard(
    {
      pagination: {
        offset,
        first: LISTING_SEASON_PER_PAGE,
      },
      sort,
    },
    { refetchInterval: ETH_AVG_BLOCK_TIME_MS, enabled: tableOption !== LeaderboardOption.EPOCH }
  );

  const { data: epochLeaderboard, isLoading: isLoadingEpochLeaderboard } = useCurrentEpochLeaderboard(
    {
      pagination: {
        offset,
        first: LISTING_SEASON_PER_PAGE,
      },
    },
    { refetchInterval: ETH_AVG_BLOCK_TIME_MS, enabled: tableOption === LeaderboardOption.EPOCH }
  );

  const leaderboard = tableOption === LeaderboardOption.EPOCH ? epochLeaderboard : seasonLeaderboard;
  const leaderboardRows = leaderboard?.points || [];
  const totalWallets = leaderboard?.total ? parseInt(leaderboard?.total.toString()) : maxRowsInTable;

  const totalPages = (() => {
    const totalPagesFloat = Math.min(maxRowsInTable, totalWallets) / LISTING_SEASON_PER_PAGE;
    return Math.ceil(totalPagesFloat);
  })();

  const { data: userSeasonData, isLoading: isLoadingUserSeasonData } = useUserSeasonPoints(connectedAddress!, {
    enabled: !!connectedAddress,
  });
  const isLoading = isLoadingLeaderboard || isLoadingUserSeasonData || isLoadingEpochLeaderboard;

  // omit user row when the connected wallet is already in the current leaderboard rows
  const shouldShowUserRow =
    !!connectedAddress &&
    !!leaderboardRows &&
    !isLoadingUserSeasonData &&
    !leaderboardRows.some((row) => isAddressEqual(row.user.address, connectedAddress));
  const { data: user } = useUser(connectedAddress!, { enabled: !!connectedAddress });

  const userSeasonDataKey = tableOption === LeaderboardOption.EPOCH ? "epochPoints" : "seasonPoints";
  const userPointsTotal = !!userSeasonData?.[userSeasonDataKey]?.seasonPoints
    ? formatNumberToLocale(Number(userSeasonData?.[userSeasonDataKey]?.seasonPoints), 0, 2)
    : "-";
  const userPoints24h = !!userSeasonData?.[userSeasonDataKey]?.points24h
    ? formatNumberToLocale(Number(userSeasonData?.[userSeasonDataKey]?.points24h), 0, 2)
    : "-";
  const userSeasonRank = !!userSeasonData?.[userSeasonDataKey]?.seasonRank
    ? Number(userSeasonData?.[userSeasonDataKey]?.seasonRank)
    : undefined;
  const user24hRank = !!userSeasonData?.[userSeasonDataKey]?.dailyRank
    ? Number(userSeasonData?.[userSeasonDataKey]?.dailyRank)
    : undefined;

  const userDisplayRank = (() => {
    switch (tableOption) {
      case LeaderboardOption.SEASON_POINTS_RANK_ASC:
      case LeaderboardOption.EPOCH:
        return userSeasonRank;
      case LeaderboardOption.SEASON_POINTS_DAILY_RANK_ASC:
        return user24hRank;
      default:
        return userSeasonRank;
    }
  })();

  return (
    <Box bg="ui-01" borderRadius="container" border="1px solid" borderColor="border-01" {...props}>
      <Stack spacing={0} divider={<StackDivider borderColor="border-01" />}>
        <Header />
        {shouldShowUserRow && (
          <Row
            key={connectedAddress}
            rank={userDisplayRank}
            accountAddress={connectedAddress!}
            pointsTotal={userPointsTotal}
            points24h={userPoints24h}
            name={user?.name || ""}
            addressIsVerified={!!user?.isVerified}
            image={user?.avatar?.image}
            isConnectedAccount
            isMobile={!!isMobile}
            sort={sort}
            isAvatarPrivate={user?.isProfileImageVisible === false}
          />
        )}

        {isLoading &&
          times(skeletonRowCount).map((_, index) => {
            return <SkeletonRow key={index} />;
          })}

        {(leaderboardRows || []).map((row) => {
          const pointsTotal = formatNumberToLocale(parseFloat(row.seasonPoints), 0, 2);
          const points24h = formatNumberToLocale(parseFloat(row.points24h), 0, 2);
          const seasonRank = parseFloat(row.seasonRank);
          const dailyRank = parseFloat(row.dailyRank);

          return (
            <Row
              key={`${sort}-${row.user.address}`}
              rank={sort === LeaderboardSortInput.SEASON_RANK_ASC ? seasonRank : dailyRank}
              accountAddress={row.user.address}
              pointsTotal={pointsTotal}
              points24h={points24h}
              name={row.user.name}
              addressIsVerified={!!row.user?.isVerified}
              image={row.user.avatar?.image}
              isConnectedAccount={isAddressEqual(row.user.address, connectedAddress)}
              isMobile={!!isMobile}
              sort={sort}
            />
          );
        })}
        <TableFooter
          currentPageNumber={currentPageNumber}
          // 1 for the user row that is inserted at the top
          totalPagesNumber={totalPages === 0 && shouldShowUserRow ? 1 : totalPages}
          goToPage={setCurrentPageNumber}
          labelPrefix="season-leaderboard"
        />
      </Stack>
    </Box>
  );
};
