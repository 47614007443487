import { Box, Flex } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { CheckmarkFilledIcon, RadioButtonIcon } from "@looksrare/uikit";
import { formatCompactNumber } from "@looksrare/utils";
import { Button, Text, TextProps } from "@looksrare/uikit";
import { MilestoneLevelCode } from "@/types/points";
import { ClaimMilestoneLevelButton } from "@/views/Rewards/components/Buttons/ClaimMilestoneLevelButton";

export interface MilestoneLevelRowProps {
  code: MilestoneLevelCode;
  claimedAt?: string | null;
  createdAt?: string | null;
  points: number | null;
  title: string;
  description?: string;
  descriptionProps?: TextProps;
}

export const MilestoneLevelRow = ({
  code,
  claimedAt,
  createdAt,
  points,
  title,
  description,
  descriptionProps,
}: MilestoneLevelRowProps) => {
  const { t } = useTranslation();

  const isHiddenReward = points === null;
  const formattedPoints = isHiddenReward ? "???" : formatCompactNumber(points);

  const icon = (() => {
    if (claimedAt) {
      return <CheckmarkFilledIcon boxSize={6} color="interactive-03" />;
    }
    // When createdAt is defined - the milestone has been created on the BE and can be claimed
    if (!!createdAt) {
      return <RadioButtonIcon boxSize={6} color="interactive-03" />;
    }
    return <RadioButtonIcon boxSize={6} color="text-disabled" />;
  })();

  const rightColumn = (() => {
    if (!!claimedAt) {
      return (
        <Button size="xs" isDisabled>
          {t("Claimed {{amount}}", { amount: points })}
        </Button>
      );
    }
    if (!!createdAt) {
      return (
        <ClaimMilestoneLevelButton size="xs" milestoneLevelCode={code}>
          {t("Claim {{amount}}", { amount: formattedPoints })}
        </ClaimMilestoneLevelButton>
      );
    }

    return (
      <Flex gap={1}>
        <Text textStyle="detail" color={isHiddenReward ? "text-disabled" : "text-02"}>
          {formattedPoints} Pts
        </Text>
      </Flex>
    );
  })();

  return (
    <Flex gap={3} alignItems="center" py={2}>
      <Flex width={8} alignItems="center" justifyContent="center" height="100%">
        {icon}
      </Flex>
      <Box flexGrow={1}>
        <Text textStyle="detail" bold={!isHiddenReward} color={isHiddenReward ? "text-disabled" : "text-02"}>
          {title}
        </Text>
        {description && (
          <Text textStyle="detail" color="text-03" noOfLines={1} {...descriptionProps}>
            {description}
          </Text>
        )}
      </Box>
      <Flex>{rightColumn}</Flex>
    </Flex>
  );
};
