import { useTranslation } from "next-i18next";
import { ModalBody, Stack } from "@chakra-ui/react";
import { Button, ModalFooterStack, ModalLegacy, ModalLegacyProps, NextLink } from "@looksrare/uikit";
import { getMoDUrl } from "@looksrare/moon-or-doom";
import { ChainId } from "@looksrare/config";
import { MilestoneCode, UserMilestoneLevel } from "@/types/points";
import { useGetMilestoneLevelText } from "../hooks";
import { MilestoneLevelRow } from "./MilestoneLevelRow";
import { MilestoneModalHeader } from "./MilestoneModalHeader";

export const YoloCta = () => {
  const { t } = useTranslation();
  return (
    <Button as={NextLink} href="/yolo" width="100%">
      {t("Play YOLO")}
    </Button>
  );
};

export const PtbCta = () => {
  const { t } = useTranslation();
  return (
    <Button as={NextLink} href="/poke-the-bear" width="100%">
      {t("Play Poke The Bear")}
    </Button>
  );
};

export const MordCta = () => {
  const { t } = useTranslation();
  return (
    <Button as={NextLink} href={getMoDUrl(ChainId.BLAST_MAINNET, "ETHUSD")} width="100%">
      {t("Play MOON or DOOM")}
    </Button>
  );
};

export const FlipperCta = () => {
  const { t } = useTranslation();
  return (
    <Button as={NextLink} href="/flipper" width="100%">
      {t("Play Flipper")}
    </Button>
  );
};

export const QuantumCta = () => {
  const { t } = useTranslation();
  return (
    <Button as={NextLink} href="/quantum" width="100%">
      {t("Play Quantum")}
    </Button>
  );
};

export const DontFallInCta = () => {
  const { t } = useTranslation();
  return (
    <Button as={NextLink} href="/dont-fall-in" width="100%">
      {t("Play Don't Fall In")}
    </Button>
  );
};

export const LaserBlastCta = () => {
  const { t } = useTranslation();
  return (
    <Button as={NextLink} href="/laser-blast" width="100%">
      {t("Play LaserBlast")}
    </Button>
  );
};

interface MilestoneModalProps extends Omit<ModalLegacyProps, "children"> {
  levels: UserMilestoneLevel[];
  milestoneCode: MilestoneCode;
  titleText: string;
  ctaElement: JSX.Element;
}
export const MilestoneModal = ({
  isOpen,
  onClose,
  milestoneCode,
  titleText,
  levels,
  ctaElement,
  ...props
}: MilestoneModalProps) => {
  const getMilestoneLevelText = useGetMilestoneLevelText();

  return (
    <ModalLegacy isOpen={isOpen} onClose={onClose} hideHeader scrollBehavior="inside" {...props}>
      <MilestoneModalHeader onClose={onClose} code={milestoneCode} titleText={titleText} />
      {!!levels.length && (
        <ModalBody py={6}>
          <Stack spacing={0}>
            {levels.map((level) => {
              const { title, description } = getMilestoneLevelText(level);
              return (
                <MilestoneLevelRow
                  key={title}
                  descriptionProps={{ noOfLines: 2 }}
                  description={description}
                  title={title}
                  {...level}
                />
              );
            })}
          </Stack>
        </ModalBody>
      )}
      <ModalFooterStack>
        {/* @todo-yg wrap in ConnectWalletGuard */}
        {ctaElement}
      </ModalFooterStack>
    </ModalLegacy>
  );
};
