import { useEffect, useRef } from "react";
import { Box, useToken } from "@chakra-ui/react";
import Highcharts from "highcharts";
import { formatNumberToLocale } from "@looksrare/utils";
import { LiquidityPoolInterval } from "@looksrare/yolo-games-gql-typegen";
import { liquidityChartLabelFormatter } from "@/utils";
import { defaultHighchartsOptions } from "./shared";

interface PositionPnLChartProps {
  chartData: number[][];
  timeInterval: LiquidityPoolInterval;
  displayDecimals?: number;
}

export const PositionPnLChart = ({ chartData, timeInterval, displayDecimals = 4 }: PositionPnLChartProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [green400, textPlaceholder, border01, ui01, text01, red400] = useToken("colors", [
    "green.400",
    "text-placeholder",
    "border-01",
    "ui-01",
    "text-01",
    "red.400",
  ]);

  useEffect(() => {
    if (ref.current) {
      const options: Highcharts.Options = {
        chart: {
          type: "area",
          marginLeft: 0,
        },
        tooltip: {
          formatter: function () {
            return !!this.y ? formatNumberToLocale(this.y, 0, displayDecimals) : this.y?.toString();
          },
          backgroundColor: ui01,
          borderColor: border01,
          borderWidth: 1,
          borderRadius: 4,
          style: {
            color: text01,
            fontWeight: "bold",
          },
        },
        xAxis: {
          type: "datetime",
          dateTimeLabelFormats: {
            second: "%H:%M",
            minute: "%H:%M",
            hour: "%H:%M",
          },
          gridLineColor: border01,
          gridLineWidth: 1,
          lineWidth: 0,
          tickWidth: 0,
          labels: {
            formatter: (input) => liquidityChartLabelFormatter(input, timeInterval),
            style: { color: textPlaceholder },
          },
        },
        yAxis: {
          opposite: true,
          gridLineColor: border01,
          gridLineWidth: 1,
          lineWidth: 0,
          tickWidth: 0,
          labels: {
            style: { color: textPlaceholder },
          },
        },
        series: [
          {
            type: "area",
            data: chartData,
            marker: { enabled: false },
            zones: [
              {
                value: 0,
                color: red400,
                fillColor: `${red400}40`,
              },
              {
                color: green400,
                fillColor: `${green400}40`,
              },
            ],
          },
        ],
      };
      Highcharts.chart(ref.current, Highcharts.merge(defaultHighchartsOptions, options));
    }
  }, [ref, chartData, green400, border01, ui01, text01, textPlaceholder, red400, displayDecimals, timeInterval]);

  return <Box ref={ref} />;
};
