import { Flex, FlexProps } from "@chakra-ui/react";
import { IconProps, Text } from "@looksrare/uikit";

interface CountTagProps extends FlexProps {
  icon: (props: IconProps) => JSX.Element;
  text: string;
}

export const CountTag = ({ icon: Icon, text, ...props }: CountTagProps) => {
  return (
    <Flex
      py="6px"
      pr={3}
      pl={2}
      borderRadius="circular"
      backgroundColor="interactive-02"
      gap="6px"
      alignItems="center"
      {...props}
    >
      <Icon boxSize={5} color="link-01" />
      <Text textStyle="detail" color="link-02" bold>
        {text}
      </Text>
    </Flex>
  );
};
