import { VStack } from "@chakra-ui/react";
import { Button, NextLink, Text } from "@looksrare/uikit";
import { useTranslation } from "next-i18next";

interface SeasonEndProps {
  season: number;
}

export const SeasonEnd = ({ season }: SeasonEndProps) => {
  const { t } = useTranslation();

  return (
    <VStack alignItems="center" justifyContent="center" h="80vh">
      <VStack spacing={4} w="100%" maxW="800px">
        <Text textStyle="display-01" textAlign="center" bold>
          {t("Season {{number}} Complete", { number: season })}
        </Text>
        <Text textAlign="center" color="text-02">
          {t(
            "The Season {{number}} airdrop claim is now closed. Any unclaimed YOLO tokens have been directed to the treasury for future initiatives.",
            { number: season }
          )}
        </Text>
        <Button as={NextLink} href="/rewards" colorScheme="brand" w="100%" maxWidth="248px">
          {t("Join Season {{number}}", { number: season + 1 })}
        </Button>
      </VStack>
    </VStack>
  );
};
