import { useState } from "react";
import { Address } from "viem";
import { useTranslation } from "next-i18next";
import { Box, Flex } from "@chakra-ui/react";
import { Button, ProfileAvatar } from "@looksrare/uikit";
import type { User } from "@/types/user";
import { FormLabel } from "@/components/Form";
import { useInvalidateUser, useToggleProfileImageVisibility } from "@/hooks/user";
import { useToast } from "@/hooks";
import { UserProfileForm } from "./UserProfileForm";

export interface SettingContentProps {
  address: Address;
  user: User;
}

export const ProfileContent = ({ address, user }: SettingContentProps) => {
  const [isProfileImageVisible, setProfileImageVisible] = useState(!!user.isProfileImageVisible);
  const { t } = useTranslation();
  const { toast } = useToast();
  const invalidateUserQuery = useInvalidateUser();

  const { mutate: toggleProfileImageVisibility, isPending } = useToggleProfileImageVisibility({
    onSuccess: () => {
      setProfileImageVisible(!isProfileImageVisible);
      invalidateUserQuery(address);
      toast({
        status: "success",
        description: isProfileImageVisible
          ? t("Your avatar is now set to private.")
          : t("Your avatar is now set to public."),
      });
    },
  });

  return (
    <>
      <Box mb={8}>
        <FormLabel textStyle="detail" mb={4}>
          {t("Profile Image")}
        </FormLabel>
        <Flex alignItems="center" mb={8}>
          <ProfileAvatar
            src={user.avatar?.image.src}
            address={address}
            size={96}
            mr={6}
            isPrivate={user.isProfileImageVisible === false}
          />
          <Button variant="outline" size="sm" onClick={toggleProfileImageVisibility} isLoading={isPending}>
            {isProfileImageVisible ? t("Make Avatar Private") : t("Make Avatar Public")}
          </Button>
        </Flex>
      </Box>
      <UserProfileForm address={address} user={user} />
    </>
  );
};
