import { useTranslation } from "next-i18next";
import { Box, Flex, IconButton, Stack } from "@chakra-ui/react";
import { CloseIcon, Text, VerifiedIcon } from "@looksrare/uikit";
import { MilestoneCode } from "@/types/points";
import { MilestoneIcon } from "../Milestone";
import { DescriptionText } from "./DescriptionText";

const VerifiedOnlyWarning = () => {
  const { t } = useTranslation();
  return (
    <Stack alignItems="center">
      <Flex alignItems="center" gap={1}>
        <VerifiedIcon boxSize={4} />
        <Text textStyle="detail" color="text-warning">
          {t("Verified collections only.")}
        </Text>
      </Flex>
      <Text textStyle="detail" color="text-warning" textAlign="center">
        {t("Sales must be from LooksRare listings or offers to count: sales from other marketplaces aren’t eligible.")}
      </Text>
    </Stack>
  );
};

interface MilestoneModalHeaderProps {
  code: MilestoneCode;
  titleText: string;
  onClose: () => void;
}
export const MilestoneModalHeader = ({ code, titleText, onClose }: MilestoneModalHeaderProps) => {
  return (
    <Box pt={4} px={8}>
      <Flex width="100%" py={6} justifyContent="center">
        <IconButton
          variant="ghost"
          size="xs"
          position="absolute"
          top={4}
          right={4}
          color="link-02"
          onClick={onClose}
          aria-label="close-modal"
        >
          <CloseIcon />
        </IconButton>
        <Stack spacing={4} alignItems="center">
          <MilestoneIcon width={16} height={16} milestoneCode={code} />
          <Stack spacing={2} alignItems="center">
            <Text textStyle="heading-03" bold>
              {titleText}
            </Text>
            <Stack spacing={4}>
              <DescriptionText code={code} />
              {["MKTP_BUY_SELL", "MKTP_STREAK"].includes(code) && <VerifiedOnlyWarning />}
            </Stack>
          </Stack>
        </Stack>
      </Flex>
    </Box>
  );
};
