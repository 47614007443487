import { HStack, Stack } from "@chakra-ui/react";
import { Text, currencyTokenIconMap } from "@looksrare/uikit";
import { formatToDisplayDecimals } from "@looksrare/utils";
import { Currency } from "@looksrare/yolo-games-gql-typegen";
import { fontSizes, fonts } from "@/theme/typography";

export interface CurrencyRowProps {
  amountWei: bigint;
  amountDollar: number;
  currency: Currency;
}

export const CurrencyRow = ({ amountWei, currency }: CurrencyRowProps) => {
  const TokenIcon = currencyTokenIconMap[currency.symbol];

  return (
    <HStack spacing={4}>
      <TokenIcon boxSize={12} />

      <Stack spacing={0}>
        <Text
          fontFamily={fonts.heading}
          fontSize={fontSizes["1xl"]}
          fontWeight="bold"
          lineHeight="1.75rem"
          letterSpacing="-0.02em"
          color={amountWei > 0n ? "text-01" : "text-disabled"}
        >
          {formatToDisplayDecimals({ amountWei, currency })}
        </Text>

        {/* @TODO-rakeback add back when USD conversion is ready */}
        {/* <Text textStyle="detail" color="text-03">
          {formatUsd(amountDollar)}
        </Text> */}
      </Stack>
    </HStack>
  );
};
