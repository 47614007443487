import { getTopicName, TopicName, useRealtimeSubscription } from "@looksrare/utils";

export const useDontFallInPageViews = () => {
  const topicName = getTopicName({ name: TopicName.dontFallInActiveViews });
  return useRealtimeSubscription<{ count: number }>(topicName);
};

export const useDontFallInActivePlayers = () => {
  const topicName = getTopicName({ name: TopicName.dontFallInActivePlayers });
  return useRealtimeSubscription<number>(topicName);
};
