import { Flex, FlexProps } from "@chakra-ui/react";
import { IconProps, Text } from "@looksrare/uikit";
import { useBoxShadowPreset } from "@/hooks/useBoxShadowPreset";

interface SeasonThemeProps extends FlexProps {
  title: string;
  description: string;
  icon: (props: IconProps) => JSX.Element;
}

export const SeasonTheme = ({ title, description, icon: Icon, ...props }: SeasonThemeProps) => {
  const boxShadowCard = useBoxShadowPreset("card");
  const boxShadowCardHover = useBoxShadowPreset("card-hover");

  const cardHoverSx = {
    cursor: "pointer",
    _hover: {
      background: "hover-ui",
      boxShadow: boxShadowCardHover,
      transform: "translateY(-2px)",
    },
    _active: {
      background: "onclick-ui",
      boxShadow: boxShadowCardHover,
      transform: "translateY(-2px)",
    },
  };

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      width="100%"
      bg="ui-bg"
      bgGradient="ui-gradient-v-01"
      py={6}
      px={4}
      gap={2}
      border="1px solid"
      borderRadius="dialog"
      borderColor="border-01"
      boxShadow={boxShadowCard}
      transform="translateY(0px)"
      transition="all 200ms ease-out 0s"
      sx={cardHoverSx}
      {...props}
    >
      <Icon boxSize={16} color="link-01" />
      <Text textColor="text-02" bold>
        {title}
      </Text>
      <Text textColor="text-03" textStyle="detail">
        {description}
      </Text>
    </Flex>
  );
};
