import { useTranslation } from "next-i18next";
import { usePreviousValue } from "@looksrare/utils";
import type { LiquidityPoolGame, LiquidityPoolMetricsFilterInput } from "@looksrare/yolo-games-gql-typegen";
import { FC } from "react";
import { IconProps } from "@looksrare/uikit";
import sumBy from "lodash/sumBy";
import { useGlobalLiqudityPoolMetrics } from "../../hooks";
import { aggregateMetrics, getPercentageChange } from "../../utils";
import { ChartBox } from "./ChartBox";
import { LiquidityPoolBarChart } from "./LiquidityPoolBarChart";

interface GlobalFeesEarnedProps {
  gameContract: LiquidityPoolGame | "ALL_GAMES";
  filters: LiquidityPoolMetricsFilterInput;
  poolIcon: FC<IconProps>;
  currencySymbol: string;
}

export const GlobalFeesEarned = ({
  gameContract,
  poolIcon: PoolIcon,
  filters,
  currencySymbol,
}: GlobalFeesEarnedProps) => {
  const { t } = useTranslation();
  const poolMetricsQuery = useGlobalLiqudityPoolMetrics({ filters });
  const feesEarnedMetrics = poolMetricsQuery.data?.filter((metric) => metric.metricType === "LP_FEES_EARNED") ?? [];
  const prevFeesEarnedMetrics = usePreviousValue(feesEarnedMetrics);
  const currentFeesEarnedMetrics = feesEarnedMetrics || prevFeesEarnedMetrics;
  const currentFeesEarnedMetricsChart =
    gameContract === "ALL_GAMES"
      ? currentFeesEarnedMetrics
      : currentFeesEarnedMetrics.filter((metric) => metric.gameContract === gameContract);
  const currentFeesEarnedTotal = sumBy(currentFeesEarnedMetricsChart, "intervalTotal");
  const aggregatedMetrics = aggregateMetrics(currentFeesEarnedMetricsChart);
  const intervalPctChange = getPercentageChange(
    aggregatedMetrics.currentCumulativeTotal,
    aggregatedMetrics.previousCumulativeTotal
  );

  return (
    <ChartBox
      isLoading={poolMetricsQuery.isFetching}
      hasData={!!currentFeesEarnedMetricsChart && currentFeesEarnedMetricsChart.length > 0}
      chartTitle={t("liq::Fees Earned")}
      timeInterval={filters.timeInterval}
      currencySymbol={currencySymbol}
      currencyIcon={PoolIcon}
      intervalPctChange={intervalPctChange}
      intervalTotal={currentFeesEarnedTotal}
      valueVumUsd={0}
    >
      <LiquidityPoolBarChart chartData={currentFeesEarnedMetricsChart} timeInterval={filters.timeInterval} />
    </ChartBox>
  );
};
