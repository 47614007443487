import { Box } from "@chakra-ui/react";
import { Container } from "@looksrare/uikit";
import { Hero, LiquidityHeroTab, LiquidityHistory, LiquidityPerformance, LiquidityPools } from "./components";

export const LiquidityView = () => {
  return (
    <Box>
      <Hero tab={LiquidityHeroTab.YOUR_POSITION} />
      <Container py={8} maxWidth="1200px">
        <LiquidityPools />
        <LiquidityPerformance />
        <LiquidityHistory />
      </Container>
    </Box>
  );
};
