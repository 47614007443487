import { IconButton, InputGroup, InputRightElement, Stack } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { ArrowRightIcon, CheckmarkFilledIcon, Input } from "@looksrare/uikit";
import { InputError, InputMessageGroup } from "@/components/Form";
import { User } from "@/types";
import { updateUserEmail } from "@/hooks/user";

interface Inputs {
  email: string;
}

export interface Props {
  user: User;
}

const VALID_EMAIL_REGEX = new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);

export const EmailNotificationInput = ({ user }: Props) => {
  const { t } = useTranslation();
  const [alertMessage, setAlertMessage] = useState("");

  const {
    register,
    handleSubmit,
    formState: { isSubmitSuccessful, isSubmitting, errors, isValid },
  } = useForm<Inputs>({
    mode: "onChange",
    defaultValues: {
      email: user.email || "",
    },
  });

  const isPendingConfirmation = user.email && !user.isEmailVerified;

  const onSubmit: SubmitHandler<Inputs> = async ({ email }) => {
    try {
      const subscribeSuccess = await updateUserEmail(user.address, user.isEmailVerified ? null : email);

      if (subscribeSuccess) {
        setAlertMessage(t("Got it! You'll receive an email with instructions to complete setup."));
      } else {
        setAlertMessage(t("Already subscribed."));
      }
    } catch {
      setAlertMessage(t("Couldn't subscribe. Please try again later."));
    }
  };

  return (
    <form method="post" onSubmit={handleSubmit(onSubmit)}>
      <Stack textStyle="body" alignItems="center" position="relative" width="100%" borderRadius="button">
        <InputGroup borderRadius="button" alignItems="center" padding={1} bgColor="field-02">
          <Input
            {...register("email", {
              required: true,
              pattern: {
                value: VALID_EMAIL_REGEX,
                message: t("Invalid email address."),
              },
            })}
            borderRadius="button"
            height={12}
            backgroundColor="transparent"
            _focus={{ boxShadow: "none" }}
            w={{ base: "90vw", md: "486px" }}
            placeholder={t("Your Email Address")}
          />
          <InputRightElement width="max-content" height="100%" mr={1}>
            <IconButton
              type="submit"
              isLoading={isSubmitting}
              colorScheme={isValid && !isSubmitSuccessful ? "white" : "whiteAlpha"}
              borderRadius="button"
              size="md"
              disabled={isSubmitting || isSubmitSuccessful}
              aria-label={t("Get In Early")}
            >
              {isSubmitSuccessful ? (
                <CheckmarkFilledIcon color="white" />
              ) : (
                <ArrowRightIcon color={isValid ? "black" : "white"} />
              )}
            </IconButton>
          </InputRightElement>
        </InputGroup>
        <InputMessageGroup position="absolute" top="100%" left={0}>
          <InputError color={isSubmitSuccessful || isPendingConfirmation ? "text-03" : "text-error"}>
            {isPendingConfirmation
              ? t("Check your inbox! You'll receive an email with instructions to complete setup.")
              : errors.email?.message ?? alertMessage}
          </InputError>
        </InputMessageGroup>
      </Stack>
    </form>
  );
};
