import { Box, Flex, IconButton } from "@chakra-ui/react";
import { DiscordIcon, Link, TwitterIcon } from "@looksrare/uikit";
import { YoloLogoColorOnDark } from "@/components/Assets";

export const Header = () => {
  return (
    <Flex p={6} gap={4} width="100%">
      <Flex width="100%">
        <YoloLogoColorOnDark w="96px" h="48px" />
      </Flex>
      {/* Spacer */}
      <Box flexGrow={1} />
      <Flex gap={{ base: 4, md: 0 }}>
        <IconButton
          size="md"
          colorScheme="secondary"
          variant="ghost"
          aria-label="x-link"
          as={Link}
          href="https://twitter.com/YOLO_Blast"
          isExternal
        >
          <TwitterIcon boxSize={5} />
        </IconButton>
        <IconButton
          size="md"
          colorScheme="secondary"
          variant="ghost"
          aria-label="discord-link"
          as={Link}
          href="https://discord.gg/7SHJdtZz37"
          isExternal
        >
          <DiscordIcon boxSize={5} />
        </IconButton>
      </Flex>
    </Flex>
  );
};
