import { useRef, useState } from "react";
import { Grid, IconButton, VStack } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { Chevron } from "@looksrare/uikit";
import { Carousel, useCarouselControl, useCarouselControlOptions, useCarouselProgress } from "@looksrare/uikit";
import { HeroCarouselSlide, HeroCarouselSlideProps } from "./components/HeroCarouselSlide";
import { HeroCarouselProgress } from "./components/HeroCarouselProgress";

const DEFAULT_AUTO_INTERVAL = 5000;

interface HeroCarouselProps {
  slides: HeroCarouselSlideProps[];
  options?: Partial<Pick<useCarouselControlOptions, "interval" | "isAutomatic">>;
}

export const HeroCarousel = ({ slides, options }: HeroCarouselProps) => {
  const { t } = useTranslation();
  const carouselRef = useRef<HTMLDivElement>(null);
  const [isPaused, setIsPaused] = useState(false);

  const interval = options?.interval ?? DEFAULT_AUTO_INTERVAL;
  const isAutomatic = options?.isAutomatic ?? true;

  const { previous, next, scrollToSlide, isPreviousDisabled, isNextDisabled, slideIndex, prevAutoSlideTime } =
    useCarouselControl(carouselRef, {
      interval,
      isAutomatic,
      isPaused,
    });
  const carouselProgress = useCarouselProgress({ isAutomatic, isPaused, interval, prevAutoSlideTime });

  return (
    <VStack spacing={0}>
      <Grid
        gridTemplateColumns={{ base: "100%", lg: "48px calc(100% - 96px - 16px) 48px" }}
        gap={2}
        width="100%"
        height="min-content"
      >
        <IconButton
          display={{ base: "none", lg: "block" }}
          icon={<Chevron direction="left" />}
          aria-label={t("previous slide")}
          variant="ghost"
          colorScheme="secondary"
          height="480px"
          onClick={previous}
          isDisabled={isPreviousDisabled}
        />

        <Carousel
          flex={1}
          gap={4}
          ref={carouselRef}
          alignItems="center"
          contentContainerProps={{ width: "100%" }}
          hideScrollbar
          onMouseOver={() => setIsPaused(true)}
          onMouseLeave={() => setIsPaused(false)}
          onTouchStart={() => setIsPaused(true)}
          onTouchEnd={() => setIsPaused(false)}
          px={{ base: 4, lg: 0 }}
          scrollPaddingX={{ base: 4, lg: 0 }}
          height="min-content"
        >
          {slides.map((slide) => (
            <HeroCarouselSlide key={slide.title} height={{ lg: "480px" }} {...slide} />
          ))}
        </Carousel>

        <IconButton
          display={{ base: "none", lg: "block" }}
          icon={<Chevron direction="right" />}
          aria-label={t("next slide")}
          variant="ghost"
          colorScheme="secondary"
          height="480px"
          onClick={next}
          isDisabled={isNextDisabled}
        />
      </Grid>

      <HeroCarouselProgress
        currentSlide={slideIndex + 1}
        totalSlides={slides.length}
        progress={carouselProgress}
        isAutomatic={isAutomatic}
        onBarClick={scrollToSlide}
      />
    </VStack>
  );
};
