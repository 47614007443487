import { Modal } from "@looksrare/uikit";
import { GroupedRakebacks } from "@looksrare/yolo-games-gql-typegen";
import { PendingRakebacksModalBody } from "./PendingRakebacksModalBody";

interface PendingRakebacksModalProps {
  isOpen: boolean;
  onClose: () => void;
  rakebacks?: GroupedRakebacks;
}

export const PendingRakebacksModal = ({ isOpen, onClose, rakebacks }: PendingRakebacksModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <PendingRakebacksModalBody onClose={onClose} rakebacks={rakebacks} />
    </Modal>
  );
};
