import type { Address } from "viem";
import { useInfiniteQuery } from "@tanstack/react-query";
import { getUserLiqudityEvents } from "../queries";

const defaultPagination = 10;

export const useUserLiquidityPoolEvents = (address?: Address) => {
  return useInfiniteQuery({
    queryKey: ["user-liquidity-pools-events", address],
    queryFn: ({ pageParam }) => getUserLiqudityEvents({ address, pagination: pageParam }),
    getNextPageParam: (lastPage) => {
      if (!lastPage || lastPage.length < defaultPagination) {
        return undefined;
      }
      const lastItem = lastPage[lastPage.length - 1];
      return {
        first: defaultPagination,
        cursor: lastItem.createdAt,
      };
    },
    initialPageParam: { first: defaultPagination },
  });
};
