import { Flex } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { Text, TextProps } from "@looksrare/uikit";

export interface FormLabelProps extends TextProps {
  isRequired?: boolean;
}

export const FormLabel: React.FC<React.PropsWithChildren<FormLabelProps>> = ({
  isRequired = false,
  children,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Text color="text-03" textStyle="detail" bold mb={2} {...props}>
      <Flex alignItems="center">
        {children}{" "}
        {isRequired && (
          <Text color="text-error" textStyle="detail" bold ml={1}>
            {`(${t("Required")})`}
          </Text>
        )}
      </Flex>
    </Text>
  );
};
