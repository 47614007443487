import { Center, TextProps } from "@chakra-ui/react";
import {
  RankFirst,
  RankFirstGradient,
  RankSecond,
  RankSecondGradient,
  RankThird,
  RankThirdGradient,
  Text,
} from "@looksrare/uikit";
import { formatNumberToLocale } from "@looksrare/utils";

interface Props {
  rank?: number;
  textProps?: TextProps;
}

export const Rank = ({ rank, textProps }: Props) => {
  const rankDisplay = (() => {
    if (!rank) {
      return "-";
    }
    return rank > 999 ? "999+" : formatNumberToLocale(rank, 0, 0);
  })();

  if (rank === 1) {
    return (
      <Center width="100%">
        <RankFirst boxSize={8} />
      </Center>
    );
  }
  if (rank === 2) {
    return (
      <Center width="100%">
        <RankSecond boxSize={8} />
      </Center>
    );
  }
  if (rank === 3) {
    return (
      <Center width="100%">
        <RankThird boxSize={8} />
      </Center>
    );
  }
  return (
    <Text textAlign="center" width="100%" whiteSpace="nowrap" {...textProps}>
      {rankDisplay}
    </Text>
  );
};

export const RankBackground = ({ rank }: { rank: number }) => {
  if (rank === 1) {
    return <RankFirstGradient width="512px" height="512px" />;
  }
  if (rank === 2) {
    return <RankSecondGradient width="512px" height="512px" />;
  }
  if (rank === 3) {
    return <RankThirdGradient width="512px" height="512px" />;
  }

  return null;
};
