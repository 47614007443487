import { useTranslation } from "next-i18next";
import { usePreviousValue } from "@looksrare/utils";
import type { LiquidityPoolGame, LiquidityPoolMetricsFilterInput } from "@looksrare/yolo-games-gql-typegen";
import { FC } from "react";
import { IconProps } from "@looksrare/uikit";
import sumBy from "lodash/sumBy";
import { useGlobalLiqudityPoolMetrics } from "../../hooks";
import { aggregateMetrics, getPercentageChange } from "../../utils";
import { ChartBox } from "./ChartBox";
import { LiquidityPoolBarChart } from "./LiquidityPoolBarChart";

interface GlobalGameVolumeProps {
  gameContract: LiquidityPoolGame | "ALL_GAMES";
  filters: LiquidityPoolMetricsFilterInput;
  poolIcon: FC<IconProps>;
  currencySymbol: string;
}

export const GlobalGameVolume = ({
  gameContract,
  poolIcon: PoolIcon,
  filters,
  currencySymbol,
}: GlobalGameVolumeProps) => {
  const { t } = useTranslation();
  const poolMetricsQuery = useGlobalLiqudityPoolMetrics({ filters });
  const gameVolumeMetrics = poolMetricsQuery.data?.filter((metric) => metric.metricType === "LP_GAMES_VOLUME") ?? [];
  const prevGameVolumeMetrics = usePreviousValue(gameVolumeMetrics);
  const currentGameVolumeMetrics = gameVolumeMetrics || prevGameVolumeMetrics;
  const currentGameVolumeMetricsChart =
    gameContract === "ALL_GAMES"
      ? currentGameVolumeMetrics
      : currentGameVolumeMetrics.filter((metric) => metric.gameContract === gameContract);
  const currentGameVolumeTotal = sumBy(currentGameVolumeMetricsChart, "intervalTotal");
  const aggregatedMetrics = aggregateMetrics(currentGameVolumeMetricsChart);
  const intervalPctChange = getPercentageChange(
    aggregatedMetrics.currentCumulativeTotal,
    aggregatedMetrics.previousCumulativeTotal
  );

  return (
    <ChartBox
      isLoading={poolMetricsQuery.isFetching}
      hasData={!!currentGameVolumeMetricsChart && currentGameVolumeMetricsChart.length > 0}
      chartTitle={t("liq::Game Volume")}
      timeInterval={filters.timeInterval}
      currencySymbol={currencySymbol}
      currencyIcon={PoolIcon}
      intervalPctChange={intervalPctChange}
      intervalTotal={currentGameVolumeTotal}
      valueVumUsd={0}
    >
      <LiquidityPoolBarChart chartData={currentGameVolumeMetricsChart} timeInterval={filters.timeInterval} />
    </ChartBox>
  );
};
