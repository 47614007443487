import { useState } from "react";
import { Flex, StackProps, Stack } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { Button, ButtonToggle, Text } from "@looksrare/uikit";
import { SeasonLeaderboard } from "@/views/Rewards/components/SeasonLeaderboard/SeasonLeaderboard";
import { LeaderboardOption } from "@/views/Rewards/components/SeasonLeaderboard/shared";

export const SeasonLeaderboardWithHeader = ({ ...props }: StackProps) => {
  const { t } = useTranslation();
  const [tableOption, setTableOption] = useState<LeaderboardOption>(LeaderboardOption.SEASON_POINTS_DAILY_RANK_ASC);

  return (
    <Stack spacing={4} {...props}>
      <Flex justifyContent="space-between">
        <Text textStyle="heading-03" bold>
          {t("Leaderboard: Top Points Earners")}
        </Text>
        <ButtonToggle>
          <Button
            key="24h"
            variant={tableOption === LeaderboardOption.SEASON_POINTS_DAILY_RANK_ASC ? "solid" : "outline"}
            onClick={() => setTableOption(LeaderboardOption.SEASON_POINTS_DAILY_RANK_ASC)}
            color={tableOption === LeaderboardOption.SEASON_POINTS_DAILY_RANK_ASC ? "link-01" : "link-02"}
            size="xs"
            data-id="season-leaderboard-sort-rank-24h"
          >
            {t("24h")}
          </Button>
          <Button
            key="epoch"
            variant={tableOption === LeaderboardOption.EPOCH ? "solid" : "outline"}
            onClick={() => setTableOption(LeaderboardOption.EPOCH)}
            color={tableOption === LeaderboardOption.EPOCH ? "link-01" : "link-02"}
            size="xs"
            data-id="season-leaderboard-sort-rank-epoch"
          >
            {t("Epoch")}
          </Button>

          <Button
            key="Total"
            variant={tableOption === LeaderboardOption.SEASON_POINTS_RANK_ASC ? "solid" : "outline"}
            onClick={() => setTableOption(LeaderboardOption.SEASON_POINTS_RANK_ASC)}
            color={tableOption === LeaderboardOption.SEASON_POINTS_RANK_ASC ? "link-01" : "link-02"}
            size="xs"
            data-id="season-leaderboard-sort-rank-total"
          >
            {t("Total")}
          </Button>
        </ButtonToggle>
      </Flex>
      <SeasonLeaderboard tableOption={tableOption} />
    </Stack>
  );
};
