import { StackProps } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { useAccount } from "wagmi";
import { Button } from "@looksrare/uikit";
import { getSupportedNetworkFromChainId } from "@looksrare/utils";
import { useAssertNetworkDisclosure } from "@looksrare/utils/hooks/useAssertNetwork";
import { useCallback } from "react";
import { useInvalidateUserRakebacks, usePausedContractCurrencySymbols, useUserRakebackData } from "@/hooks/rakeback";
import { ClaimRakebackModal } from "@/components/Modals/ClaimRakebackModal/ClaimRakebackModal";
import { getDefaultChain } from "@/utils";
import { useMapRakebacksToCurrencyRows } from "../hooks/useMapRakebacksToCurrencyRows";
import { RakebackCard } from "./RakebackCard";

export const ReadyToClaimCard = (props: StackProps) => {
  const { t } = useTranslation();
  const network = getSupportedNetworkFromChainId(getDefaultChain());

  const { address } = useAccount();
  const mapToCurrencyRows = useMapRakebacksToCurrencyRows();
  const rakebackModalDisclosure = useAssertNetworkDisclosure({ network });

  const invalidateUserRakebacks = useInvalidateUserRakebacks({ address: address!, filter: { status: "CLAIMED" } });
  const { data: userRakebackData, refetch: refetchRakebackUserData } = useUserRakebackData(address!, {
    enabled: !!address,
  });
  const { data: pausedContractCurrencySymbols } = usePausedContractCurrencySymbols();

  const claimDetails = (userRakebackData?.rakebackClaimDetails ?? []).filter(Boolean);
  const unlockedRakebacks = userRakebackData?.rakebacks.rakebacks.unlocked ?? [];
  const currencyRows = mapToCurrencyRows(unlockedRakebacks);

  const nextRakeback = userRakebackData?.nextRakeback;
  const hasClaimableRakebacks = !!nextRakeback && nextRakeback.status === "UNLOCKED" && claimDetails.length > 0;
  const nextRakebackExpiresAt = nextRakeback ? new Date(nextRakeback.expiresAt) : new Date();

  const onSuccess = useCallback(() => {
    invalidateUserRakebacks();
    refetchRakebackUserData();
  }, [invalidateUserRakebacks, refetchRakebackUserData]);

  return (
    <>
      <RakebackCard
        title={t("Ready to Claim")}
        timerExpiresAt={nextRakebackExpiresAt}
        currencyRows={currencyRows}
        showTimer={hasClaimableRakebacks}
        isHighlighted={hasClaimableRakebacks}
        {...props}
      >
        {hasClaimableRakebacks ? (
          <Button onClick={rakebackModalDisclosure.onOpen} colorScheme="brand">
            {t("Claim All")}
          </Button>
        ) : (
          <Button isDisabled>{t("Nothing to Claim")}</Button>
        )}
      </RakebackCard>

      <ClaimRakebackModal
        isOpen={rakebackModalDisclosure.isOpen}
        onClose={rakebackModalDisclosure.onClose}
        userRakebacks={userRakebackData?.rakebacks}
        nextRakeback={userRakebackData?.nextRakeback}
        claimDetails={claimDetails}
        pausedContractCurrencySymbols={pausedContractCurrencySymbols}
        onSuccess={onSuccess}
      />
    </>
  );
};
