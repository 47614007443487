import { Box, HStack, VStack } from "@chakra-ui/react";
import { formatCompactNumber, formatNumberToLocale } from "@looksrare/utils";
import { Text } from "@looksrare/uikit";
import { textStyles } from "@/theme/textStyles";

interface TokenDropletsCounterProps {
  shardAmount: number;
  textStyle?: keyof typeof textStyles;
}

export const TokenDropletsCounter = ({ shardAmount, textStyle }: TokenDropletsCounterProps) => {
  const formattedValue =
    shardAmount < 1_000_000_000
      ? formatNumberToLocale(shardAmount, 0, 0)
      : formatCompactNumber(shardAmount, undefined, 5, 2);

  return (
    <HStack gap={0} overflow="hidden" margin={-1} padding={1}>
      {[...formattedValue].map((decimal, idx) => {
        const { lineHeight, fontSize } = textStyles[textStyle ?? "display-03"];

        if (isNaN(parseInt(decimal, 10))) {
          return (
            <VStack key={idx} height={lineHeight}>
              <Text textStyle={textStyle ?? "display-03"} color={shardAmount > 0 ? "text-acid" : "text-03"} bold>
                {decimal}
              </Text>
            </VStack>
          );
        }

        return (
          <Box key={idx} height={lineHeight} fontSize={fontSize}>
            <VStack
              /**
               * In order for this animation to work, we have to stack all 10 possible decimals
               * on top of each other. We do this by creating this vertical stack and mapping an
               * empty array of 10 elements. This puts them all on top of each other.
               * Now, in order to show the correct number, we have to transform-translate the stack
               * upwards by 10% times the decimal we wanna show. So say we wanna show "5", we do 10% * 5 = 50%.
               * However, since there's also gaps between all decimals of 8 pixels, we also have to
               * take this into account. So, for that, we need to add 8px divided by 10 (the number of decimals),
               * which will then be multiplied by the decimal we wanna show, so in our example "5".
               * So, in the case of "5", we do 10% * 5 = 50%, plus (8px / 10) * 5 = 4px. Which gives us 50% + 4px as result.
               * Css just allows us to combine those calculations in one, which looks a bit magical.
               * Then finally we negate the whole calculation since we need to transform upwards and now downwards.
               */
              transform={`translateY(calc(calc(10% + calc(8px / 10)) * ${decimal} * -1))`}
              transition="transform 1s ease, width 1s ease"
              sx={{ fontFeatureSettings: '"tnum" on, "lnum" on' }}
            >
              {[...Array(10)].map((_, _decimal) => (
                <Text
                  key={_decimal}
                  textStyle={textStyle ?? "display-03"}
                  color={shardAmount > 0 ? "text-acid" : "text-03"}
                  bold
                >
                  {_decimal}
                </Text>
              ))}
            </VStack>
          </Box>
        );
      })}
    </HStack>
  );
};
