import { gql } from "graphql-request";
import { useQuery } from "@tanstack/react-query";
import { BigIntish, RQueryOptions, graphql } from "@looksrare/utils";

export interface GameStats {
  totalPlayers: BigIntish;
  totalGamesPlayed: BigIntish;
  volumePlayedWei: BigIntish;
}

export const getGameStats = async (requestHeaders?: HeadersInit): Promise<GameStats> => {
  const query = gql`
    query GetGameStats {
      gameStats {
        totalPlayers
        totalGamesPlayed
        volumePlayedWei
      }
    }
  `;

  const res = await graphql<{ gameStats: GameStats }>({ query, requestHeaders });

  return res.gameStats;
};

export const useGameStats = (queryOptions?: RQueryOptions<GameStats>) => {
  return useQuery({
    queryKey: ["game-stats"],
    queryFn: () => getGameStats(),
    refetchInterval: 30_000,
    ...queryOptions,
  });
};
