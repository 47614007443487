import { Box, HStack } from "@chakra-ui/react";
import range from "lodash/range";

interface HeroCarouselProgressProps {
  currentSlide: number;
  totalSlides: number;
  progress: number;
  isAutomatic: boolean;
  onBarClick?: (index: number) => void;
}

export const HeroCarouselProgress = ({
  currentSlide,
  totalSlides,
  progress,
  isAutomatic,
  onBarClick,
}: HeroCarouselProgressProps) => {
  return (
    <HStack height={8}>
      {range(totalSlides).map((index) => {
        const isCurrentSlide = index === currentSlide - 1;
        const isActive = index < currentSlide;

        const rightPercentage = isActive ? "0%" : "100%";
        const shouldShowProgress = isCurrentSlide && isAutomatic;

        return (
          <Box
            key={index}
            position="relative"
            width="64px"
            height="4px"
            borderRadius="circular"
            overflow="hidden"
            cursor="pointer"
            onClick={() => onBarClick?.(index)}
            _before={{
              content: "''",
              position: "absolute",
              inset: 0,
              backgroundColor: "interactive-02",
              zIndex: 0,
            }}
            _after={{
              content: "''",
              position: "absolute",
              inset: 0,
              right: shouldShowProgress ? `${progress * 100}%` : rightPercentage,
              backgroundColor: "interactive-03",
              transition: shouldShowProgress ? "right 25ms" : "right 200ms",
              zIndex: 1,
            }}
          />
        );
      })}
    </HStack>
  );
};
