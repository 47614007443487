import { gql } from "graphql-request";
import { Address } from "viem";
import { useAccount } from "wagmi";
import { UseMutationOptions, useMutation, useQuery } from "@tanstack/react-query";
import { RQueryOptions, graphql } from "@looksrare/utils";
import { BoostAllocation, SuccessPayload } from "@/types";
import { getAuthHeader } from "@/utils";

interface GetBoostAllocationResponse {
  boostAllocation: BoostAllocation;
}

const getBoostAllocation = async (address: Address) => {
  const query = gql`
    query GetBoostAllocation($address: Address!) {
      boostAllocation(address: $address) {
        boostFromEthWei
        boostFromUsdWei
        ethDepositedWei
        usdDepositedWei
        totalBoostWei
        claimed
        burned
        proof
      }
    }
  `;

  const res: GetBoostAllocationResponse = await graphql({ query, params: { address } });
  return res.boostAllocation;
};

export const useBoostAllocation = (address: Address, options?: RQueryOptions<BoostAllocation | null>) => {
  return useQuery({ queryKey: ["boostAllocation", address], queryFn: () => getBoostAllocation(address), ...options });
};

/**
 * Allow a user that spent all their BOOST to exist boost mode. This will updated boostAllocation.burned to `true`
 */
interface ExitBoostModeResponse {
  exitBoostMode: {
    success: boolean;
  };
}
const exitBoostMode = async (address: Address) => {
  const headers = getAuthHeader(address);

  const query = gql`
    mutation ExitBoost {
      exitBoostMode {
        success
      }
    }
  `;

  const res: ExitBoostModeResponse = await graphql<{ exitBoostMode: SuccessPayload }>({
    query,
    requestHeaders: headers,
  });
  return res.exitBoostMode;
};

export const useExitBoostModeMutation = (options?: UseMutationOptions<SuccessPayload>) => {
  const { address } = useAccount();
  return useMutation({ mutationFn: () => exitBoostMode(address!), ...options });
};
