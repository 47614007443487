import { useTranslation } from "next-i18next";
import { ModalBody } from "@chakra-ui/react";
import { ModalHeroHeader, ModalLegacy, ModalProps, ExternalLink, Button, LiquidityIcon } from "@looksrare/uikit";

export const ProvideLiquidityModal = (props: Omit<ModalProps, "children">) => {
  const { t } = useTranslation();

  return (
    <ModalLegacy hideHeader {...props}>
      <ModalHeroHeader
        showCloseButton
        icon={LiquidityIcon}
        title={t("Provide Liquidity")}
        label={t("Deposit ETH & YOLO to secure Liquidity Pools, earn game fees and end-of-season rewards!")}
        onClose={props.onClose}
      />
      <ModalBody pt={0}>
        <Button
          as={ExternalLink}
          href="https://docs.yologames.io/games/liquidity-and-probabilities/liquidity-pools"
          isExternal
          width="full"
          colorScheme="secondary"
        >
          {t("Learn More")}
        </Button>
      </ModalBody>
    </ModalLegacy>
  );
};
