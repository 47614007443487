import { useWalletClient } from "wagmi";
import { Modal, type ModalProps } from "@looksrare/uikit";
import { sleep } from "@looksrare/utils";
import { ModalHeader } from "./ModalHeader";
import { DepositWithdraw, DepositWithdrawProps } from "./DepositWithdraw";
import { useDepositWithdrawStore } from "./store";
import { DepositWithdrawStep } from "./types";

interface DepositWithdrawModalProps extends DepositWithdrawProps {
  isOpen: ModalProps["isOpen"];
  onClose: ModalProps["onClose"];
  poolName: string;
}

export const DepositWithdrawModal = ({
  isOpen,
  onClose,
  poolName,
  contract,
  currency,
  dailyDroplets,
  depositFeeBps,
  minDepositVum,
  maxDepositVum,
  assetsBalanceVum,
  pendingDepositsAmountVum,
  assetsLimitVum,
  finalizationIncentiveEthWei,
  timelockDelaySecs,
  onFinalizeSuccess,
  currentMultiplier,
  initialAction,
}: DepositWithdrawModalProps) => {
  const [reset, step] = useDepositWithdrawStore((state) => [state.reset, state.step]);
  useWalletClient();

  const handleClose = async () => {
    onClose();
    // Reset after the modal is done animating
    await sleep(250);
    reset();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      modalContentProps={{ bg: "ui-bg" }}
      closeOnOverlayClick={[
        DepositWithdrawStep.DEPOSIT_CONFIRMATION,
        DepositWithdrawStep.WITHDRAWAL_CONFIRMATION,
      ].includes(step)}
    >
      <ModalHeader onCloseModal={handleClose}>{poolName}</ModalHeader>
      <DepositWithdraw
        contract={contract}
        currency={currency}
        dailyDroplets={dailyDroplets}
        depositFeeBps={depositFeeBps}
        minDepositVum={minDepositVum}
        maxDepositVum={maxDepositVum}
        assetsBalanceVum={assetsBalanceVum}
        pendingDepositsAmountVum={pendingDepositsAmountVum}
        assetsLimitVum={assetsLimitVum}
        finalizationIncentiveEthWei={finalizationIncentiveEthWei}
        timelockDelaySecs={timelockDelaySecs}
        onClose={handleClose}
        onFinalizeSuccess={onFinalizeSuccess}
        currentMultiplier={currentMultiplier}
        initialAction={initialAction}
      />
    </Modal>
  );
};

export * from "./types";
