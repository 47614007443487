import { useState } from "react";
import { Box, ModalBody, Stack, VStack } from "@chakra-ui/react";
import { type Currency } from "@looksrare/config";
import { Rakeback, RakebackClaimDetails, Rakebacks } from "@looksrare/yolo-games-gql-typegen";
import { getIsRakebackFullyPaused } from "@/hooks/rakeback";
import { ClaimRakebackSuccessHeader } from "./components/ClaimRakebackSuccessHeader";
import { ClaimRakebackUnlockedHeader } from "./components/ClaimRakebackUnlockedHeader";
import { ClaimRakebackLockedHeader } from "./components/ClaimRakebackLockedHeader";
import { ClaimRakebackProblemHeader } from "./components/ClaimRakebackProblemHeader";
import { ClaimRakebackCalculatingHeader } from "./components/ClaimRakebackCalculatingHeader";
import { ClaimRakebackTransactionRow } from "./components/ClaimRakebackTransactionRow";

// In case rakeback fails again for some reason
// we can set this to true to show the error message and disable transactions
const IS_RAKEBACK_PAUSED = false;

interface ClaimRakebackModalBodyProps {
  onClose: () => void;
  onSuccess?: () => void;
  pausedContractCurrencySymbols?: Currency[];
  userRakebacks?: Rakebacks | null;
  nextRakeback?: Rakeback | null;
  claimDetails: RakebackClaimDetails[];
}

export const ClaimRakebackModalBody = ({
  onClose,
  onSuccess,
  pausedContractCurrencySymbols,
  userRakebacks,
  nextRakeback,
  claimDetails,
}: ClaimRakebackModalBodyProps) => {
  const [currentTransactionIndex, setCurrentTransactionIndex] = useState(0);
  const [isSuccess, setSuccess] = useState(false);

  // We only want to do transactions for unpaused contracts
  const filteredClaimDetails = claimDetails.filter(
    (claim) => !pausedContractCurrencySymbols?.includes(claim.currency.symbol as Currency)
  );

  const isNextUnlocked = nextRakeback?.status === "UNLOCKED";
  const isTransactionReady = isNextUnlocked && filteredClaimDetails.length > 0 && !!userRakebacks && !isSuccess;

  const handleTransactionSuccess = () => {
    const nextIndex = currentTransactionIndex + 1;

    if (nextIndex === claimDetails.length) {
      setSuccess(true);
      onSuccess?.();
      return;
    }

    setCurrentTransactionIndex(nextIndex);
  };

  return (
    <>
      {(() => {
        if (IS_RAKEBACK_PAUSED) {
          return <ClaimRakebackProblemHeader onClose={onClose} />;
        }

        if (getIsRakebackFullyPaused(pausedContractCurrencySymbols)) {
          return <ClaimRakebackCalculatingHeader onClose={onClose} />;
        }

        if (isSuccess) {
          return <ClaimRakebackSuccessHeader onClose={onClose} />;
        }

        if (isNextUnlocked) {
          return <ClaimRakebackUnlockedHeader onClose={onClose} />;
        }

        if (nextRakeback) {
          return <ClaimRakebackLockedHeader onClose={onClose} nextUnlockAt={nextRakeback.unlocksAt} />;
        }

        return <ClaimRakebackCalculatingHeader onClose={onClose} />;
      })()}

      {!IS_RAKEBACK_PAUSED && (
        <ModalBody pt={0}>
          <VStack spacing={4}>
            {isTransactionReady && (
              <Box
                px={4}
                py={6}
                backgroundColor="ui-bg"
                borderRadius="container"
                borderWidth="1px"
                borderColor="interactive-03"
                width="100%"
              >
                <Stack spacing={4}>
                  {filteredClaimDetails.map((singleRakebackClaimDetails, index) => {
                    return (
                      <ClaimRakebackTransactionRow
                        key={singleRakebackClaimDetails?.currency.address}
                        claimDetails={singleRakebackClaimDetails}
                        userRakebacks={userRakebacks}
                        claimIndex={index}
                        isActive={currentTransactionIndex === index}
                        onSuccess={handleTransactionSuccess}
                      />
                    );
                  })}
                </Stack>
              </Box>
            )}
          </VStack>
        </ModalBody>
      )}
    </>
  );
};
