import { useEffect, useRef } from "react";
import { Box, useToken } from "@chakra-ui/react";
import Highcharts from "highcharts/highstock";
import {
  LiquidityPoolGranularity,
  LiquidityPoolInterval,
  LiquidityPoolTimeseries,
} from "@looksrare/yolo-games-gql-typegen";
import { liquidityChartLabelFormatter } from "@/utils";
import { gameContractToDisplay } from "../../utils";
import { defaultHighchartsOptions } from "./shared";

interface LiquidityPoolValueChartProps {
  chartData: Array<LiquidityPoolTimeseries>;
  timeInterval: LiquidityPoolInterval;
}

export const granularityToMins = (granularity: LiquidityPoolGranularity) => {
  switch (granularity) {
    case "_5m":
      return 5;
    case "_1H":
      return 60;
    case "_1D":
      return 60 * 24;
    case "_1W":
      return 60 * 24 * 7;
    case "_1M":
      return 60 * 24 * 30;
  }
};

export const LiquidityPoolBarChart = ({ chartData, timeInterval }: LiquidityPoolValueChartProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [green, blue, purple, orange, magenta, gray, textPlaceholder, border01, ui01, text01, bg01] = useToken(
    "colors",
    [
      "green.400",
      "blue.400",
      "purple.400",
      "orange.400",
      "magenta.500",
      "gray.400",
      "text-placeholder",
      "border-01",
      "ui-01",
      "text-01",
      "ui-bg",
    ]
  );

  useEffect(() => {
    const colorMap: Record<string, string> = {
      "green.400": green,
      "blue.400": blue,
      "purple.400": purple,
      "orange.400": orange,
      "gray.400": gray,
      "magenta.500": magenta,
    };

    if (ref.current) {
      const options: Highcharts.Options = {
        chart: {
          type: "column",
          marginLeft: 0,
        },
        tooltip: {
          shared: true,
          backgroundColor: ui01,
          borderColor: border01,
          borderWidth: 1,
          borderRadius: 4,
          style: {
            color: text01,
            fontWeight: "bold",
          },
        },
        xAxis: {
          type: "datetime",
          gridLineColor: border01,
          gridLineWidth: 1,
          lineWidth: 0,
          tickWidth: 0,
          labels: {
            formatter: (input) => liquidityChartLabelFormatter(input, timeInterval),
            style: { color: textPlaceholder },
          },
        },
        yAxis: {
          opposite: true,
          gridLineColor: border01,
          gridLineWidth: 1,
          lineWidth: 0,
          tickWidth: 0,
          labels: {
            style: { color: textPlaceholder },
          },
        },
        plotOptions: {
          column: {
            groupPadding: 0,
            stacking: "normal",
            grouping: false,
            pointPadding: 0,
          },
        },
        series: chartData.map((data) => {
          const game = data.gameContract && gameContractToDisplay(data.gameContract);
          const dataPointsPerColumn = data.data.length / 24;
          const groupingTimeMinutes = dataPointsPerColumn * granularityToMins(data.dataGranularity);

          return {
            type: "column",
            data: data.data,
            name: game?.name ?? "NA",
            color: game?.color ? colorMap[game.color] : gray,
            borderColor: bg01,
            borderWidth: 2,
            borderRadius: 2,
            dataGrouping: {
              enabled: true,
              forced: true,
              units: [["minute", [groupingTimeMinutes]]],
              approximation: "sum",
            },
          } satisfies Highcharts.SeriesOptionsType;
        }),
      };

      Highcharts.chart(ref.current, Highcharts.merge(defaultHighchartsOptions, options));
    }
  }, [
    ref,
    chartData,
    border01,
    ui01,
    text01,
    textPlaceholder,
    bg01,
    gray,
    green,
    blue,
    purple,
    orange,
    timeInterval,
    magenta,
  ]);

  return <Box ref={ref} />;
};
