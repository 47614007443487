import {
  HStack,
  Stack,
  Table,
  TableCellProps,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import { Button, EthTokenIcon, LazyLoad, Text, TokenYoloIcon } from "@looksrare/uikit";
import { useTranslation } from "next-i18next";
import { useAccount } from "wagmi";
import { useMemo } from "react";
import { formatToDisplayDecimals } from "@looksrare/utils";
import { groupBy } from "lodash";
import { format } from "date-fns/format";
import { Rakeback } from "@looksrare/yolo-games-gql-typegen";
import { useUserRakebacks } from "@/hooks/rakeback";

const ThText = ({ children, ...props }: TableCellProps) => {
  return (
    <Th {...props}>
      <Text textStyle="helper" color="text-03" bold>
        {children}
      </Text>
    </Th>
  );
};

export const RakebackHistory = () => {
  const { t } = useTranslation();

  const { address } = useAccount();
  const {
    data: userRakebacks,
    hasNextPage,
    fetchNextPage,
  } = useUserRakebacks(
    { address: address!, filter: { status: "CLAIMED" } },
    {
      enabled: !!address,
    }
  );

  const groupedHistoryRakebacks = useMemo(() => {
    const flattened = userRakebacks?.pages.flatMap((page) => page?.rakebacks.history).filter(Boolean) as
      | Rakeback[]
      | undefined;
    const sortedByDate = flattened?.sort(
      (a, b) => new Date(b.claimedAt ?? b.expiresAt).getTime() - new Date(a.claimedAt ?? a.expiresAt).getTime()
    );

    const removedUnclaimed = sortedByDate?.filter((rakeback) => !!rakeback.claimedAt);
    const groupedByDate = groupBy(removedUnclaimed, (rakeback) =>
      new Date(format(rakeback.claimedAt!, "yyyy-MM-dd HH:mm")).toISOString()
    );

    return groupedByDate;
  }, [userRakebacks?.pages]);

  return (
    <TableContainer backgroundColor="ui-01" border="1px solid" borderColor="border-01" borderRadius="container">
      <Table>
        <Thead>
          <Tr>
            <ThText>{t("Date")}</ThText>
            <ThText textAlign="right">{t("{{currency}} Claimed", { currency: "ETH" })}</ThText>
            <ThText textAlign="right">{t("{{currency}} Claimed", { currency: "YOLO" })}</ThText>
            {/* @TODO-rakeback add back when USD conversion is ready */}
            {/* <ThText>{t("USD Value")}</ThText> */}
            {/* @TODO-rakeback add back when TX hash is available */}
            {/* <ThText>{t("Tx")}</ThText> */}
          </Tr>
        </Thead>

        <Tbody>
          {Object.entries(groupedHistoryRakebacks).map(([claimedAt, rakebacks]) => {
            const ethAmountWei = rakebacks
              .filter((rakeback) => rakeback.currency.symbol === "ETH")
              .reduce((total, current) => total + BigInt(current.amountWei), 0n);

            const yoloAmountWei = rakebacks
              .filter((rakeback) => rakeback.currency.symbol === "YOLO")
              .reduce((total, current) => total + BigInt(current.amountWei), 0n);

            // @TODO-rakeback add currency conversion and transaction links when available
            // const transactionHashes: string[] = [];
            // const dollarAmount = 10;

            return (
              <LazyLoad
                as={Tr}
                key={claimedAt}
                transition="background-color 100ms"
                _hover={{
                  bg: "border-01",
                }}
                _notLast={{ borderBottom: "1px solid", borderBottomColor: "border-01" }}
              >
                <Td>
                  <Stack spacing={0} alignItems="start">
                    <Text textStyle="helper" color="text-03">
                      {format(claimedAt, "HH:mm")}
                    </Text>
                    <Text textStyle="helper" color="text-03">
                      {format(claimedAt, "dd/MM/yyyy")}
                    </Text>
                  </Stack>
                </Td>
                <Td>
                  <HStack justifyContent="end">
                    <Text textStyle="detail" color="text-03">
                      {formatToDisplayDecimals({
                        amountWei: ethAmountWei,
                        currency: { symbol: "ETH" },
                        size: "large",
                        zeroCharacter: "—",
                      })}
                    </Text>

                    <EthTokenIcon boxSize={5} />
                  </HStack>
                </Td>
                <Td>
                  <HStack justifyContent="end">
                    <Text textStyle="detail" color="text-03">
                      {formatToDisplayDecimals({
                        amountWei: yoloAmountWei,
                        currency: { symbol: "YOLO" },
                        size: "large",
                        zeroCharacter: "—",
                      })}
                    </Text>

                    <TokenYoloIcon boxSize={5} />
                  </HStack>
                </Td>
                {/* @TODO-rakeback add back when USD conversion is ready */}
                {/* <Td>
                  <Text textStyle="detail" color="text-03">
                    {formatUsd(dollarAmount)}
                  </Text>
                </Td> */}
                {/* @TODO-rakeback add back when TX hash is available */}
                {/* <Td>
                  <HStack>
                    {transactionHashes.map((hash) => (
                      <IconLinkButton
                        key={hash}
                        href={getExplorerLink(
                          hash,
                          "transaction",
                          IS_TESTNET ? ChainId.BLAST_SEPOLIA : ChainId.BLAST_MAINNET
                        )}
                        icon={<LaunchOpenInNewIcon boxSize={4} color="link-02" />}
                        aria-label={t("View on explorer")}
                        size="xs"
                        isExternal
                      />
                    ))}
                  </HStack>
                </Td> */}
              </LazyLoad>
            );
          })}
        </Tbody>
      </Table>
      {Object.keys(groupedHistoryRakebacks).length === 0 && (
        <Text textStyle="detail" textAlign="center" color="text-03" p={4}>
          {t("Nothing claimed yet")}
        </Text>
      )}
      {hasNextPage && (
        <VStack px={4} py={3} borderTop="1px solid" borderTopColor="border-01">
          <Button onClick={() => fetchNextPage()} variant="outline" colorScheme="secondary">
            {t("Load More")}
          </Button>
        </VStack>
      )}
    </TableContainer>
  );
};
