import { useMemo } from "react";
import { HStack, Stack } from "@chakra-ui/react";
import { addSeconds } from "date-fns/addSeconds";
import { format } from "date-fns/format";
import { intervalToDuration } from "date-fns/intervalToDuration";
import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";
import { useTranslation } from "next-i18next";
import { CheckmarkFilledIcon, ClockIcon, RadioButtonIcon, Text, currencyTokenIconMap } from "@looksrare/uikit";
import {
  formatDuration,
  formatToDisplayDecimals,
  formatToSignificant,
  getCurrencyDisplayDecimals,
} from "@looksrare/utils";
import { GroupedRakebacks, Rakeback } from "@looksrare/yolo-games-gql-typegen";

interface PendingRakebacksProps {
  rakebacks?: GroupedRakebacks;
}

export const PendingRakebacks = ({ rakebacks }: PendingRakebacksProps) => {
  const { t } = useTranslation();

  const now = new Date();

  const unlockedRakebacks = useMemo(() => {
    if (!rakebacks?.unlocked) {
      return [];
    }

    return sortBy(rakebacks.unlocked, (rakeback) => new Date(rakeback.expiresAt).getTime());
  }, [rakebacks?.unlocked]);

  /**
   * Group locked rakebacks by unlock time
   * user can't claim these yet, limit to 8 to not waste space
   */
  const lockedRakebacksDateMap: Record<string, Rakeback[]> = useMemo(() => {
    if (!rakebacks?.locked) {
      return {};
    }

    const sortedByUnlockTime = sortBy(rakebacks.locked, (rakeback) => new Date(rakeback.unlocksAt).getTime());
    const limited = sortedByUnlockTime.slice(0, 8);
    const groupedByUnlockTime = groupBy(limited, (rakeback) =>
      new Date(format(rakeback.unlocksAt, "yyyy-MM-dd HH:mm")).toISOString()
    );

    return groupedByUnlockTime;
  }, [rakebacks?.locked]);

  /**
   * Group history rakebacks by claimed date
   * user has already claimed these, limit to 8 to not waste space
   */
  const groupedHistoryRakebacks = useMemo(() => {
    if (!rakebacks?.history) {
      return [];
    }

    const removedUnclaimed = rakebacks.history.filter((rakeback) => !!rakeback.claimedAt);
    const sortedByDate = removedUnclaimed.sort(
      (a, b) => new Date(b.claimedAt!).getTime() - new Date(a.claimedAt!).getTime()
    );
    const limited = sortedByDate.slice(0, 8);

    const groupedByDate = groupBy(limited, (rakeback) =>
      new Date(format(rakeback.claimedAt!, "yyyy-MM-dd HH:mm")).toISOString()
    );

    return groupedByDate;
  }, [rakebacks?.history]);

  return (
    <Stack spacing={4} maxHeight={{ base: "400px", sm: "600px" }} overflowY="auto">
      {unlockedRakebacks.length > 0 && (
        <Stack spacing={1}>
          <Text textStyle="detail" bold>
            {t("Claimable")}
          </Text>

          {unlockedRakebacks.map((rakeback) => {
            const expiresIn = intervalToDuration({ start: now, end: new Date(rakeback.expiresAt) });
            const TokenIcon = currencyTokenIconMap[rakeback.currency.symbol];

            return (
              <HStack key={rakeback.expiresAt} justifyContent="space-between" py={2} pl={4}>
                <HStack spacing={2}>
                  <RadioButtonIcon boxSize={5} color="interactive-03" />

                  <Text textStyle="detail" color="link-01">
                    {t("Expiring in {{time}}", {
                      time: formatDuration(expiresIn, {
                        shortLabels: true,
                        format: ["months", "days", "hours", "minutes"],
                      }),
                    })}
                  </Text>
                </HStack>

                <HStack spacing={2}>
                  <Text textStyle="detail" color="link-01" bold>
                    {formatToDisplayDecimals({
                      amountWei: rakeback.amountWei,
                      currency: rakeback.currency,
                      size: "large",
                    })}
                  </Text>

                  <TokenIcon boxSize={5} />
                </HStack>
              </HStack>
            );
          })}
        </Stack>
      )}

      {Object.keys(lockedRakebacksDateMap).length > 0 && (
        <Stack spacing={1}>
          <Text textStyle="detail" bold>
            {t("Unlocking Soon")}
          </Text>

          {Object.entries(lockedRakebacksDateMap).map(([unlocksAt, lockedRakebacks]) => {
            const rakebackUnlocksAt = new Date(unlocksAt);
            const unlocksIn = intervalToDuration({ start: now, end: rakebackUnlocksAt });
            const isUnlockingSoon = rakebackUnlocksAt < addSeconds(now, 60);

            return (
              <Stack
                key={unlocksAt}
                direction="row"
                alignItems="start"
                justifyContent="space-between"
                py={2}
                pl={4}
                borderTop="1px solid"
                borderTopColor="border-01"
              >
                <HStack spacing={2}>
                  <ClockIcon boxSize={5} color="text-placeholder" />

                  <Text textStyle="detail" color="text-03">
                    {isUnlockingSoon
                      ? t("Soon")
                      : formatDuration(unlocksIn, {
                          shortLabels: true,
                          format: ["months", "days", "hours", "minutes"],
                        })}
                  </Text>
                </HStack>

                <Stack spacing={2} alignItems="end">
                  {lockedRakebacks.map((rakeback) => {
                    const hasValue = !!rakeback.amountWei;
                    const TokenIcon = currencyTokenIconMap[rakeback.currency.symbol];

                    return (
                      <HStack key={`${unlocksAt}-${rakeback.currency}`} spacing={2}>
                        <Text textStyle="detail" color={hasValue ? "text-03" : "text-disabled"} bold>
                          {formatToDisplayDecimals({
                            amountWei: rakeback.amountWei,
                            currency: rakeback.currency,
                            size: "large",
                            zeroCharacter: "—",
                          })}
                        </Text>

                        {hasValue && <TokenIcon boxSize={5} />}
                      </HStack>
                    );
                  })}
                </Stack>
              </Stack>
            );
          })}
        </Stack>
      )}

      {Object.keys(groupedHistoryRakebacks).length > 0 && (
        <Stack spacing={1}>
          <Text textStyle="detail" bold>
            {t("Recently Claimed")}
          </Text>

          {Object.entries(groupedHistoryRakebacks).map(([claimDate, historyRakebacks]) => {
            return (
              <Stack
                key={claimDate}
                direction="row"
                alignItems="start"
                justifyContent="space-between"
                py={2}
                pl={4}
                borderTop="1px solid"
                borderTopColor="border-01"
              >
                <HStack spacing={2}>
                  <CheckmarkFilledIcon boxSize={5} color="link-01" />

                  <Text textStyle="detail" color="text-03">
                    {t("Claimed at {{time}}", { time: format(claimDate, "h:mm aa") })}
                  </Text>
                </HStack>

                <Stack spacing={2} alignItems="end">
                  {historyRakebacks.map((rakeback) => {
                    const TokenIcon = currencyTokenIconMap[rakeback.currency.symbol];

                    return (
                      <HStack key={rakeback.currency.address} spacing={2}>
                        <Text textStyle="detail" color="text-03" bold>
                          {formatToDisplayDecimals({
                            amountWei: rakeback.amountWei,
                            currency: rakeback.currency,
                            size: "large",
                          })}
                        </Text>

                        <TokenIcon boxSize={5} />
                      </HStack>
                    );
                  })}
                </Stack>
              </Stack>
            );
          })}
        </Stack>
      )}
    </Stack>
  );
};
