import { Flex } from "@chakra-ui/react";
import { Text } from "@looksrare/uikit";
import { formatDistanceToNowStrict } from "date-fns";
import { Trans } from "next-i18next";
import { FC } from "react";

export const DailyQuestsCountdown: FC<{ nextDay?: string }> = ({ nextDay }) => {
  const countdown = nextDay ? formatDistanceToNowStrict(new Date(nextDay)) : "-";

  return (
    <Trans i18nkey="transDailyQuestsCountdown">
      <Flex gap={2}>
        <Text color="text-03" textStyle="detail" bold>
          Daily Quests
        </Text>
        {!!nextDay && (
          <>
            <Text color="text-03" textStyle="detail">
              Resetting in
            </Text>
            <Text textStyle="detail" bold>
              <>{{ countdown }}</>
            </Text>
          </>
        )}
      </Flex>
    </Trans>
  );
};
