import { useAccount } from "wagmi";
import { useTranslation } from "next-i18next";
import { Box, Table, TableContainer, Tbody, Th, Thead, Tr, Td, HStack } from "@chakra-ui/react";
import {
  Button,
  ExternalLink,
  InformationFilledIcon,
  Popover,
  Text,
  type TextProps,
  TooltipText,
  EmptyStateIcon,
  currencyTokenIconMap,
} from "@looksrare/uikit";
import { formatToSignificant, formatUsd, getExplorerLink, timeAgo } from "@looksrare/utils";
import { lpConfig } from "@/config";
import { getDefaultChain } from "@/utils";
import { useUserLiquidityPoolEvents } from "../hooks";

const CellText = (props: TextProps) => <Text color="text-02" textStyle="detail" {...props} />;

export const LiquidityHistory = () => {
  const { address } = useAccount();
  const { t } = useTranslation();
  const liquidityPoolsEvents = useUserLiquidityPoolEvents(address);
  const hasNoData = liquidityPoolsEvents.isSuccess && liquidityPoolsEvents.data.pages.length === 0;

  return (
    <Box>
      <Text textStyle="heading-03" bold mb={4}>
        {t("liq::Your Liquidity History")}
      </Text>
      <TableContainer border="solid 1px" borderColor="border-01" bgGradient="none" borderRadius="dialog">
        <Table>
          <Thead>
            <Tr>
              <Th>{t("liq::Pool")}</Th>
              <Th>{t("liq::Action")}</Th>
              <Th textAlign="right">{t("liq::Quantity")}</Th>
              <Th textAlign="right">{t("liq::USD Value")}</Th>
              <Th>{t("liq::Date")}</Th>
              <Th />
            </Tr>
          </Thead>
          <Tbody>
            {(() => {
              if (hasNoData) {
                return (
                  <Tr>
                    <Td colSpan={6} borderBottom={0}>
                      <Box py={8} textAlign="center">
                        <EmptyStateIcon boxSize={16} color="text-disabled" />
                        <Text color="text-disabled" bold>
                          {t("No Data")}
                        </Text>
                      </Box>
                    </Td>
                  </Tr>
                );
              }
              return liquidityPoolsEvents.data?.pages.map((page) => {
                return page.map(
                  ({
                    contract,
                    currency,
                    transactionHash,
                    type,
                    isTransfer,
                    assetsAmountVum,
                    assetsAmountUsd,
                    createdAt,
                  }) => {
                    const Icon = currencyTokenIconMap[currency.symbol];
                    const usdValueDisplay = (() => {
                      if (!assetsAmountUsd) {
                        return "-";
                      }
                      return assetsAmountUsd < 0.01 ? "$<0.01" : formatUsd(assetsAmountUsd, 2, 2);
                    })();

                    return (
                      <Tr key={transactionHash} borderBottom="1px solid" borderBottomColor="border-01">
                        <Td>
                          <Icon boxSize={5} />
                        </Td>
                        <Td>
                          <HStack>
                            <CellText>{type === "DEPOSIT" ? t("liq::Deposit") : t("liq::Withdraw")}</CellText>
                            {isTransfer && (
                              <Popover
                                renderInPortal
                                label={
                                  <>
                                    <TooltipText>
                                      {t(
                                        "liq::This transaction was initiated by a different wallet. Check the chain explorer for more details."
                                      )}
                                    </TooltipText>
                                  </>
                                }
                              >
                                <span>
                                  <InformationFilledIcon boxSize={4} color="support-info" />
                                </span>
                              </Popover>
                            )}
                          </HStack>
                        </Td>
                        <Td>
                          <HStack spacing={1} justifyContent="end">
                            <CellText>
                              {formatToSignificant(assetsAmountVum, lpConfig[contract].displayDecimals, {
                                decimals: currency.decimals,
                              })}
                            </CellText>
                            <Icon boxSize={4} />
                          </HStack>
                        </Td>
                        <Td>
                          <CellText textAlign="right">{usdValueDisplay}</CellText>
                        </Td>
                        <Td>
                          <CellText>{timeAgo(new Date(createdAt), { shortLabels: true })}</CellText>
                        </Td>
                        <Td textAlign="right">
                          <ExternalLink href={getExplorerLink(transactionHash, "transaction", getDefaultChain())} />
                        </Td>
                      </Tr>
                    );
                  }
                );
              });
            })()}
          </Tbody>
          {!hasNoData && (
            <Tr>
              <Td colSpan={6} textAlign="center" borderBottom={0}>
                <Button
                  size="xs"
                  onClick={() => liquidityPoolsEvents.fetchNextPage()}
                  isLoading={liquidityPoolsEvents.isFetching}
                  isDisabled={!liquidityPoolsEvents.hasNextPage}
                >
                  {t("Load More")}
                </Button>
              </Td>
            </Tr>
          )}
        </Table>
      </TableContainer>
    </Box>
  );
};
