import { Flex, Stack } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import { Container, Link, Text } from "@looksrare/uikit";
import { ReadyToClaimCard } from "./components/ReadyToClaimCard";
import { UnlockingSoonCard } from "./components/UnlockingSoonCard";
import { RakebackStats } from "./components/RakebackStats";
import { RakebackHistory } from "./components/RakebackHistory";

export const RakebackView = () => {
  const { t } = useTranslation();

  return (
    <Flex position="relative">
      <Flex position="absolute" width="100%" height="665px" zIndex={0}>
        <Image src="/images/rakeback/rakeback_bg.svg" layout="fill" alt="" objectFit="cover" />
      </Flex>

      <Container position="relative" py={16} maxWidth="1200px" width="100%">
        <Stack spacing={16}>
          <Stack spacing={4} maxWidth="768px">
            <Text textStyle="display-01">{t("Rakeback")}</Text>

            <Text textStyle="body" color="text-03">
              {t(
                "A portion of the fees you pay in games are broken down into four parts and released back to you as rakebacks over the next 48 hours."
              )}
              <Text
                as={Link}
                href="https://docs.yologames.io/rewards/rakebacks-and-rewards#-user-rakebacks-30"
                ml={1}
                textStyle="detail"
                color="link-01"
              >
                {t("Learn More")}
              </Text>
            </Text>
          </Stack>

          <Stack spacing={12}>
            <Stack spacing={4} direction={{ base: "column", md: "row" }}>
              <ReadyToClaimCard flex={1} />

              <UnlockingSoonCard flex={1} />
            </Stack>

            <Stack spacing={4}>
              <RakebackStats />

              <RakebackHistory />
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </Flex>
  );
};
