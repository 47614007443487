import { useTranslation } from "next-i18next";
import { Box, Grid, GridItem, HStack, Tab, TabList, Tabs } from "@chakra-ui/react";
import { useAccount } from "wagmi";
import { Container, InformationCircleIcon, Link, NextLink, Popover, Text, TokenDropletsIcon } from "@looksrare/uikit";
import { useLiquidityPoolTotalDroplets } from "../hooks";
import { TokenDropletsCounter } from "./TokenDropletsCounter";
import { DropletTooltipText } from "./TooltipText";

export enum LiquidityHeroTab {
  YOUR_POSITION,
  GLOBAL,
}

interface LiquidityHeroProps {
  tab?: LiquidityHeroTab;
}

export const Hero = ({ tab }: LiquidityHeroProps) => {
  const { t } = useTranslation();
  const { address } = useAccount();
  const lpDropletQuery = useLiquidityPoolTotalDroplets(address);
  const dropletsAmount = lpDropletQuery.isSuccess ? Number(lpDropletQuery.data) : 0;

  return (
    <Box bgGradient="linear(to-b, ui-liquidity-a, ui-liquidity-b)">
      <Box
        backgroundImage="url('/images/textures/topography-1.svg')"
        backgroundPosition="right -418px"
        backgroundRepeat="no-repeat"
        backgroundSize="837px 896px"
      >
        <Container maxWidth="1200px" pt={24} pb={0}>
          <Grid
            gridTemplateColumns={{ base: "minmax(0, 1fr)", md: "repeat(2, minmax(0, 1fr))" }}
            gridGap={{ base: 8, md: 16 }}
            mb={8}
          >
            <GridItem>
              <Text as="h1" textStyle="display-03" bold mb={4}>
                {t("liq::Earn Liquidity Rewards")}
              </Text>
              <Text color="text-03">
                {t(
                  "liq::Provide liquidity to earn games fees and Droplets. Droplets earn you reward at the end of the season!"
                )}{" "}
                <Link
                  as={NextLink}
                  href="https://docs.yologames.io/games/liquidity-and-probabilities/liquidity-pools"
                  color="link-01"
                  fontWeight="bold"
                  isExternal
                >
                  {t("Learn More")}
                </Link>
              </Text>
            </GridItem>
            <GridItem>
              <Box
                border="1px solid"
                borderRadius="dialog"
                borderColor="acid.200"
                bgGradient="linear(to-b, ui-gradient-02-a, ui-gradient-02-b)"
                p={6}
              >
                <Popover variant="tooltip" label={<DropletTooltipText />}>
                  <HStack mb={2}>
                    <Text color="text-03">{t("liq::Your Liquidity Droplets")}</Text>
                    <InformationCircleIcon boxSize={5} color="text-03" />
                  </HStack>
                </Popover>
                <HStack>
                  <TokenDropletsIcon boxSize={8} />
                  <TokenDropletsCounter shardAmount={dropletsAmount} />
                </HStack>
              </Box>
            </GridItem>
          </Grid>
          <Tabs variant="blank" index={tab}>
            <TabList>
              <Tab as={NextLink} href="/liquidity">
                {t("liq::Your Positions")}
              </Tab>
              <Tab as={NextLink} href="/liquidity/global">
                {t("liq::Global Data")}
              </Tab>
            </TabList>
          </Tabs>
        </Container>
      </Box>
    </Box>
  );
};
