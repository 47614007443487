import { Box, Flex, FlexProps, VStack } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import {
  IconProps,
  NextLink,
  NotificationFilledIcon,
  OfferFillIcon,
  Text,
  UserAvatarFilledIcon,
} from "@looksrare/uikit";
import { FC, PropsWithChildren } from "react";
import { useRouter } from "next/router";

export interface SidebarLinkProps extends FlexProps {
  icon: (iconProps: IconProps) => JSX.Element;
  isActive?: boolean;
  href: string;
}

export const SidebarLink: FC<PropsWithChildren<SidebarLinkProps>> = ({
  icon: Icon,
  isActive = false,
  children,
  href,
  ...props
}) => {
  const activeProps: FlexProps = isActive
    ? {
        bg: "ui-01",
        color: "link-01",
        fontWeight: 700,
      }
    : { color: "text-02", sx: { _hover: { bg: "hover-ui" } } };
  return (
    <Flex
      as={NextLink}
      alignItems="center"
      px={2}
      py={4}
      borderRadius="mini"
      mb={2}
      href={href}
      {...activeProps}
      {...props}
    >
      <Icon color={isActive ? "text-01" : "text-03"} boxSize={6} mr={3} />
      {children}
    </Flex>
  );
};

export const SideMenu = () => {
  const { t } = useTranslation();
  const { asPath } = useRouter();

  return (
    <VStack align="stretch" spacing={10} py={{ base: 0, md: 8 }} px={8}>
      <Box>
        <Text textStyle="helper" color="text-03" bold mb={2}>
          {t("Settings")}
        </Text>
        <SidebarLink
          href={`/settings/profile`}
          icon={UserAvatarFilledIcon}
          textStyle="detail"
          isActive={asPath === `/settings/profile`}
        >
          {t("Profile")}
        </SidebarLink>
        <SidebarLink
          href={`/settings/notifications`}
          icon={NotificationFilledIcon}
          textStyle="detail"
          isActive={asPath === `/settings/notifications`}
        >
          {t("Notifications")}
        </SidebarLink>
        <SidebarLink
          href={`/settings/take-a-break`}
          icon={OfferFillIcon}
          textStyle="detail"
          isActive={asPath === `/settings/take-a-break`}
        >
          {t("Take a Break")}
        </SidebarLink>
      </Box>
    </VStack>
  );
};
