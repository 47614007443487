import { useRouter } from "next/router";
import DiscordOAuth2 from "discord-oauth2";
import { Address } from "viem";
import qs from "qs";
import { useAccount, useWalletClient } from "wagmi";
import { useCallback, useEffect, useRef } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getAuthCookie, getStorageKey, graphql, isAuthorized, useSignAndLoginIfJwtIsInvalid } from "@looksrare/utils";
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from "@looksrare/utils/localStorage";
import { gql } from "graphql-request";
import { SuccessPayload } from "@/types";
import { useInvalidateUser, userKeys } from "./user";

export type SupportedSocialPlatforms = "TWITTER" | "DISCORD";

export type OAuthAuthorizationInput = {
  code: string;
  codeVerifier?: string;
  redirectUri: string;
};

export type BaseSocialInput = {
  account: Address;
  platform: SupportedSocialPlatforms;
};

export interface DisconnectCollectionSocialInput extends BaseSocialInput {
  collectionAddress: Address;
}

export interface ConnectSocialInput extends BaseSocialInput {
  data: OAuthAuthorizationInput;
}

export interface DisconnectCollectionSocialInput extends BaseSocialInput {
  collectionAddress: Address;
}

export interface ConnectSocialInput extends BaseSocialInput {
  data: OAuthAuthorizationInput;
}

export interface ConnectCollectionSocialInput extends ConnectSocialInput {
  collectionAddress: Address;
}

export type StoredOAuthInfo = {
  nonce: string;
  redirectUri: string;
  codeVerifier?: string;
  socialPlatform: SupportedSocialPlatforms;
  redirectOnSuccess: string;
};

type OAuthAuthorizationRequestParams = {
  response_type: "code";
  client_id: string;
  redirect_uri: string;
  state: string;
  code_challenge: string;
  code_challenge_method: "S256" | "plain";
  scope?: string;
};

export const LOCAL_STORAGE_OAUTH_INFO = getStorageKey("oauth2_info");

const cryptographicallyRandomString = (crypto: Crypto, length = 128) => {
  // A code verifier is a  cryptographically random string using the characters A-Z, a-z, 0-9, and the punctuation
  // characters -._~ (hyphen, period, underscore, and tilde), between 43 and 128 characters long.
  return Array(length)
    .fill("0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz-._~")
    .map((x) => {
      const cryptoRandom = crypto.getRandomValues(new Uint32Array(1))[0];
      return x[Math.floor(cryptoRandom % x.length)];
    })
    .join("");
};

const generateCodeChallenge = async (crypto: Crypto, codeVerifier: string) => {
  // A code challenge is genereated by taking the SHA256 hash of the code verifier and then base64url encoding it.
  const encoder = new TextEncoder();
  const encoded = encoder.encode(codeVerifier);
  const buffer = await crypto.subtle.digest("SHA-256", encoded);
  //ArrayBuffer to base64 https://gist.github.com/jonleighton/958841#gistcomment-2915919
  return (
    btoa(
      new Uint8Array(buffer).reduce((data, byte) => {
        return data + String.fromCharCode(byte);
      }, "")
    )
      /* replace special characters to make it url-friendly. encodeUriComponent() would mess it up with percent encoding
       * and we'd be returning a different code challenge than the one we sent to the OAuth provider.
       * known issue, addressed natively in other languages e.g https://docs.python.org/3/library/base64.html#base64.urlsafe_b64encode
       */
      .replace(/=/g, "")
      .replace(/\+/g, "-")
      .replace(/\//g, "_")
  );
};

export const prepareOAuth = async (
  clientId: string,
  authProviderEndpoint: string,
  redirectUri: string,
  scope: string
) => {
  if (window === undefined) {
    throw new Error("Web Crypto API is required for this operation.");
  }

  // Given that we're using the S256 method, we need to generate a code challenge from the code verifier.
  const codeVerifier = cryptographicallyRandomString(window.crypto, 128);
  const codeChallenge = await generateCodeChallenge(window.crypto, codeVerifier);
  const csrfToken = cryptographicallyRandomString(window.crypto, 128);
  const authRequestParams: OAuthAuthorizationRequestParams = {
    client_id: clientId,
    code_challenge: codeChallenge,
    code_challenge_method: "S256",
    redirect_uri: redirectUri,
    response_type: "code",
    state: csrfToken,
    scope,
  };
  const link = `${authProviderEndpoint}?${qs.stringify(authRequestParams, { encode: false })}`;

  return {
    authLink: link,
    codeVerifier,
    codeChallenge,
    csrfToken,
  };
};

export const connectUserSocial = async ({ account, platform, data }: ConnectSocialInput): Promise<SuccessPayload> => {
  if (platform === "TWITTER" && !data.codeVerifier) {
    throw new Error("Missing code verifier, mandatory for Twitter OAuth.");
  }
  const query = gql`
    mutation ConnectUserSocial($platform: SOCIAL_PLATFORM!, $data: ConnectSocialInput!) {
      connectUserSocial(platform: $platform, data: $data) {
        success
      }
    }
  `;
  const authCookie = getAuthCookie(account);
  const requestHeaders = {
    Authorization: `Bearer ${authCookie}`,
  };
  const res: { connectUserSocial: SuccessPayload } = await graphql({
    query,
    params: { platform, data },
    requestHeaders,
  });
  return res.connectUserSocial;
};

export const disconnectUserSocial = async ({ account, platform }: BaseSocialInput): Promise<SuccessPayload> => {
  const query = gql`
    mutation DisconnectUserSocial($platform: SOCIAL_PLATFORM!) {
      disconnectUserSocial(platform: $platform) {
        success
      }
    }
  `;
  const authCookie = getAuthCookie(account);
  const requestHeaders = {
    Authorization: `Bearer ${authCookie}`,
  };
  const res: { disconnectUserSocial: SuccessPayload } = await graphql({ query, params: { platform }, requestHeaders });
  return res.disconnectUserSocial;
};

export class OAuthURLParamError extends Error {
  /* @See https://www.oauth.com/oauth2-servers/server-side-apps/possible-errors/
   * The only error that should happen in user-land is "access_denied" */
  constructor(message: string) {
    super(message);
    this.name = "TwitterError";
  }
}

type OAuthStartFlowParams = {
  socialPlatform: SupportedSocialPlatforms;
  redirectOnSuccess?: string;
  onDisconnectSuccess?: () => void;
  onConnectSuccess?: () => void;
  onDisconnectError?: (error: Error) => void;
  callbackUrlPath?: "/settings/profile" | "/airdrop/early-access";
};

const generateRandomHex = async () => {
  if (typeof window === "undefined" || !window.crypto || !window.crypto.getRandomValues) {
    throw new Error("Web Crypto API not supported.");
  }

  const buffer = new Uint8Array(16);
  window.crypto.getRandomValues(buffer);

  return Array.from(buffer, (byte) => byte.toString(16).padStart(2, "0")).join("");
};

export const useGetStartOAuthFlowForUser = ({
  onDisconnectSuccess,
  onDisconnectError,
  onConnectSuccess,
  socialPlatform,
  redirectOnSuccess,
  callbackUrlPath = "/settings/profile",
}: OAuthStartFlowParams) => {
  const { address } = useAccount();
  const { data: signer } = useWalletClient();
  const accountIsAuthorized = isAuthorized(address);
  const invalidateUserQuery = useInvalidateUser();
  const signAndLoginIfJwtIsInvalid = useSignAndLoginIfJwtIsInvalid();

  const enforceAuth = async () => {
    /* Successive graphql calls require a valid JWT. After the redirect, the code url param is valid for 30 seconds,
     therefore, any time-consuming operation like requesting a wallet signature is better done beforehand. */
    if (!accountIsAuthorized) {
      await signAndLoginIfJwtIsInvalid(signer!, address!);
    }
  };

  const connectWithTwitter = useCallback(async () => {
    const twitterClientId = process.env.NEXT_PUBLIC_TWITTER_CLIENT_ID;

    if (!twitterClientId) {
      throw new Error("Please make sure NEXT_PUBLIC_TWITTER_CLIENT_ID is set in .env");
    }

    if (typeof window === "undefined") {
      throw new Error("OAuth2 is only supported in a browser environment.");
    }

    const redirectUri = encodeURIComponent(new URL(callbackUrlPath, window.location.href).toString());
    const scope = encodeURIComponent(["users.read", "tweet.read", "follows.write", "offline.access"].join(" "));

    const { authLink, codeVerifier, csrfToken } = await prepareOAuth(
      twitterClientId,
      "https://twitter.com/i/oauth2/authorize",
      redirectUri,
      scope
    );

    const toStoreLocally: StoredOAuthInfo = {
      nonce: csrfToken,
      codeVerifier,
      socialPlatform,
      redirectUri: redirectUri, // Must match exactly what's configured per app.
      /* @NOTE redirectOnSuccess is unrelated to the OAuth2 redirectUri. It's a clientside redirect to have a single OAuth
      redirect page that can reroute users to dynamic urls e.g. the collection page. */
      redirectOnSuccess: redirectOnSuccess || window.location.href,
    };

    try {
      setLocalStorageItem(LOCAL_STORAGE_OAUTH_INFO, JSON.stringify(toStoreLocally));
      window.location.href = authLink;
    } catch (e) {
      console.error(e);
    }
  }, [socialPlatform, redirectOnSuccess, callbackUrlPath]);

  const connectWithDiscord = useCallback(async () => {
    const clientId = process.env.NEXT_PUBLIC_DISCORD_CLIENT_ID;

    if (!clientId) {
      throw new Error("Please make sure NEXT_PUBLIC_DISCORD_CLIENT_ID is set in .env");
    }

    const redirectUri = new URL(callbackUrlPath, window.location.href).toString();
    const state = await generateRandomHex();

    const authLink = new DiscordOAuth2().generateAuthUrl({
      clientId,
      scope: "identify",
      redirectUri,
      state,
    });

    const toStoreLocally: StoredOAuthInfo = {
      nonce: state,
      socialPlatform: "DISCORD",
      redirectUri: redirectUri,
      /* @NOTE redirectOnSuccess is unrelated to the OAuth2 redirectUri. It's a clientside redirect to have a single OAuth
      redirect page that can reroute users to dynamic urls e.g. the collection page. */
      redirectOnSuccess: redirectOnSuccess || window.location.href,
    };

    try {
      setLocalStorageItem(LOCAL_STORAGE_OAUTH_INFO, JSON.stringify(toStoreLocally));
      window.location.href = authLink;
    } catch (e) {
      console.error(e);
    }
  }, [redirectOnSuccess, callbackUrlPath]);

  const connectSocial = useCallback(async () => {
    if (socialPlatform === "DISCORD") {
      await connectWithDiscord();
      return onConnectSuccess?.();
    } else {
      await connectWithTwitter();
      return onConnectSuccess?.();
    }
  }, [socialPlatform, connectWithDiscord, connectWithTwitter, onConnectSuccess]);

  const { mutate: disconnectUserSocialMutation, isPending: isUserMutationLoading } = useMutation({
    mutationFn: disconnectUserSocial,
    onSuccess: () => {
      invalidateUserQuery(address!);

      onDisconnectSuccess?.();
    },
    onSettled: () => {
      removeLocalStorageItem(LOCAL_STORAGE_OAUTH_INFO);
    },
    onError: (error: Error) => {
      onDisconnectError?.(error);
    },
  });

  const disconnect = useCallback(
    async () =>
      disconnectUserSocialMutation({
        account: address!,
        platform: socialPlatform,
      }),
    [address, disconnectUserSocialMutation, socialPlatform]
  );
  return {
    connect: () => enforceAuth().then(connectSocial),
    disconnect: () => enforceAuth().then(disconnect),
    isLoading: isUserMutationLoading,
  };
};

type Params = {
  onSuccess?: () => void;
  onError?: (e: Error) => void;
  disabled?: boolean;
};

/***
 * Checks url parameters to determine if the current page is part of an OAuth2 flow and performs preset graphQL
 * mutations based on data in local storage.
 * If {collectionAddress} is present, the user is connecting a social, to one of his owned collections. If not,
 * the user is connecting a social to his account.
 *
 * Uses a ref to avoid triggering the effect if multiple renders occur with valid trigger parameters.
 */
export const useOAuthRedirectEvaluation = ({ onSuccess, onError, disabled }: Params) => {
  const router = useRouter();
  const { state: nonce, code, error } = router.query;

  const account = useAccount();
  const queryClient = useQueryClient();
  const isProcessingMutation = useRef(false);

  const storedInfo = (() => {
    try {
      return JSON.parse(getLocalStorageItem(LOCAL_STORAGE_OAUTH_INFO) || "{}") as unknown as StoredOAuthInfo;
    } catch (e) {
      console.error(e);
      return null;
    }
  })();

  const onSuccessHandler = async (queryKey: string[]) => {
    const redirectNext = storedInfo?.redirectOnSuccess || "/settings/profile";
    queryClient.invalidateQueries({ queryKey });
    onSuccess?.();

    try {
      // We're about to redirect the user to an address read from localStorage, so only allowing relative paths
      const url = new URL(redirectNext, window.location.href);
      await router.replace(url.pathname);
    } catch (e) {
      console.error(`failed redirecting to ${redirectNext} after OAuth flow`, e);
    }
  };

  const handleError = useCallback(
    (err: Error) => {
      onError?.(err);
    },
    [onError]
  );

  const handleSettled = () => {
    removeLocalStorageItem(LOCAL_STORAGE_OAUTH_INFO);
  };

  const { mutate: connectUserSocialMutation } = useMutation({
    mutationFn: connectUserSocial,
    onSuccess: () => onSuccessHandler(userKeys.user(account.address!)),
    onError: handleError,
    onSettled: handleSettled,
  });

  useEffect(() => {
    let err: Error | OAuthURLParamError | null = null;

    if (!disabled && nonce && account.address) {
      if (error) {
        console.error("OAuth error:", error);
        err = new OAuthURLParamError(error as string);
      }
      if (!!code && !!storedInfo?.nonce) {
        if (storedInfo.nonce !== nonce) {
          err = new Error("Nonce mismatch");
        } else {
          const sharedMutationData = {
            account: account.address,
            platform: storedInfo.socialPlatform,
            data: {
              code: code as string,
              codeVerifier: storedInfo.codeVerifier,
              redirectUri: decodeURIComponent(storedInfo.redirectUri),
            },
          };
          if (!isProcessingMutation.current) {
            isProcessingMutation.current = true;
            connectUserSocialMutation(sharedMutationData);
          }
        }
      }

      if (err) {
        handleError(err);
      }
    }
  }, [
    account,
    router,
    nonce,
    code,
    onSuccess,
    onError,
    disabled,
    error,
    connectUserSocialMutation,
    storedInfo,
    handleError,
  ]);
};
