import { useMemo } from "react";
import { zeroAddress, type Address } from "viem";
import { motion } from "framer-motion";
import { Trans } from "next-i18next";
import { HStack, type StackProps } from "@chakra-ui/react";
import { EthTokenIcon, Text, TextProps } from "@looksrare/uikit";
import { formatToSignificant, timeAgo, useGetCurrencyConfig, type BigIntish } from "@looksrare/utils";

interface WinnerMarqueeItemProps extends StackProps {
  poolName: string;
  amountWonWei: BigIntish;
  gameName: string;
  timestamp: string;
  currency?: Address;
}

const InnerText = (props: TextProps) => <Text as="span" color="text-03" whiteSpace="nowrap" {...props} />;

export const WinnerMarqueeItem = ({
  poolName,
  amountWonWei,
  gameName,
  timestamp,
  currency = zeroAddress,
  ...props
}: WinnerMarqueeItemProps) => {
  const getCurrencyConfig = useGetCurrencyConfig();
  const { icon: Icon = EthTokenIcon } = getCurrencyConfig(currency);
  const timestampDate = useMemo(() => new Date(timestamp), [timestamp]);
  const timestampDisplay = timeAgo(timestampDate, { shortLabels: true });
  const amount = formatToSignificant(amountWonWei, 4);
  return (
    <HStack spacing={1.5} flexShrink={0} px={4} borderRight="1px solid" borderRightColor="border-01" {...props}>
      <Trans i18nKey="marqueeText" ns="liq">
        <InnerText bold>
          <>{{ poolName }}</>
        </InnerText>
        <InnerText>won</InnerText>
        <HStack display="inline-flex" spacing={1}>
          <Icon boxSize={4} color="text-03" />
          <InnerText bold color="link-01">
            <>{{ amount }}</>
          </InnerText>
        </HStack>
        <InnerText>from</InnerText>
        <InnerText bold>
          <>{{ gameName }}</>
        </InnerText>
        <InnerText>
          <>{{ timestampDisplay }}</>
        </InnerText>
      </Trans>
    </HStack>
  );
};

const animations = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { type: "spring", stiffness: 200, damping: 50 },
};

export const AnimatedWinnerMarqueeItem = (props: WinnerMarqueeItemProps) => (
  <motion.div {...animations} layout>
    <WinnerMarqueeItem {...props} />
  </motion.div>
);
