import { Divider, Flex, Stack, StackProps } from "@chakra-ui/react";
import { IconProps, Text } from "@looksrare/uikit";

interface StatBoxProps extends StackProps {
  headerText: string;
  value: string;
  icon?: (props: IconProps) => JSX.Element;
}

export const StatBox = ({ headerText, value, icon: Icon, ...props }: StatBoxProps) => {
  return (
    <Stack spacing={2} p={4} bg="interactive-02" borderRadius="button" {...props}>
      <Text textStyle="detail" color="text-03">
        {headerText}
      </Text>
      <Stack spacing={4}>
        <Divider borderRadius="circular" borderColor="interactive-03" borderWidth="2px" width="142px" />
        <Flex gap={2} alignItems="center">
          {Icon && <Icon boxSize={{ base: 6, md: 8 }} color="text-01" />}
          <Text textStyle="display-03" bold>
            {value}
          </Text>
        </Flex>
      </Stack>
    </Stack>
  );
};
