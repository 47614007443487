import { Flex } from "@chakra-ui/react";
import { AnimatePresence } from "framer-motion";
import { AnimatedWinnerMarqueeItem } from "./WinnerMarqueeItem";

// @todo-liq
export const WinnerMarquee = () => {
  const items: any[] = []; //@TODO
  return (
    <Flex
      alignItems="center"
      border="1px solid"
      borderColor="border-01"
      borderRadius="dialog"
      overflowX="auto"
      height={9}
      mb={8}
      sx={{
        scrollbarWidth: "none",
        "::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      <AnimatePresence>
        {items.map((item) => (
          <AnimatedWinnerMarqueeItem
            key={item.timestamp}
            poolName={item.poolName}
            amountWonWei={item.amountWonWei}
            gameName={item.gameName}
            timestamp={item.timestamp}
          />
        ))}
      </AnimatePresence>
    </Flex>
  );
};
