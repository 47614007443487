import { ReactNode } from "react";
import { HStack, Stack, StackProps } from "@chakra-ui/react";
import { Text } from "@looksrare/uikit";
import { formatDuration, useCountdown } from "@looksrare/utils";
import { addSeconds } from "date-fns/addSeconds";
import { useTranslation } from "next-i18next";
import { gradients } from "@/theme/colors";
import { CurrencyRow, CurrencyRowProps } from "./CurrencyRow";
import { TimePill } from "./TimePill";

interface RakebackCardProps extends StackProps {
  title: string;
  timerExpiresAt: Date;
  currencyRows: CurrencyRowProps[];
  children: ReactNode;
  showTimer?: boolean;
  isHighlighted?: boolean;
}

export const RakebackCard = ({
  title,
  timerExpiresAt,
  currencyRows,
  children,
  showTimer,
  isHighlighted,
  ...props
}: RakebackCardProps) => {
  const { t } = useTranslation();

  const timerExpiresIn = useCountdown(timerExpiresAt.getTime(), showTimer);
  const isTimerEndingSoon = timerExpiresAt < addSeconds(Date.now(), 60);

  return (
    <Stack
      spacing={8}
      p={6}
      bgGradient={gradients["new-ui-gradient-v-01"]}
      borderRadius="dialog"
      borderWidth="1px"
      borderColor={isHighlighted ? "interactive-03" : "border-01"}
      transition="border-color 200ms"
      {...props}
    >
      <Stack spacing={6}>
        <HStack justifyContent="space-between">
          <Text textStyle="display-body" bold>
            {title}
          </Text>

          {showTimer && timerExpiresIn && (
            <TimePill
              label={
                isTimerEndingSoon
                  ? t("Soon")
                  : formatDuration(timerExpiresIn, {
                      format: ["days", "hours", "minutes"],
                      shortLabels: true,
                    })
              }
            />
          )}
        </HStack>

        <Stack spacing={4}>
          {currencyRows?.map((rakebackAmount) => (
            <CurrencyRow key={rakebackAmount.currency.address} {...rakebackAmount} />
          ))}
        </Stack>
      </Stack>

      {children}
    </Stack>
  );
};
