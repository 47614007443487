import { useCallback } from "react";
import type { Address } from "viem";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getUserLiquidityPendingActions } from "../queries";

export const useUserLiquidityPendingActions = (address?: Address) => {
  return useQuery({
    queryKey: ["user-liquidity-pending-actions", address],
    queryFn: () => getUserLiquidityPendingActions(address!),
    enabled: !!address,
    refetchInterval: 20_000,
  });
};

export const useInvalidateUserLiquidityPendingActions = () => {
  const queryClient = useQueryClient();
  return useCallback(
    (address?: Address) => {
      queryClient.invalidateQueries({ queryKey: ["user-liquidity-pending-actions", address] });
    },
    [queryClient]
  );
};
