import { Box } from "@chakra-ui/react";
import { type BigIntish } from "@looksrare/utils";
import type { Currency, LiquidityPoolContract } from "@looksrare/yolo-games-gql-typegen/src/generated/gql/graphql";
import { InitiateDepositStep } from "./InitiateDepositStep";
import { FinalizeDepositStep } from "./FinalizeDepositStep";
import { ApproveTokenStep } from "./ApproveTokenStep";

interface DepositConfirmationViewProps {
  isEthPool: boolean;
  contract: LiquidityPoolContract;
  finalizationIncentiveEthWei: BigIntish;
  currency: Currency;
  timelockDelaySecs: number;
  onFinalizeSuccess: () => void;
}

export const DepositConfirmationView = ({
  isEthPool,
  contract,
  finalizationIncentiveEthWei,
  currency,
  timelockDelaySecs,
  onFinalizeSuccess,
}: DepositConfirmationViewProps) => (
  <Box>
    <ApproveTokenStep isEthPool={isEthPool} currency={currency} />
    <InitiateDepositStep
      isEthPool={isEthPool}
      finalizationIncentiveEthWei={finalizationIncentiveEthWei}
      currency={currency}
      timelockDelaySecs={timelockDelaySecs}
    />
    <FinalizeDepositStep
      contract={contract}
      finalizationIncentiveEthWei={finalizationIncentiveEthWei}
      currency={currency}
      onFinalizeSuccess={onFinalizeSuccess}
    />
  </Box>
);
