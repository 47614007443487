import { Box, Grid } from "@chakra-ui/react";
import { Text } from "@looksrare/uikit";
import { useTranslation } from "next-i18next";
import { SignedUserGuard } from "@/components";
import { SideMenu } from "./components/SideMenu";
import { SettingsNotificationContent } from "./components/NotificationContent";

export const NotificationsView = () => {
  const { t } = useTranslation();
  return (
    <Grid gridTemplateColumns={{ base: "minmax(0, 1fr)", md: "320px minmax(0, 1fr)" }} gridGap={8} my={8}>
      <SideMenu />
      <Box maxW="512px" borderLeft="1px solid" borderLeftColor="border-01" p={8} alignItems="flex-start">
        <Text as="h1" bold textStyle="display-03" letterSpacing="-2%" mb={8}>
          {t(`Notification Settings`)}
        </Text>
        <SignedUserGuard>
          {(user, address) => <SettingsNotificationContent address={address} user={user} />}
        </SignedUserGuard>
      </Box>
    </Grid>
  );
};
