import type { Address } from "viem";
import { useReadContract } from "wagmi";
import { LiquidityPoolAbi } from "@looksrare/config";
import { useMaxRedeemableShares } from "./useMaxRedeemableShares";

/**
 * Returns an the maximum amount of assets (shares converted to asset) a user can withdraw from a pool
 */
export const useMaxRedeemableAssets = (liqudityPool: Address) => {
  const redeemableSharesQuery = useMaxRedeemableShares(liqudityPool);
  const shares = redeemableSharesQuery.isSuccess ? redeemableSharesQuery.data : 0n;
  return useReadContract({
    address: liqudityPool,
    abi: LiquidityPoolAbi,
    functionName: "convertToAssets",
    args: [shares],
    query: { enabled: redeemableSharesQuery.isSuccess },
  });
};
