import { useEffect, useState } from "react";
import { usePublicClient, useWalletClient } from "wagmi";
import type { Hash } from "viem";
import { useElapsedTime } from "use-elapsed-time";
import { useTranslation } from "next-i18next";
import { Box } from "@chakra-ui/react";
import { LiquidityPoolRouterAbi } from "@looksrare/config";
import { Button, Text, TransactionStep, TransactionStepRow } from "@looksrare/uikit";
import {
  NoPublicClientError,
  formatToSignificant,
  sleep,
  toDecimals,
  useHandleModalStep,
  type BigIntish,
} from "@looksrare/utils";
import type { Currency } from "@looksrare/yolo-games-gql-typegen/src/generated/gql/graphql";
import { getDefaultChain } from "@/utils";
import { gameAddresses } from "@/config";
import { useDepositWithdrawStore } from "../store";
import { DepositWithdrawStep } from "../types";
import { FeeText } from "../shared";

interface InitiateDepositStepProps {
  isEthPool: boolean;
  finalizationIncentiveEthWei: BigIntish;
  currency: Currency;
  timelockDelaySecs: number;
}

export const InitiateDepositStep = ({
  isEthPool,
  finalizationIncentiveEthWei,
  currency,
  timelockDelaySecs,
}: InitiateDepositStepProps) => {
  const [
    step,
    inputValue,
    startInitiateDeposit,
    startTimelock,
    startFinalization,
    setFailStatus,
    initiateDepositStatus,
    timelockStatus,
  ] = useDepositWithdrawStore((state) => [
    state.step,
    state.inputValue,
    state.startInitiateDeposit,
    state.startDepositTimelock,
    state.startDepositFinalization,
    state.setFailStatus,
    state.stepStatus[DepositWithdrawStep.INITIATE_DEPOSIT],
    state.stepStatus[DepositWithdrawStep.DEPOSIT_TIMELOCK],
  ]);
  const [isCountingDown, setIsCountingDown] = useState(false);
  const { elapsedTime } = useElapsedTime({
    duration: timelockDelaySecs,
    isPlaying: isCountingDown,
    updateInterval: 1,
    onComplete: () => {
      setIsCountingDown(false);
      startFinalization();
    },
  });
  const timeLockRemaining = timelockDelaySecs - elapsedTime;
  const { t } = useTranslation();

  const { data: walletClient } = useWalletClient();
  const publicClient = usePublicClient();
  const feeDisplay = `${formatToSignificant(finalizationIncentiveEthWei, 4)} ETH`;

  const initiateDeposit = useHandleModalStep({
    onSubmit: async () => {
      if (!walletClient) {
        throw Error("No wallet client found");
      }
      if (!publicClient) {
        throw NoPublicClientError;
      }
      startInitiateDeposit();

      const [account] = await walletClient.getAddresses();
      const inputVum = toDecimals(inputValue, currency.decimals);
      const incentive = BigInt(finalizationIncentiveEthWei);
      const chainId = getDefaultChain();
      const addresses = gameAddresses[chainId];

      let hash: Hash;

      if (isEthPool) {
        const { request } = await publicClient.simulateContract({
          address: addresses.LIQUIDITY_POOL_ROUTER,
          abi: LiquidityPoolRouterAbi,
          functionName: "depositETH",
          args: [inputVum],
          value: inputVum + incentive,
          account,
        });
        hash = await walletClient.writeContract(request);
      } else {
        const { request } = await publicClient.simulateContract({
          address: addresses.LIQUIDITY_POOL_ROUTER,
          abi: LiquidityPoolRouterAbi,
          functionName: "deposit",
          args: [currency.address, inputVum],
          value: incentive,
          account,
        });
        hash = await walletClient.writeContract(request);
      }
      await publicClient.waitForTransactionReceipt({ hash });

      // Wait a little bit more just in case
      await sleep(2_000);

      startTimelock();
      setIsCountingDown(true);
    },
    onError: () => {
      setFailStatus(DepositWithdrawStep.INITIATE_DEPOSIT);
    },
  });

  const handleRetryInitiateDeposit = () => {
    initiateDeposit.handleSubmit();
  };

  useEffect(() => {
    if (step === DepositWithdrawStep.INITIATE_DEPOSIT) {
      initiateDeposit.handleSubmit({ callOnlyOnce: true });
    }
  }, [step, initiateDeposit, inputValue]);

  return (
    <TransactionStep
      status={initiateDepositStatus.timingStatus}
      title={t("liq::Initiate Deposit")}
      width="100%"
      spacing={0}
      bodyWrapperProps={{ pl: 0 }}
      mb={4}
      collapse={initiateDepositStatus.timingStatus !== "current"}
    >
      <FeeText feeDisplay={feeDisplay} />
      <Box py={6} px={4} bg="ui-01" borderRadius="container">
        <Text textStyle="detail" mb={4}>
          {t("Waiting for you to confirm in wallet")}
        </Text>
        <TransactionStepRow
          status={initiateDepositStatus.status}
          transactionHash={initiateDepositStatus.hash}
          text={t("liq::Initiate Deposit")}
          mb={2}
        />
        {initiateDepositStatus.hasError && (
          <Button width="100%" size="sm" mt={2} mb={4} onClick={handleRetryInitiateDeposit}>
            {t("Retry")}
          </Button>
        )}
        <TransactionStepRow
          status={timelockStatus.status}
          text={t("liq::Time Lock ({{countdown}}s)", { countdown: timeLockRemaining })}
        />
      </Box>
    </TransactionStep>
  );
};
