import {
  Box,
  HStack,
  Stack,
  Table,
  TableCellProps,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import { Avatar, Button, ButtonToggle, FireIcon, Text, UsernameDisplay, currencyTokenIconMap } from "@looksrare/uikit";
import { formatToDisplayDecimals, tabularNums } from "@looksrare/utils";
import { RecentGameWinSort } from "@looksrare/yolo-games-gql-typegen";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import { useState } from "react";
import { GameType, useGetGameInfoByGameType, useRecentGameWins } from "@/hooks/recent-wins";

const ThText = ({ children, ...props }: TableCellProps) => {
  return (
    <Th borderBottomWidth="2px" borderBottomColor="ui-bg" {...props}>
      <Text textStyle="helper" color="text-03" bold>
        {children}
      </Text>
    </Th>
  );
};
const sortValues: RecentGameWinSort[] = ["RECENT_WINS_DESC", "BIGGEST_WINS_DESC"];

export const RecentWins = () => {
  const { t } = useTranslation();
  const [sort, setSort] = useState<RecentGameWinSort>("RECENT_WINS_DESC");

  const { data: recentWins } = useRecentGameWins(sort, { refetchInterval: 10_000 });
  const getGameInfoByGameType = useGetGameInfoByGameType();

  const sortTranslations = {
    RECENT_WINS_DESC: t("All Wins"),
    BIGGEST_WINS_DESC: t("Biggest Wins"),
  };

  return (
    <Stack spacing={4} px={{ base: 4, lg: 12 }}>
      <HStack justifyContent="space-between">
        <HStack spacing={2}>
          <FireIcon boxSize={5} />
          <Text textTransform="uppercase" bold>
            {t("Recent Wins")}
          </Text>
        </HStack>

        <ButtonToggle>
          {sortValues.map((sortValue) => {
            const isSelected = sort === sortValue;
            return (
              <Button
                key={sortValue}
                variant="outline"
                onClick={() => setSort(sortValue)}
                px={3}
                color={isSelected ? "link-01" : "link-02"}
                backgroundColor={isSelected ? "interactive-02" : "transparent"}
                borderColor="border-01"
                size="xs"
                data-id="recent-filter-mine"
              >
                {sortTranslations[sortValue]}
              </Button>
            );
          })}
        </ButtonToggle>
      </HStack>

      <Box
        position="relative"
        borderRadius="container"
        overflow="hidden"
        _after={
          recentWins && recentWins.length > 4
            ? {
                content: '""',
                position: "absolute",
                bottom: 0,
                height: "188px",
                width: "100%",
                bgGradient: "linear-gradient(180deg, rgba(27, 34, 48, 0.65) 0%, gray.900 75%);",
              }
            : {}
        }
      >
        <TableContainer backgroundColor="interactive-02" border="none">
          <Table>
            <Thead>
              <Tr>
                <ThText>{t("Game")}</ThText>
                <ThText>{t("Player")}</ThText>
                <ThText textAlign="right">{t("Entry Amount")}</ThText>
                <ThText textAlign="right">{t("Amount Won")}</ThText>
              </Tr>
            </Thead>

            <Tbody>
              {recentWins?.map(({ amountWonWei, entryAmountWei, currency, game, winner }, index) => {
                const TokenIcon = currencyTokenIconMap[currency.symbol];

                const { gameName, thumbnail } = getGameInfoByGameType(game as GameType);

                return (
                  <Tr key={index} _notLast={{ borderBottomWidth: "2px", borderBottomColor: "ui-bg" }}>
                    <Td>
                      <HStack spacing={4}>
                        <Box position="relative" boxSize="40px" borderRadius="mini" overflow="hidden">
                          <Image src={thumbnail} alt="" sizes="80px" layout="fill" objectFit="cover" />
                        </Box>

                        <Text textStyle="detail" color="text-03">
                          {gameName}
                        </Text>
                      </HStack>
                    </Td>
                    <Td>
                      <HStack>
                        <Avatar src={winner.avatar?.image?.src} address={winner.address} size={24} />

                        <UsernameDisplay
                          address={winner.address}
                          name={winner.name}
                          textStyle="detail"
                          isVerified={winner.isVerified}
                          bold
                        />
                      </HStack>
                    </Td>
                    <Td>
                      <HStack spacing={1} justifyContent="end">
                        <Text textStyle="detail" sx={tabularNums} bold>
                          {formatToDisplayDecimals({ amountWei: entryAmountWei, currency, size: "large" })}
                        </Text>

                        <TokenIcon boxSize={4} />
                      </HStack>
                    </Td>
                    <Td>
                      <HStack spacing={1} justifyContent="end">
                        <Text textStyle="detail" color="link-01" sx={tabularNums} bold>
                          {formatToDisplayDecimals({ amountWei: amountWonWei, currency, size: "large" })}
                        </Text>

                        <TokenIcon boxSize={4} />
                      </HStack>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
          {(!recentWins || recentWins.length === 0) && (
            <VStack py={4}>
              <Text color="text-03">{t("No recent wins")}</Text>
            </VStack>
          )}
        </TableContainer>
      </Box>
    </Stack>
  );
};
