import { type Context, useContext } from "react";
import { type Currency } from "@looksrare/config";
import { type GameContext, GameProviderContext } from "@looksrare/uikit";

export interface LaserBlastConfig {
  isSoundEnabled: boolean;
  isWalletBlocked: boolean;
  selfTimeoutUntil?: string | null;
  supportedCurrencies: Currency[];
}

export const useLaserBlastConfig = () => {
  const context = useContext(GameProviderContext as Context<GameContext<LaserBlastConfig> | undefined>);
  if (!context?.config) {
    throw new Error("No config found");
  }

  return context.config;
};
