import { Flex } from "@chakra-ui/react";
import { CheckmarkFilledIcon, type ModalProps, Text, Button } from "@looksrare/uikit";
import { useTranslation } from "next-i18next";
import { formatToSignificant, type BigIntish } from "@looksrare/utils";
import type { Currency } from "@looksrare/yolo-games-gql-typegen/src/generated/gql/graphql";

interface ConfirmWithdrawalViewProps {
  onClose: ModalProps["onClose"];
  finalizationIncentiveEthWei: BigIntish;
  currency: Currency;
}

export const ConfirmWithdrawalView = ({
  onClose,
  finalizationIncentiveEthWei,
  currency,
}: ConfirmWithdrawalViewProps) => {
  const { t } = useTranslation();
  return (
    <Flex flexDirection="column" justifyContent="center" alignItems="center">
      <CheckmarkFilledIcon color="link-01" boxSize={16} mb={4} />
      <Text as="h1" textStyle="heading-03" bold mb={2}>
        {t("liq::Withdrawal Complete")}
      </Text>
      <Text as="p" mb={8} textAlign="center">
        {t("You've withdrawn your assets and reclaimed your temporary finalization fee of {{feeDisplay}}.", {
          feeDisplay: `${formatToSignificant(finalizationIncentiveEthWei, 6, { decimals: currency.decimals })} ETH`,
        })}
      </Text>
      <Button width="100%" onClick={onClose}>
        {t("Nice")}
      </Button>
    </Flex>
  );
};
