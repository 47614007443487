import type { Address } from "viem";
import { useQuery } from "@tanstack/react-query";
import type { UserLiquidityPoolActionPreviewArgs } from "@looksrare/yolo-games-gql-typegen";
import { getUserLiquidityPoolActionPreview } from "../queries";

export const useUserLiquidityPoolActionPreview = ({
  address,
  contract,
  actionType,
  assetsAmountVum,
  enabled,
}: UserLiquidityPoolActionPreviewArgs & { address?: Address; enabled?: boolean }) => {
  return useQuery({
    queryKey: ["user-liquidity-pool-action-preview", address, contract, actionType, assetsAmountVum],
    queryFn: () => getUserLiquidityPoolActionPreview({ address: address!, contract, actionType, assetsAmountVum }),
    enabled,
  });
};
