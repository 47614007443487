import { Address } from "viem";
import { HStack, Stack, useDisclosure } from "@chakra-ui/react";
import { Alert, Button, ButtonProps, Datepicker, Text, selfExclusionForeverDate, useToast } from "@looksrare/uikit";
import { useTranslation } from "next-i18next";
import { useState } from "react";
import { formatAddress, useGetFormattedErrorAndTitle } from "@looksrare/utils";
import { format } from "date-fns/format";
import { isBefore } from "date-fns/isBefore";
import { isEqual } from "date-fns/isEqual";
import { addDays } from "date-fns";
import type { User } from "@/types/user";
import { useAskSelfTimeout, useInvalidateUser } from "@/queries/user";
import { TakeABreakConfirmModal } from "./TakeABreakConfirmModal";

export enum BreakDuration {
  OneDay = "OneDay",
  SevenDays = "SevenDays",
  ThirtyDays = "ThirtyDays",
  Forever = "Forever",
  Custom = "Custom",
}

export interface TakeABreakContentProps {
  address: Address;
  user: User;
}

const activeButtonProps: Partial<ButtonProps> = {
  variant: "solid",
  colorScheme: "secondary",
  color: "link-01",
};

const inactiveButtonProps: Partial<ButtonProps> = {
  variant: "outline",
  colorScheme: "secondary",
};

export const TakeABreakContent = ({ address, user }: TakeABreakContentProps) => {
  const { t } = useTranslation();
  const confirmModalDisclosure = useDisclosure();
  const { toast } = useToast();
  const getFormattedErrorAndTitle = useGetFormattedErrorAndTitle();

  const [selectedDuration, setSelectedDuration] = useState<BreakDuration>(BreakDuration.OneDay);
  const [customEndDate, setCustomEndDate] = useState<Date>(new Date());

  const invalidateUser = useInvalidateUser();
  const { mutateAsync: askSelfTimeout, isPending } = useAskSelfTimeout({
    onError: (error) => toast({ status: "error", ...getFormattedErrorAndTitle(error) }),
  });

  const isDateNotSetOrExpired = !user.selfTimeoutUntil || isBefore(user.selfTimeoutUntil, Date.now());

  const getButtonProps = (duration: BreakDuration) => {
    return selectedDuration === duration ? activeButtonProps : inactiveButtonProps;
  };

  const handleSelectCustomDuration = () => {
    setSelectedDuration(BreakDuration.Custom);
  };

  const getAlertText = () => {
    if (isDateNotSetOrExpired) {
      return t("Connected wallet: {{address}}", { address: formatAddress(address, 6) });
    }

    if (isEqual(user.selfTimeoutUntil!, selfExclusionForeverDate)) {
      return t("Wallet is blocked permanently.");
    }

    return t("Wallet is blocked until {{date}}", { date: format(user.selfTimeoutUntil!, "HH:mm dd MMMM yyyy") });
  };

  const getBreakEndDate = () => {
    if (selectedDuration === BreakDuration.SevenDays) {
      return addDays(new Date(), 7).toISOString();
    }

    if (selectedDuration === BreakDuration.ThirtyDays) {
      return addDays(new Date(), 30).toISOString();
    }

    if (selectedDuration === BreakDuration.Forever) {
      return new Date(selfExclusionForeverDate).toISOString();
    }

    if (selectedDuration === BreakDuration.Custom) {
      return customEndDate.toISOString();
    }

    return addDays(new Date(), 1).toISOString();
  };

  const handleAskSelfTimeout = async () => {
    const { askSelfTimeout: result } = await askSelfTimeout(getBreakEndDate());

    if (result.success) {
      invalidateUser(address);
    } else {
      toast({ status: "error", title: t("Error"), description: t("Something went wrong") });
    }
  };

  const confirmBlockLabel = t("Confirm Block: {{duration}}", { duration: t(selectedDuration) });

  return (
    <>
      <Stack alignItems="start" spacing={8}>
        <Alert status="info">
          <Text textStyle="detail">{getAlertText()}</Text>
        </Alert>

        <Stack spacing={2}>
          <Text textStyle="heading-03" bold>
            {t("Self-Exclusion")}
          </Text>

          <Text textStyle="body" color="text-02">
            {t("Block your currently connected wallet from playing YOLO Games for a set period of time.")}
          </Text>
        </Stack>

        {isDateNotSetOrExpired && (
          <>
            <Stack spacing={2}>
              <Text textStyle="detail" color="text-03" bold>
                {t("Block wallet for how long?")}
              </Text>

              <HStack spacing={2} flexWrap="wrap">
                {Object.values(BreakDuration).map((duration) => {
                  return (
                    <Button
                      key={duration}
                      onClick={() =>
                        duration === BreakDuration.Custom ? handleSelectCustomDuration() : setSelectedDuration(duration)
                      }
                      {...getButtonProps(duration)}
                    >
                      {t(duration)}
                    </Button>
                  );
                })}
              </HStack>
              {selectedDuration === BreakDuration.Custom && (
                <Datepicker minDate={addDays(new Date(), 1)} onChange={(dates) => setCustomEndDate(dates[0])} />
              )}
            </Stack>

            <Button onClick={confirmModalDisclosure.onOpen} colorScheme="secondary" isLoading={isPending}>
              {confirmBlockLabel}
            </Button>
          </>
        )}
      </Stack>
      <TakeABreakConfirmModal
        isOpen={confirmModalDisclosure.isOpen}
        confirmLabel={confirmBlockLabel}
        onClose={confirmModalDisclosure.onClose}
        onConfirm={() => {
          handleAskSelfTimeout();
          confirmModalDisclosure.onClose();
        }}
      />
    </>
  );
};
