import { Box, HStack, IconButton, Link, Stack } from "@chakra-ui/react";
import { DiscordIcon, TwitterIcon } from "@looksrare/uikit";
import { YoloLogoHorizontalColorOnDark } from "@/components/Assets";

export const LogoAndSocials = () => {
  return (
    <Stack px={{ base: 4, lg: 12 }} direction={{ base: "column", sm: "row" }} alignItems="center">
      <Box flexGrow={1}>
        <YoloLogoHorizontalColorOnDark height="40px" />
      </Box>
      <HStack gap={3}>
        <IconButton
          size="md"
          colorScheme="secondary"
          variant="ghost"
          aria-label="x-link"
          as={Link}
          href="https://twitter.com/YOLO_Blast"
          isExternal
        >
          <TwitterIcon boxSize={5} />
        </IconButton>
        <IconButton
          size="md"
          colorScheme="secondary"
          variant="ghost"
          aria-label="discord-link"
          as={Link}
          href="https://discord.gg/7SHJdtZz37"
          isExternal
        >
          <DiscordIcon boxSize={5} />
        </IconButton>
      </HStack>
    </Stack>
  );
};
