import { forwardRef } from "react";
import { Trans } from "next-i18next";
import { Flex, HStack, Spinner, type StackProps, type FlexProps } from "@chakra-ui/react";
import { ArrowRightIcon, Text, TokenDropletsIcon, type TextProps } from "@looksrare/uikit";
import { formatCompactNumber } from "@looksrare/utils";

export const Info = (props: FlexProps) => <Flex justifyContent="space-between" py={2} {...props} />;

/**
 * One of these days we will make this a universal component
 */
export const InfoTitle = forwardRef<HTMLDivElement, TextProps>((props, ref) => (
  <Text ref={ref} color="text-03" textStyle="detail" {...props} />
));
InfoTitle.displayName = "InfoTitle";

interface FeeTextProps {
  feeDisplay: string;
}

export const FeeText = ({ feeDisplay }: FeeTextProps) => {
  return (
    <Trans i18nKey="liqInitialDepositText" ns="liq">
      <Text as="p" textStyle="detail" color="text-03" mb={4}>
        This transaction includes a temporary fee of{" "}
        <Text as="span" textStyle="detail" color="white" bold>
          <>{{ feeDisplay }}</>
        </Text>
        , but you&apos;ll get it back as soon as you finalize your deposit.
      </Text>
    </Trans>
  );
};

interface DropletEstimateDisplayProps extends StackProps {
  current24hDroplets: number;
  estimated24hDroplets: number;
  isLoading: boolean;
}

export const DropletEstimateDisplay = ({
  current24hDroplets,
  estimated24hDroplets,
  isLoading,
  ...props
}: DropletEstimateDisplayProps) => {
  const textColor = estimated24hDroplets < current24hDroplets && !isLoading ? "text-error" : "text-body";
  return (
    <HStack spacing={1} {...props}>
      <Text textStyle="detail" color={textColor}>
        {formatCompactNumber(current24hDroplets)}
      </Text>
      <ArrowRightIcon boxSize={5} color={textColor} />
      <Text textStyle="detail" color={textColor}>
        {formatCompactNumber(estimated24hDroplets)}
      </Text>
      {isLoading ? (
        <Flex height={4} width={4} justifyContent="center" alignItems="center">
          <Spinner height={3} width={3} />
        </Flex>
      ) : (
        <TokenDropletsIcon boxSize={4} />
      )}
    </HStack>
  );
};
