import { Box, Skeleton } from "@chakra-ui/react";
import range from "lodash/range";
import { useAccount } from "wagmi";
import { useLiquidityPools } from "../hooks";
import { PoolCard } from "./PoolCard";

export const LiquidityPools = () => {
  const { address } = useAccount();
  const poolsQuery = useLiquidityPools({ address });

  return (
    <>
      <Box
        mb={8}
        border="1px solid"
        borderColor="border-01"
        borderRadius="dialog"
        bg="ui-01"
        overflow="hidden"
        sx={{
          _last: { borderBottom: 0 },
        }}
      >
        {(() => {
          if (poolsQuery.isLoading) {
            return (
              <>
                {range(0, 3).map((n) => (
                  <Skeleton height={10} key={n} speed={0} />
                ))}
              </>
            );
          }
          return poolsQuery.data?.map((pool) => (
            <PoolCard
              key={pool.currency.address}
              contract={pool.contract}
              currency={pool.currency}
              userPoolPct={pool.userPoolPct}
              userSharesValueVum={pool.userSharesValueVum}
              currentMultiplier={pool.liquidityPoolSeason?.currentMultiplier}
              nextIncreaseAt={pool.liquidityPoolSeason?.nextIncreaseAt}
              nextMultiplierIncrease={pool.liquidityPoolSeason?.nextMultiplierIncrease}
              dailyDroplets={pool.liquidityPoolSeason?.dailyDroplets}
              depositFeeBps={pool.liquidityPoolConfig.depositFeeBps}
              minDepositVum={pool.liquidityPoolConfig.minDepositVum}
              maxDepositVum={pool.liquidityPoolConfig.maxDepositVum}
              assetsBalanceVum={pool.assetsBalanceVum}
              pendingDepositsAmountVum={pool.pendingDepositsAmountVum}
              assetsLimitVum={pool.liquidityPoolConfig.assetsLimitVum}
              finalizationIncentiveEthWei={pool.liquidityPoolConfig.finalizationIncentiveEthWei}
              timelockDelaySecs={pool.liquidityPoolConfig?.timelockDelaySecs}
            />
          ));
        })()}
      </Box>
    </>
  );
};
