import type { Hash } from "viem";
import type { TransactionStatus } from "@looksrare/uikit";

export enum DepositWithdrawStep {
  IDLE = "IDLE",
  // Input
  DEPOSIT_INPUT = "DEPOSIT_INPUT",
  WITHDRAWAL_INPUT = "WITHDRAWAL_INPUT",
  // Deposit
  DEPOSIT_GRANT_APPROVAL = "DEPOSIT_GRANT_APPROVAL",
  DEPOSIT_APPROVE_TOKEN = "DEPOSIT_APPROVE_TOKEN",
  INITIATE_DEPOSIT = "INITIATE_DEPOSIT",
  DEPOSIT_TIMELOCK = "DEPOSIT_TIMELOCK",
  FINALIZE_DEPOSIT = "FINALIZE_DEPOSIT",
  DEPOSIT_CONFIRMATION = "DEPOSIT_CONFIRMATION",
  // Withdrawal
  WITHDRAWAL_GRANT_APPROVAL = "WITHDRAWAL_GRANT_APPROVAL",
  WITHDRAWAL_APPROVE_TOKEN = "WITHDRAWAL_APPROVE_TOKEN",
  INITIATE_WITHDRAWAL = "INITIATE_WITHDRAWAL",
  WITHDRAWAL_TIMELOCK = "WITHDRAWAL_TIMELOCK",
  FINALIZE_WITHDRAWAL = "FINALIZE_WITHDRAWAL",
  WITHDRAWAL_CONFIRMATION = "WITHDRAWAL_CONFIRMATION",
}

export type StepsWithActions =
  | DepositWithdrawStep.DEPOSIT_GRANT_APPROVAL
  | DepositWithdrawStep.DEPOSIT_APPROVE_TOKEN
  | DepositWithdrawStep.INITIATE_DEPOSIT
  | DepositWithdrawStep.DEPOSIT_TIMELOCK
  | DepositWithdrawStep.FINALIZE_DEPOSIT
  | DepositWithdrawStep.WITHDRAWAL_GRANT_APPROVAL
  | DepositWithdrawStep.WITHDRAWAL_APPROVE_TOKEN
  | DepositWithdrawStep.INITIATE_WITHDRAWAL
  | DepositWithdrawStep.WITHDRAWAL_TIMELOCK
  | DepositWithdrawStep.FINALIZE_WITHDRAWAL;

export type InitialAction = DepositWithdrawStep.DEPOSIT_INPUT | DepositWithdrawStep.WITHDRAWAL_INPUT;

export interface StepData {
  status: TransactionStatus;
  hasError: boolean;
  hash?: Hash;
}
