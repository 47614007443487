import { HStack, StackProps } from "@chakra-ui/react";
import { ClockIcon, Text } from "@looksrare/uikit";

interface TimePillProps extends StackProps {
  label: string;
}

export const TimePill = ({ label, ...props }: TimePillProps) => {
  return (
    <HStack spacing={1} px={3} py={1.5} borderRadius="button" borderWidth="1px" borderColor="interactive-03" {...props}>
      <Text textStyle="detail" color="link-01" bold>
        {label}
      </Text>

      <ClockIcon boxSize={5} color="link-01" />
    </HStack>
  );
};
