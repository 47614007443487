import { Modal } from "@looksrare/uikit";
import { type Currency } from "@looksrare/config";
import { Rakeback, RakebackClaimDetails, Rakebacks } from "@looksrare/yolo-games-gql-typegen";
import { ClaimRakebackModalBody } from "./ClaimRakebackModalBody";

interface ClaimRakebackModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  pausedContractCurrencySymbols?: Currency[];
  userRakebacks?: Rakebacks | null;
  nextRakeback?: Rakeback | null;
  claimDetails: RakebackClaimDetails[];
}

export const ClaimRakebackModal = ({
  isOpen,
  onClose,
  pausedContractCurrencySymbols,
  userRakebacks,
  onSuccess,
  nextRakeback,
  claimDetails,
}: ClaimRakebackModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ClaimRakebackModalBody
        onClose={onClose}
        onSuccess={onSuccess}
        pausedContractCurrencySymbols={pausedContractCurrencySymbols}
        userRakebacks={userRakebacks}
        nextRakeback={nextRakeback}
        claimDetails={claimDetails}
      />
    </Modal>
  );
};
