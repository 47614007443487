import { Box } from "@chakra-ui/react";
import { Container } from "@looksrare/uikit";
import { Hero, LiquidityHeroTab } from "./components";
import { LiquidityGameOverview } from "./components/LiquidityGameOverview";
import { LiquidityGlobalPerformance } from "./components/LiquidityGlobalPerformance";

export const LiquidityViewGlobal = () => {
  return (
    <Box>
      <Hero tab={LiquidityHeroTab.GLOBAL} />
      <Container py={8} maxWidth="1200px">
        <LiquidityGlobalPerformance />
        <LiquidityGameOverview />
      </Container>
    </Box>
  );
};
