import { type FC } from "react";
import { useTranslation } from "next-i18next";
import { usePreviousValue } from "@looksrare/utils";
import { type IconProps } from "@looksrare/uikit";
import type { LiquidityPoolMetricsFilterInput } from "@looksrare/yolo-games-gql-typegen";
import { useGlobalLiqudityPoolMetrics } from "../../hooks";
import { getPercentageChange } from "../../utils";
import { ChartBox } from "./ChartBox";
import { LiquidityInflowsChart } from "./LiquidityInflowsChart";

interface InflowsOutflowsProps {
  filters: LiquidityPoolMetricsFilterInput;
  poolIcon: FC<IconProps>;
  currencySymbol: string;
}

export const InflowsOutflows = ({ filters, poolIcon: PoolIcon, currencySymbol }: InflowsOutflowsProps) => {
  const { t } = useTranslation();
  const poolMetricsQuery = useGlobalLiqudityPoolMetrics({ filters });
  const inflowOutflowMetrics = poolMetricsQuery.data?.find((metric) => metric.metricType === "LP_NET_FLOW");
  const prevInflowOutflowMetrics = usePreviousValue(inflowOutflowMetrics);
  const currentInflowOutflowMetrics = inflowOutflowMetrics || prevInflowOutflowMetrics;
  const intervalPctChange = getPercentageChange(
    currentInflowOutflowMetrics?.currentCumulativeTotal,
    currentInflowOutflowMetrics?.previousCumulativeTotal
  );

  return (
    <ChartBox
      isLoading={poolMetricsQuery.isFetching}
      hasData={!!currentInflowOutflowMetrics && currentInflowOutflowMetrics.data.length > 0}
      chartTitle={t("liq::Pool Inflows/Outflows")}
      timeInterval={filters.timeInterval}
      currencySymbol={currencySymbol}
      currencyIcon={PoolIcon}
      intervalPctChange={intervalPctChange}
      intervalTotal={currentInflowOutflowMetrics?.intervalTotal}
      valueVumUsd={0}
      enableRelativeText
    >
      <LiquidityInflowsChart
        granularity={currentInflowOutflowMetrics?.dataGranularity ?? "_1H"}
        chartData={currentInflowOutflowMetrics?.data || []}
        timeInterval={filters.timeInterval}
      />
    </ChartBox>
  );
};
