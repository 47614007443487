import { ClockIcon, ModalHeroHeader } from "@looksrare/uikit";
import { useTranslation } from "next-i18next";

interface ClaimRakebackCalculatingHeaderProps {
  onClose: () => void;
}

export const ClaimRakebackCalculatingHeader = ({ onClose }: ClaimRakebackCalculatingHeaderProps) => {
  const { t } = useTranslation();

  return (
    <ModalHeroHeader
      icon={ClockIcon}
      title={t("Calculating Your Claim...")}
      label={t("Check back in a few minutes.")}
      labelProps={{ textStyle: "body", color: "text-02" }}
      flexShrink={0}
      onClose={onClose}
      showCloseButton
    />
  );
};
