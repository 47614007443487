import { useTranslation } from "next-i18next";
import { Stack } from "@chakra-ui/react";
import { EthMonoIcon, LevelsFilledIcon } from "@looksrare/uikit";
import { formatNumberToLocale, formatToSignificant } from "@looksrare/utils";
import { useGameStats } from "@/queries/stats";
import { SectionHeader } from "./SectionHeader";
import { StatBox } from "./StatBox";

export const PlatformStats = () => {
  const { t } = useTranslation();
  const { data: gameStats } = useGameStats();
  const { totalPlayers, totalGamesPlayed, volumePlayedWei } = gameStats || {};
  const totalPlayersDisplay = totalPlayers ? formatNumberToLocale(parseInt(totalPlayers.toString()), 0) : "-";
  const totalGamesPlayedDisplay = totalGamesPlayed
    ? formatNumberToLocale(parseInt(totalGamesPlayed.toString()), 0)
    : "-";
  const totalVolumeDisplay = volumePlayedWei ? formatToSignificant(volumePlayedWei, 0) : "-";

  return (
    <Stack spacing={4} px={{ base: 4, lg: 12 }}>
      <SectionHeader icon={LevelsFilledIcon} title={t("Platform Stats")} />

      <Stack spacing={3} direction={{ base: "column", xl: "row" }} width="100%">
        <Stack spacing={3} direction={{ base: "column", md: "row" }} flex={2}>
          <StatBox headerText={t("Total Players")} value={totalPlayersDisplay} flex={1} />
          <StatBox headerText={t("Total Game Volume")} value={totalVolumeDisplay} icon={EthMonoIcon} flex={1} />
        </Stack>
        <StatBox headerText={t("Total Games Played")} value={totalGamesPlayedDisplay} flex={1} />
      </Stack>
    </Stack>
  );
};
