import { ModalHeroHeader, WalletIcon } from "@looksrare/uikit";
import { useTranslation } from "next-i18next";

interface ClaimRakebackProblemHeaderProps {
  onClose: () => void;
}

export const ClaimRakebackProblemHeader = ({ onClose }: ClaimRakebackProblemHeaderProps) => {
  const { t } = useTranslation();

  return (
    <ModalHeroHeader
      icon={WalletIcon}
      title={t("Rakebacks is Currently Paused!")}
      label={t(
        "We're experiencing ongoing minor issues with a small percentage of users and want to ensure everyone has a fair chance to claim. We are investigating and will resume the claiming shortly."
      )}
      labelProps={{ textStyle: "body", color: "text-02" }}
      flexShrink={0}
      onClose={onClose}
      showCloseButton
    />
  );
};
