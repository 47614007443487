import { useAccount } from "wagmi";
import { useRouter } from "next/router";
import { AlertDescription, Divider, Flex, Stack, StackProps, useDisclosure } from "@chakra-ui/react";
import { Trans, useTranslation } from "next-i18next";
import {
  Alert,
  ArrowRightIcon,
  Button,
  EthTokenIcon,
  IconProps,
  NextLink,
  RocketIcon,
  StablesIcon,
  Text,
  TokenBoostIcon,
} from "@looksrare/uikit";
import { formatToSignificant, useGetFormattedErrorAndTitle, useSubmitTransaction } from "@looksrare/utils";
import { ClaimCompleteModal } from "@/components";
import { useBoostAllocation } from "@/queries/boost";
import { BoostAllocation } from "@/types";
import { useReferralCode } from "@/queries/user";
import { useClaimBoost } from "@/hooks/boost";
import { useToast } from "@/hooks";

interface TokenBoxProps extends StackProps {
  title: string;
  icon: (props: IconProps) => JSX.Element;
  amount: string;
}
const TokenBox = ({ title, icon: Icon, amount, ...props }: TokenBoxProps) => {
  return (
    <Stack spacing={1} {...props}>
      <Text textStyle="detail" bold color="text-03">
        {title}
      </Text>
      <Flex gap={2} alignItems="center">
        <Icon boxSize={5} />
        <Text textStyle="heading-03" color="text-01" bold>
          {amount}
        </Text>
      </Flex>
    </Stack>
  );
};

const boostAllocationDefaults: BoostAllocation = {
  boostFromEthWei: 0n,
  boostFromUsdWei: 0n,
  ethDepositedWei: 0n,
  usdDepositedWei: 0n,
  totalBoostWei: 0n,
  claimed: false,
  burned: false,
  proof: [],
};

export const ClaimStep = () => {
  const { t } = useTranslation();
  const { address } = useAccount();
  const router = useRouter();
  const claimCompleteModalDisclosure = useDisclosure();
  const { data: boostAllocation } = useBoostAllocation(address!, { enabled: !!address });
  const { boostFromEthWei, boostFromUsdWei, ethDepositedWei, usdDepositedWei, totalBoostWei, proof, claimed } =
    boostAllocation || boostAllocationDefaults;

  const ethDepositFormatted = formatToSignificant(ethDepositedWei.toString());
  const stablesDepositFormatted = formatToSignificant(usdDepositedWei.toString());
  const ethClaimableBoostFormatted = formatToSignificant(boostFromEthWei.toString());
  const stablesClaimableBoostFormatted = formatToSignificant(boostFromUsdWei.toString());
  const totalClaimableFormatted = formatToSignificant(totalBoostWei.toString());
  const { data: referralCode } = useReferralCode();
  const getFormattedErrorAndTitle = useGetFormattedErrorAndTitle();
  const { toast } = useToast();

  const claimBoost = useClaimBoost();
  const txHandler = useSubmitTransaction({
    onSend: async () => {
      if (!address) {
        return Promise.reject();
      }
      return await claimBoost({ address, amount: totalBoostWei, proof });
    },
    onSuccess: () => {
      claimCompleteModalDisclosure.onOpen();
    },

    onError: (error: any) => {
      const { title, description } = getFormattedErrorAndTitle(error);
      toast({ title, description, status: "error", dataIdSuffix: "claim-boost" });
    },
  });

  return (
    <>
      <Stack px={{ base: 4, md: 12 }} spacing={6} alignItems="center" width="100%">
        <Text width={{ base: "100%", md: "648px" }} textStyle="display-03" bold color="text-01" textAlign="center">
          {t("Rewards Summary")}
        </Text>
        <Stack
          borderRadius="24px"
          width="100%"
          maxWidth="648px"
          px={{ base: 2, md: 12 }}
          py={{ base: 6, md: 16 }}
          spacing={9}
          bg="rgba(255, 255, 255, 0.04)"
        >
          <Stack spacing={5} width="100%">
            <Stack spacing={1}>
              <Text textStyle="heading-03" bold>
                {t("Your BLAST Deposits")}
              </Text>
              <Trans i18nKey="rewards-summary">
                <Text color="text-03">
                  These are the tokens you&apos;ve deposited on Blast — and the corresponding
                  <Text as="span" color="link-01" mx="3px">
                    BOOST tokens
                  </Text>
                  earned
                </Text>
              </Trans>
            </Stack>
            <Stack spacing={4} py={4} px={6} border="1px solid" borderColor="border-01" borderRadius="container">
              <Flex gap={4} alignItems="center">
                <TokenBox flex={1} title={t("Ether")} icon={EthTokenIcon} amount={ethDepositFormatted} />
                <ArrowRightIcon boxSize={5} color="text-03" />
                <TokenBox
                  flex={1}
                  title={t("Claimable BOOST")}
                  icon={TokenBoostIcon}
                  amount={ethClaimableBoostFormatted}
                />
              </Flex>
              <Divider />
              <Flex gap={4} alignItems="center">
                <TokenBox flex={1} title={t("Stablecoins")} icon={StablesIcon} amount={stablesDepositFormatted} />
                <ArrowRightIcon boxSize={5} color="text-03" />
                <TokenBox
                  flex={1}
                  title={t("Claimable BOOST")}
                  icon={TokenBoostIcon}
                  amount={stablesClaimableBoostFormatted}
                />
              </Flex>
            </Stack>
            top
          </Stack>
          <Stack spacing={5} width="100%">
            <Stack spacing={1}>
              <Text textStyle="heading-03" bold>
                {t("Total Claimable")}
              </Text>
              <Text color="text-03">{t("The total amount of BOOST tokens available to claim")}</Text>
            </Stack>
            {!!totalBoostWei ? (
              <Stack spacing={4} py={4} px={6} border="1px solid" borderColor="interactive-03" borderRadius="container">
                <Text textStyle="heading-03" bold>
                  {!!claimed
                    ? t("{{amount}} (Already Claimed)", { amount: totalClaimableFormatted })
                    : totalClaimableFormatted}
                </Text>
                <Button
                  width="100%"
                  onClick={!!claimed ? () => router.push("/yolo/boost") : txHandler.submitTransaction}
                  isLoading={txHandler.isTxSending || txHandler.isTxWaiting}
                >
                  {!!claimed ? t("Go to Yolo BOOST mode") : t("Claim BOOST")}
                </Button>
              </Stack>
            ) : (
              <>
                <Alert status="info" data-id="alert-info-no-boost">
                  <AlertDescription>
                    {t(
                      "This wallet hasn't deposited to Blast yet — so it's ineligible for a BOOST airdrop. However, you can start playing and earning Points for the Season 1 airdrop. You game?"
                    )}
                  </AlertDescription>
                </Alert>
                <Button as={NextLink} href="/rewards" rightIcon={<RocketIcon />}>
                  {t("Let's Go!")}
                </Button>
              </>
            )}
          </Stack>
        </Stack>
      </Stack>
      <ClaimCompleteModal
        referralCode={referralCode || ""}
        {...claimCompleteModalDisclosure}
        onClose={() => {
          claimCompleteModalDisclosure.onClose();
          router.push("/yolo/boost");
        }}
        amountClaimed={BigInt(totalBoostWei.toString())}
      />
    </>
  );
};
