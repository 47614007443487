import { useEffect } from "react";
import { usePublicClient, useWalletClient } from "wagmi";
import { useTranslation } from "next-i18next";
import { Box } from "@chakra-ui/react";
import { Button, Text, TransactionStep, TransactionStepRow, useHandleModalStep } from "@looksrare/uikit";
import { NoPublicClientError, toDecimals } from "@looksrare/utils";
import { Erc20Abi, LiquidityPoolAbi, SAFE_MAX_UINT_256 } from "@looksrare/config";
import type { Currency, LiquidityPoolContract } from "@looksrare/yolo-games-gql-typegen/src/generated/gql/graphql";
import { getDefaultChain, getLiquidityPoolAddressFromContract } from "@/utils";
import { gameAddresses } from "@/config";
import { useDepositWithdrawStore } from "../store";
import { DepositWithdrawStep } from "../types";
import { useGrantApproval } from "../hooks";

interface ApproveWithdrawTransferStepProps {
  contract: LiquidityPoolContract;
  currency: Currency;
}

export const ApproveWithdrawTransferStep = ({ contract, currency }: ApproveWithdrawTransferStepProps) => {
  const { t } = useTranslation();
  const { data: walletClient } = useWalletClient();
  const publicClient = usePublicClient();

  const [
    step,
    inputValue,
    grantApprovalStepStatus,
    approveTokenStepStatus,
    startWithdrawalGrantApproval,
    startWithdrawalTokenApproval,
    startInitiateWithdraw,
    setFailStatus,
  ] = useDepositWithdrawStore((state) => [
    state.step,
    state.inputValue,
    state.stepStatus[DepositWithdrawStep.WITHDRAWAL_GRANT_APPROVAL],
    state.stepStatus[DepositWithdrawStep.WITHDRAWAL_APPROVE_TOKEN],
    state.startWithdrawalGrantApproval,
    state.startWithdrawalTokenApproval,
    state.startInitiateWithdraw,
    state.setFailStatus,
  ]);
  const grantTransferManagerApproval = useGrantApproval();

  const approveTransfer = useHandleModalStep({
    onSubmit: async () => {
      if (!walletClient) {
        throw Error("No wallet client found");
      }
      if (!publicClient) {
        throw NoPublicClientError;
      }

      startWithdrawalTokenApproval();

      const [account] = await walletClient.getAddresses();
      const inputVum = toDecimals(inputValue, currency.decimals);
      const chainId = getDefaultChain();
      const addresses = gameAddresses[chainId];

      // Check allowance
      const [shares, allowance] = await Promise.all([
        publicClient.readContract({
          address: getLiquidityPoolAddressFromContract(contract),
          abi: LiquidityPoolAbi,
          functionName: "convertToShares",
          args: [inputVum],
        }),
        publicClient.readContract({
          address: getLiquidityPoolAddressFromContract(contract),
          abi: Erc20Abi,
          functionName: "allowance",
          args: [account, addresses.LIQUIDITY_TRANSFER_MANAGER],
        }),
      ]);

      if (allowance >= shares) {
        startInitiateWithdraw();
        return;
      }

      // Approval
      const { request } = await publicClient.simulateContract({
        address: getLiquidityPoolAddressFromContract(contract),
        abi: Erc20Abi,
        functionName: "approve",
        args: [addresses.LIQUIDITY_TRANSFER_MANAGER, SAFE_MAX_UINT_256],
        account,
      });
      const hash = await walletClient.writeContract(request);
      await publicClient.waitForTransactionReceipt({ hash });
      startInitiateWithdraw();
    },
    onError: () => {
      setFailStatus(DepositWithdrawStep.WITHDRAWAL_APPROVE_TOKEN);
    },
  });

  const handleRetryApproveTransfer = () => {
    approveTransfer.handleSubmit();
  };

  const grantApproval = useHandleModalStep({
    onSubmit: async () => {
      grantTransferManagerApproval({
        onBeforeSend: () => {
          startWithdrawalGrantApproval();
        },
        onSuccess: () => {
          startWithdrawalTokenApproval();
        },
      });
    },
    onError: () => {
      setFailStatus(DepositWithdrawStep.WITHDRAWAL_GRANT_APPROVAL);
    },
  });

  const handleRetryGrantApprovals = () => {
    grantApproval.handleSubmit();
  };

  useEffect(() => {
    if (step === DepositWithdrawStep.WITHDRAWAL_GRANT_APPROVAL) {
      grantApproval.handleSubmit({ callOnlyOnce: true });
    }
    if (step === DepositWithdrawStep.WITHDRAWAL_APPROVE_TOKEN) {
      approveTransfer.handleSubmit({ callOnlyOnce: true });
    }
  }, [step, grantApproval, approveTransfer]);

  return (
    <TransactionStep
      status={approveTokenStepStatus.timingStatus}
      title={t("liq::Approve Transfer")}
      width="100%"
      collapse={approveTokenStepStatus.timingStatus !== "current"}
      bodyWrapperProps={{ pl: 0, pt: 0 }}
      mb={4}
    >
      <Box py={6} px={4} bg="ui-01" borderRadius="container">
        <Text textStyle="detail" mb={4}>
          {t("Waiting for you to confirm in wallet")}
        </Text>
        <TransactionStepRow
          status={grantApprovalStepStatus.status}
          transactionHash={grantApprovalStepStatus.hash}
          text={t("Transfer Manager")}
          mb={2}
        />
        {grantApprovalStepStatus.hasError && (
          <Button width="100%" size="sm" my={4} onClick={handleRetryGrantApprovals}>
            {t("Retry")}
          </Button>
        )}
        <TransactionStepRow
          status={approveTokenStepStatus.status}
          transactionHash={approveTokenStepStatus.hash}
          text={t("liq::Approve Share Transfer")}
          mb={2}
        />
        {approveTokenStepStatus.hasError && (
          <Button width="100%" size="sm" mt={4} onClick={handleRetryApproveTransfer}>
            {t("Retry")}
          </Button>
        )}
      </Box>
    </TransactionStep>
  );
};
