import { forwardRef, useState } from "react";
import { zeroAddress } from "viem";
import { useAccount } from "wagmi";
import { useTranslation } from "next-i18next";
import { Grid, GridItem, HStack, Spinner, StackProps, type GridProps } from "@chakra-ui/react";
import {
  formatCompactNumber,
  formatNumberToLocale,
  formatToSignificant,
  isAddressEqual,
  sleep,
  timeAgo,
  type BigIntish,
} from "@looksrare/utils";
import type {
  Currency as GqlCurrency,
  LiquidityPoolContract,
} from "@looksrare/yolo-games-gql-typegen/src/generated/gql/graphql";
import { INDEXER_REFETCH_DELAY_MS } from "@looksrare/config";
import {
  Button,
  ConnectWalletButton,
  FireIcon,
  InformationCircleIcon,
  Popover,
  Text,
  TokenDropletsIcon,
  TooltipText,
  currencyTokenIconMap,
  type TextProps,
} from "@looksrare/uikit";
import { lpConfig } from "@/config";
import { getLiquidityPoolAddressFromContract } from "@/utils";
import { DepositWithdrawModal, DepositWithdrawStep, type InitialAction } from "../Modals";
import {
  useGetTokenBalanceInfoForLP,
  useInvalidateLiquidityPools,
  useInvalidateUserLiquidityPendingActions,
  useInvalidateUserLiquidityPoolMetrics,
  useMaxRedeemableAssets,
  useUserLiquidityPendingActions,
} from "../../hooks";
import { DropletTooltipText } from "../TooltipText";

interface PoolCardProps extends GridProps {
  contract: LiquidityPoolContract;
  currency: GqlCurrency;
  userPoolPct: number;
  userSharesValueVum: BigIntish;
  dailyDroplets?: BigIntish;
  currentMultiplier?: number;
  nextIncreaseAt?: string | null;
  nextMultiplierIncrease?: number;
  depositFeeBps: number;
  minDepositVum: BigIntish;
  maxDepositVum: BigIntish;
  assetsBalanceVum: BigIntish;
  pendingDepositsAmountVum: BigIntish;
  assetsLimitVum: BigIntish;
  finalizationIncentiveEthWei: BigIntish;
  timelockDelaySecs: number;
}

interface State {
  isOpen: boolean;
  initialAction: InitialAction;
}

const RowTitle = (props: TextProps) => (
  <Text color="text-03" textStyle={{ base: "body", md: "helper" }} bold {...props} />
);
const RowValue = (props: TextProps) => (
  <Text bold textStyle={{ base: "body", md: "helper" }} color="currentcolor" {...props} />
);
const RowMeta = (props: TextProps) => <Text textStyle="caption" color="text-03" {...props} />;
const RowHStack = forwardRef<HTMLDivElement, StackProps>((props, ref) => (
  <HStack ref={ref} spacing={1} minHeight={6} {...props} />
));
RowHStack.displayName = "RowHStack";

export const PoolCard = ({
  contract,
  currency,
  userPoolPct,
  userSharesValueVum,
  dailyDroplets,
  currentMultiplier,
  nextIncreaseAt,
  nextMultiplierIncrease,
  depositFeeBps,
  minDepositVum,
  maxDepositVum,
  assetsBalanceVum,
  pendingDepositsAmountVum,
  assetsLimitVum,
  finalizationIncentiveEthWei,
  timelockDelaySecs,
  ...props
}: PoolCardProps) => {
  const { t } = useTranslation();
  const { isConnected, address } = useAccount();
  const [state, setState] = useState<State>({ isOpen: false, initialAction: DepositWithdrawStep.DEPOSIT_INPUT });
  const { isLoading: isLoadingTokenBalance, tokenBalanceVum } = useGetTokenBalanceInfoForLP(
    isAddressEqual(currency.address, zeroAddress),
    currency.address,
    currency.decimals
  );

  const invalidatePendingActions = useInvalidateUserLiquidityPendingActions();
  const invalidateLiquidityPools = useInvalidateLiquidityPools();
  const invalidateUserLiquidityPoolMetrics = useInvalidateUserLiquidityPoolMetrics();
  const maxRedeemableAssets = useMaxRedeemableAssets(getLiquidityPoolAddressFromContract(contract));

  // maxRedeemableAssets is the most a user can withdraw
  // this value falls back the BE value which could be outdated
  const estimatedPosition = BigInt(maxRedeemableAssets.data || userSharesValueVum);

  const Icon = currencyTokenIconMap[currency.symbol];
  const poolName = t("liq::{{token}} Pool", { token: currency.symbol });

  const userLiquidityPendingActionsQuery = useUserLiquidityPendingActions(address);
  const pendingAction = userLiquidityPendingActionsQuery.data?.find((action) => action.contract === contract);

  const isLoading = isLoadingTokenBalance || userLiquidityPendingActionsQuery.isLoading;

  const hasPendingDeposit = pendingAction?.type === "DEPOSIT";
  const hasPendingRedeem = pendingAction?.type === "REDEEM";
  const hasReachedPoolLimit = BigInt(assetsBalanceVum) + BigInt(pendingDepositsAmountVum) >= BigInt(assetsLimitVum);

  const isDepositButtonDisabled = (() => {
    if (hasPendingDeposit) {
      return false;
    }
    return tokenBalanceVum === 0n || hasReachedPoolLimit;
  })();
  const isWithdrawButtonDisabled = estimatedPosition === 0n;

  const percentOfPoolDisplay = (() => {
    if (userPoolPct === 0) {
      return "0%";
    }
    if (userPoolPct < 0.001) {
      return t("liq::{{percentOfPool}} of Pool", { percentOfPool: "<0.001%" });
    }
    return t("liq::{{percentOfPool}} of Pool", { percentOfPool: `${formatNumberToLocale(userPoolPct, 2, 2)}%` });
  })();

  const handleFinalizatonSuccess = async () => {
    await sleep(INDEXER_REFETCH_DELAY_MS);
    invalidatePendingActions(address);
    invalidateLiquidityPools(address);
    invalidateUserLiquidityPoolMetrics();
  };

  const handleOpen = (initialAction: InitialAction) => {
    setState({ isOpen: true, initialAction });
  };

  const handleClose = () => {
    setState({ isOpen: false, initialAction: DepositWithdrawStep.DEPOSIT_INPUT });
  };

  return (
    <>
      <Grid
        gridTemplateColumns={{ base: "repeat(2, minmax(0, 1fr))", md: "repeat(4, minmax(0, 1fr)) minmax(108px, auto)" }}
        gridTemplateAreas={{
          base: "'currency currency' 'position droplets' 'multiplier .' 'manage manage'",
          md: "'currency position droplets multiplier manage'",
        }}
        gridGap={4}
        p={4}
        borderBottom="1px solid"
        borderBottomColor="border-01"
        {...props}
      >
        <GridItem gridArea="currency" alignSelf="center">
          <HStack spacing={4} flexDirection={{ base: "row-reverse", md: "row" }} width="100%">
            <Icon boxSize={8} />
            <Text color={lpConfig[contract].textColor} bold flex={1} textStyle={{ base: "display-body", md: "body" }}>
              {poolName}
            </Text>
          </HStack>
        </GridItem>
        <GridItem gridArea="position">
          <RowTitle>{t("liq::Est. Position")}</RowTitle>
          <Popover
            variant="tooltip"
            label={
              <TooltipText>
                {t(
                  "liq::Due to slippage, the total value of your deposits and withdrawals may change slightly in the time between initiating and finalizing your transactions."
                )}
              </TooltipText>
            }
          >
            <RowHStack color={currentMultiplier === 0 ? "text-03" : "link-01"}>
              <Icon boxSize={4} />
              <RowValue color="text-01">
                {formatToSignificant(estimatedPosition, lpConfig[contract].displayDecimals, {
                  decimals: currency.decimals,
                })}
              </RowValue>
              <InformationCircleIcon boxSize={4} color="text-03" />
            </RowHStack>
          </Popover>
          <RowMeta>{percentOfPoolDisplay}</RowMeta>
        </GridItem>
        <GridItem gridArea="droplets">
          <RowTitle>{t("liq::Est. {{timePeriodDisplay}} Droplets", { timePeriodDisplay: "24h" })}</RowTitle>
          <Popover variant="tooltip" label={<DropletTooltipText />}>
            <RowHStack>
              <TokenDropletsIcon boxSize={4} />
              <RowValue>{formatCompactNumber(Number(dailyDroplets ?? 0))}</RowValue>
              <InformationCircleIcon boxSize={4} color="text-03" />
            </RowHStack>
          </Popover>
        </GridItem>
        <GridItem gridArea="multiplier">
          <RowTitle>{t("liq::Current Mult.")}</RowTitle>
          <Popover
            variant="tooltip"
            label={
              <TooltipText>
                {t(
                  "liq::Your multiplier is applied to your position to determine the number of Droplets you earn. It increases every {{increaseInterval}} days",
                  { increaseInterval: "30" }
                )}
              </TooltipText>
            }
          >
            <RowHStack color={currentMultiplier === 0 ? "text-03" : "link-01"}>
              <FireIcon boxSize={4} />
              <RowValue>{`x${formatNumberToLocale(currentMultiplier ?? 1, 0, 2) ?? 1}`}</RowValue>
              <InformationCircleIcon boxSize={4} color="text-03" />
            </RowHStack>
          </Popover>
          {!!nextMultiplierIncrease && nextMultiplierIncrease > 0 && !!nextIncreaseAt && (
            <Text textStyle="caption" color="text-03">
              {t("{{multiplier}}x increase in {{nextIncrease}}", {
                multiplier: nextMultiplierIncrease,
                nextIncrease: timeAgo(new Date(nextIncreaseAt), { addSuffix: false, shortLabels: true }),
              })}
            </Text>
          )}
        </GridItem>
        <GridItem gridArea="manage" alignSelf="center">
          {(() => {
            if (!isConnected) {
              return <ConnectWalletButton />;
            }
            return (
              <HStack spacing={4}>
                {/* Withdrawal */}
                <Button
                  colorScheme="brand"
                  width="100%"
                  onClick={() => handleOpen(DepositWithdrawStep.WITHDRAWAL_INPUT)}
                  size="sm"
                  variant="outline"
                  isLoading={isLoading}
                  rightIcon={hasPendingRedeem ? <Spinner boxSize={4} /> : undefined}
                  isDisabled={isWithdrawButtonDisabled}
                >
                  {hasPendingRedeem ? t("liq::Finalize") : t("liq::Withdraw")}
                </Button>
                {/* Deposit */}
                <Button
                  colorScheme="brand"
                  width="100%"
                  onClick={() => handleOpen(DepositWithdrawStep.DEPOSIT_INPUT)}
                  size="sm"
                  isLoading={isLoading}
                  isDisabled={isDepositButtonDisabled}
                  rightIcon={hasPendingDeposit ? <Spinner boxSize={4} /> : undefined}
                >
                  {(() => {
                    if (hasReachedPoolLimit) {
                      return t("liq::Pool Full");
                    }
                    if (hasPendingDeposit) {
                      return t("liq::Finalize");
                    }
                    return t("liq::Deposit");
                  })()}
                </Button>
              </HStack>
            );
          })()}
        </GridItem>
      </Grid>
      <DepositWithdrawModal
        isOpen={state.isOpen}
        initialAction={state.initialAction}
        onClose={handleClose}
        poolName={poolName}
        contract={contract}
        currency={currency}
        dailyDroplets={dailyDroplets}
        depositFeeBps={depositFeeBps}
        minDepositVum={minDepositVum}
        maxDepositVum={maxDepositVum}
        assetsBalanceVum={assetsBalanceVum}
        pendingDepositsAmountVum={pendingDepositsAmountVum}
        assetsLimitVum={assetsLimitVum}
        finalizationIncentiveEthWei={finalizationIncentiveEthWei}
        timelockDelaySecs={timelockDelaySecs}
        onFinalizeSuccess={handleFinalizatonSuccess}
        currentMultiplier={currentMultiplier}
      />
    </>
  );
};
