import { Box, Center, Divider, Flex, ModalBody, ModalHeader, Stack, VStack } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import {
  BlastGoldTokenIcon,
  Button,
  GoldBigPrizeIcon,
  Link,
  ModalCloseButton,
  Text,
  TwitterIcon,
  useConfettiOnMount,
} from "@looksrare/uikit";
import Image from "next/image";
import { formatNumberToLocale } from "@looksrare/utils";
import { gradients } from "@/theme/colors";

interface WinSummaryProps {
  gamesGold: number;
  dropletsGold: number;
  yYoloGold: number;
  onClose: () => void;
}

export const WinSummary = ({ gamesGold, dropletsGold, yYoloGold, onClose }: WinSummaryProps) => {
  const { t } = useTranslation();
  useConfettiOnMount();

  const totalGoldDisplay = formatNumberToLocale(gamesGold + dropletsGold + yYoloGold, 0);
  const textToShare = `I just received ${totalGoldDisplay} Blast Gold in by stacking YOLO Points and Droplets on @YOLO_Blast!\n\nI love GOOOOOOOLD!`;

  return (
    <>
      <ModalHeader pt={4} px={8} pb={0}>
        <ModalCloseButton position="absolute" top={4} right={4} onClick={onClose} />
        <VStack py={6} spacing={8}>
          <Center position="relative" boxSize="128px">
            <Box
              position="absolute"
              animation="spin 20s linear infinite"
              boxSize="208px"
              sx={{
                "@keyframes spin": {
                  from: {
                    transform: "rotate(0deg)",
                  },
                  to: {
                    transform: "rotate(360deg)",
                  },
                },
              }}
            >
              <Image src="/images/star.svg" layout="fill" alt="" />
            </Box>

            <GoldBigPrizeIcon position="absolute" boxSize={32} />
          </Center>

          <Text textStyle="display-03" textAlign="center">
            {t("You’ve won gold!")}
          </Text>
        </VStack>
      </ModalHeader>

      <ModalBody pt={4}>
        <Stack spacing={4}>
          <Stack
            spacing={4}
            px={4}
            py={6}
            bgGradient={gradients["new-ui-gradient-v-02"]}
            borderWidth="1px"
            borderColor="border-01"
            borderRadius="dialog"
            overflow="hidden"
          >
            <VStack spacing={2}>
              <BlastGoldTokenIcon boxSize={8} />

              <Text
                textStyle="heading-03"
                textTransform="uppercase"
                bgGradient={gradients["ui-gradient-goldrush"]}
                backgroundClip="text"
                sx={{ WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent" }}
                bold
              >
                {t("{{amount}} Gold", { amount: totalGoldDisplay })}
              </Text>
            </VStack>

            <Flex gap={4} flexWrap="wrap">
              <VStack spacing={0} flex={1}>
                <Text textStyle="body" bold>
                  {formatNumberToLocale(gamesGold, 0)}
                </Text>

                <Text textStyle="helper" color="text-03">
                  {t("from epoch Pts")}
                </Text>
              </VStack>

              <VStack spacing={0} flex={1}>
                <Text textStyle="body" bold>
                  {formatNumberToLocale(dropletsGold, 0)}
                </Text>

                <Text textStyle="helper" color="text-03">
                  {t("from Droplets")}
                </Text>
              </VStack>

              <VStack spacing={0} flex={1}>
                <Text textStyle="body" bold>
                  {formatNumberToLocale(yYoloGold, 0)}
                </Text>

                <Text textStyle="helper" color="text-03" whiteSpace="nowrap">
                  {t("from yYOLO Holdings")}
                </Text>
              </VStack>
            </Flex>

            <Divider />

            <Text textStyle="body" textAlign="center" color="text-03">
              {t(
                "You’ve earned a mid-season Blast Gold distribution from your epoch Points, Droplets and yYOLO holdings! Confirm your distribution via your Blast dashboard."
              )}
            </Text>
          </Stack>
          <Button
            as={Link}
            href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(textToShare)}`}
            rightIcon={<TwitterIcon boxSize={5} />}
            colorScheme="secondary"
            width="100%"
            isExternal
          >
            {t("Shout about it on")}
          </Button>
        </Stack>
      </ModalBody>
    </>
  );
};
