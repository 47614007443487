import { useState } from "react";
import { useAccount } from "wagmi";
import { AlertDescription, Box, HStack } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import {
  Alert,
  Button,
  CheckboxCheckedFilledIcon,
  CheckboxOutlineIcon,
  EthTokenIcon,
  InformationCircleIcon,
  NumberInput,
  Popover,
  Text,
} from "@looksrare/uikit";
import type { Currency, LiquidityPoolContract } from "@looksrare/yolo-games-gql-typegen/src/generated/gql/graphql";
import {
  type BigIntish,
  formatToSignificant,
  useGetCurrencyConfig,
  toDecimals,
  fromDecimals,
  formatNumberToLocale,
  useDebouncedValue,
} from "@looksrare/utils";
import { lpConfig } from "@/config";
import { getLiquidityPoolAddressFromContract } from "@/utils";
import {
  useGetTokenBalanceInfoForLP,
  useMaxRedeemableAssets,
  useUserLiquidityPoolActionPreview,
} from "@/views/Liquidity/hooks";
import { SlippageTooltipText } from "../../TooltipText";
import { useDepositWithdrawStore } from "./store";
import { DropletEstimateDisplay, Info, InfoTitle } from "./shared";
import { useInputValidation } from "./hooks";

interface WithdrawInputViewProps {
  currency: Currency;
  contract: LiquidityPoolContract;
  isEthPool: boolean;
  dailyDroplets?: BigIntish;
  currentMultiplier?: number;
}

export const WithdrawInputView = ({
  currency,
  contract,
  isEthPool,
  dailyDroplets,
  currentMultiplier = 1,
}: WithdrawInputViewProps) => {
  const { address } = useAccount();
  const { t } = useTranslation();
  const [hasAcknowledged, setHasAcknowledged] = useState(false);
  const [inputValue, setInputValue, startWithdrawalGrantApproval] = useDepositWithdrawStore((state) => [
    state.inputValue,
    state.setInputValue,
    state.startWithdrawalGrantApproval,
  ]);
  const { isLoading, tokenBalanceVum } = useGetTokenBalanceInfoForLP(isEthPool, currency.address, currency.decimals);
  const maxRedeemQuery = useMaxRedeemableAssets(getLiquidityPoolAddressFromContract(contract));
  const getCurrencyConfig = useGetCurrencyConfig();

  const { icon: Icon = EthTokenIcon } = getCurrencyConfig(currency.address);
  const maxUserShareValueVum = maxRedeemQuery.data || 0n;
  const inputVum = !!inputValue ? toDecimals(inputValue, currency.decimals) : 0n;

  const { isValidInput, warningText } = useInputValidation({
    inputValueVum: inputVum,
    tokenBalanceVum,
    maxVum: maxUserShareValueVum,
    tokenDecimals: currency.decimals,
    tokenSymbol: currency.symbol,
    validateTokenBalance: false,
    displayDecimals: lpConfig[contract].displayDecimals,
  });

  const debouncedInputValue = useDebouncedValue(inputVum, 1_000);
  const actionPreviewQuery = useUserLiquidityPoolActionPreview({
    address,
    contract,
    actionType: "REDEEM",
    assetsAmountVum: debouncedInputValue,
  });
  const current24hDroplets = !!dailyDroplets ? Number(dailyDroplets) : 0;
  const estimated24hDroplets = (() => {
    if (actionPreviewQuery.isSuccess) {
      return Number(actionPreviewQuery.data?.dailyDroplets);
    }
    return current24hDroplets;
  })();

  const handleSetMaxValue = () => {
    setInputValue(fromDecimals(maxUserShareValueVum, { decimals: currency.decimals }));
  };

  const handleInputChange = (newValue: string) => {
    setInputValue(newValue);
  };

  const handleClick = async () => {
    startWithdrawalGrantApproval();
  };

  return (
    <Box>
      <Box position="relative" height={12} mb={4}>
        <Box position="absolute" left={4} top="50%" transform="translateY(-50%)">
          <Icon boxSize={5} />
        </Box>
        <NumberInput size="lg" value={inputValue} onTextChange={handleInputChange} px="52px" />
        <Button
          size="xs"
          variant="ghost"
          color="white"
          position="absolute"
          right={4}
          top="50%"
          transform="translateY(-50%)"
          onClick={handleSetMaxValue}
          isLoading={isLoading}
          isDisabled={maxUserShareValueVum === 0n}
        >
          {t("Max")}
        </Button>
      </Box>
      {!!warningText && (
        <Text textStyle="detail" color="text-error" pb={2}>
          {warningText}
        </Text>
      )}
      <Info>
        <InfoTitle>{t("liq::Remaining Position")}</InfoTitle>
        <Box>
          <HStack spacing={2} justifyContent="end">
            <Text textStyle="detail">
              {formatToSignificant(maxUserShareValueVum - inputVum, lpConfig[contract].displayDecimals, {
                decimals: currency.decimals,
              })}
            </Text>
            <Icon boxSize={4} />
          </HStack>
        </Box>
      </Info>
      <Info mb={4}>
        <Popover label={<SlippageTooltipText />}>
          <HStack spacing={1}>
            <InfoTitle>{t("liq::Est. Total Withdrawl")}</InfoTitle>
            <InformationCircleIcon boxSize={4} color="text-03" />
          </HStack>
        </Popover>
        <Box>
          <HStack spacing={2} justifyContent="end">
            <Text textStyle="detail">
              {formatToSignificant(inputVum, lpConfig[contract].displayDecimals, { decimals: currency.decimals })}
            </Text>
            <Icon boxSize={4} />
          </HStack>
        </Box>
      </Info>
      <Alert status="warning" mb={4}>
        <AlertDescription>
          {t(
            "liq::Withdrawing your position will reduce your current Droplet Multiplier. Keep your position to continue earning Droplets."
          )}
        </AlertDescription>
      </Alert>
      <Info>
        <InfoTitle>{t("liq::Est. {{timePeriodDisplay}} Droplets", { timePeriodDisplay: "24h" })}</InfoTitle>
        <DropletEstimateDisplay
          current24hDroplets={current24hDroplets}
          estimated24hDroplets={estimated24hDroplets}
          isLoading={actionPreviewQuery.isFetching}
        />
      </Info>
      <Info mb={4}>
        <InfoTitle>{t("liq::Multiplier")}</InfoTitle>
        <Text bold textStyle="detail">{`${formatNumberToLocale(currentMultiplier, 2)}x`}</Text>
      </Info>
      <HStack
        alignItems="start"
        mb={4}
        onClick={() => setHasAcknowledged((prevHasAcknowledged) => !prevHasAcknowledged)}
        cursor="pointer"
      >
        {hasAcknowledged ? <CheckboxCheckedFilledIcon /> : <CheckboxOutlineIcon />}
        <Text userSelect="none" textStyle="detail">
          {t("liq::I understand that withdrawing will reduce my Droplet Multiplier")}
        </Text>
      </HStack>
      <Button isDisabled={!isValidInput || !hasAcknowledged} isLoading={isLoading} width="100%" onClick={handleClick}>
        {t("Withdraw")}
      </Button>
    </Box>
  );
};
