import { useTranslation } from "next-i18next";
import { ButtonProps } from "@chakra-ui/react";
import { useGetFormattedErrorAndTitle, useSignInHandler } from "@looksrare/utils";
import { Button } from "@looksrare/uikit";
import { useToast } from "@/hooks/useToast";
import { useClaimDailyQuest } from "@/hooks/points";
import { DailyQuestCode } from "@/types/points";

interface ClaimDailyQuestButtonProps extends ButtonProps {
  code: DailyQuestCode;
}
export const ClaimDailyQuestButton = ({ code, children, ...props }: ClaimDailyQuestButtonProps) => {
  const { t } = useTranslation();

  const { toast } = useToast();
  const getFormattedErrorAndTitle = useGetFormattedErrorAndTitle();
  const { mutate: claimDailyQuest, isPending } = useClaimDailyQuest({
    onError: (error) => {
      const { title, description } = getFormattedErrorAndTitle(error);
      toast({ title, description, status: "error", dataIdSuffix: "claim-daily-quest-query-failure" });
    },
  });

  const handleAuthFailure = (error: any) => {
    const { title, description } = getFormattedErrorAndTitle(error);
    toast({ title, description, status: "error", dataIdSuffix: "claim-daily-quest-auth-failure" });
  };

  const { signInHandler, isSigning } = useSignInHandler({
    onAuthSuccess: () => claimDailyQuest(code),
    onAuthFailure: handleAuthFailure,
  });

  return (
    <Button
      isLoading={isPending || isSigning}
      onClick={(e) => {
        e.stopPropagation();
        signInHandler();
      }}
      {...props}
    >
      {children || t("Claim")}
    </Button>
  );
};
