import { Flex, IconProps } from "@chakra-ui/react";
import { Text } from "@looksrare/uikit";

interface SectionHeaderProps {
  icon: (props: IconProps) => JSX.Element;
  title: string;
}

export const SectionHeader = ({ icon: Icon, title }: SectionHeaderProps) => {
  return (
    <Flex gap={2} alignItems="center">
      <Icon boxSize={5} />
      <Text bold textTransform="uppercase">
        {title}
      </Text>
    </Flex>
  );
};
