import type { QueryLiquidityPoolMetricsArgs } from "@looksrare/yolo-games-gql-typegen";
import { useQuery } from "@tanstack/react-query";
import { getGlobalLiqudityPoolMetrics } from "../queries";

export const useGlobalLiqudityPoolMetrics = ({ filters }: QueryLiquidityPoolMetricsArgs) => {
  return useQuery({
    queryKey: ["user-liquidity-pool-global-metrics", filters],
    queryFn: () => getGlobalLiqudityPoolMetrics({ filters }),
  });
};
