import { Box, BoxProps, Flex, FlexProps, Progress, TagLabel } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { Button, Tag, Text, CheckmarkFilledIcon } from "@looksrare/uikit";
import { dialogRadius, gradients } from "@looksrare/chakra-theme";
import { divideWeiByWei, formatNumberToLocale, formatToSignificant } from "@looksrare/utils";
import { useBoxShadowPreset } from "@/hooks/useBoxShadowPreset";
import { MilestoneCode, UserMilestoneLevel } from "@/types";
import { MilestoneState, useGetMilestoneLevelText } from "./hooks";
import { DescriptionText } from "./MilestoneModals/DescriptionText";
import { getCurrentLevel } from "./utils";

interface MilestoneIconProps extends BoxProps {
  milestoneCode: MilestoneCode;
}

export const MilestoneIcon = ({ milestoneCode, ...props }: MilestoneIconProps) => {
  const imageUrl = {
    ["MORD_ROUND"]: "url('/images/quests/zero-g.png')",
    ["MORD_WIN"]: "url('/images/quests/to-infinity-and-beyond.png')",
    ["MORD_WIN_STREAK"]: "url('/images/quests/precision-engineering.png')",
    ["MORD_ETH"]: "url('/images/quests/space-race.png')",

    ["PTB_ETH"]: "url('/images/quests/thrill-seeker.png')",
    ["PTB_WIN"]: "url('/images/quests/bear-whisperer.png')",
    ["PTB_WIN_STREAK"]: "url('/images/quests/immortal.png')",
    ["PTB_FUTURE_ROUND"]: "url('/images/quests/sucker-for-pain.png')",

    ["YOLO_ETH"]: "url('/images/quests/eth-baller.png')",
    ["YOLO_WIN"]: "url('/images/quests/the-luck-of-the-devil.png')",
    ["YOLO_ROUND"]: "url('/images/quests/russian-roulette.png')",
    ["YOLO_FUTURE_ROUND"]: "url('/images/quests/over-and-over.png')",

    ["POINTS"]: "url('/images/quests/double-dippin.png')",
    ["PROFILE_SETUP"]: "url('/images/quests/profile-setup.png')",
    ["COMING_SOON"]: "url('/images/quests/double-dippin.png')",

    ["DONT_FALL_IN_ETH"]: "url('/images/quests/volcanologist.png')",
    ["DONT_FALL_IN_ROUND"]: "url('/images/quests/in-the-hot-seat.png')",
    ["DONT_FALL_IN_WIN"]: "url('/images/quests/lava-lover.png')",
    ["DONT_FALL_IN_WIN_STREAK"]: "url('/images/quests/magma-proof.png')",

    ["FLIPPER_ETH"]: "url('/images/quests/the-flipside.png')",
    ["FLIPPER_ROUND"]: "url('/images/quests/like-a-bad-penny.png ')",
    ["FLIPPER_WIN"]: "url('/images/quests/on-the-dime.png')",
    ["FLIPPER_WIN_STREAK"]: "url('/images/quests/weighted-coin.png')",

    ["LASER_BLAST_ETH"]: "url('/images/quests/independence-day.png')",
    ["LASER_BLAST_ROUND"]: "url('/images/quests/finish-the-fight.png')",

    ["QUANTUM_ETH"]: "url('/images/quests/the-scientist.png')",
    ["QUANTUM_WIN_STREAK"]: "url('/images/quests/schrodinger.png')",
    ["QUANTUM_WIN"]: "url('/images/quests/collapse.png')",
    ["QUANTUM_ROUND"]: "url('/images/quests/chain-reaction.png')",
  }[milestoneCode];

  return (
    <Box
      width={16}
      height={16}
      backgroundImage={imageUrl}
      backgroundSize="contain"
      backgroundRepeat="no-repeat"
      {...props}
    />
  );
};

interface MilestoneProps extends FlexProps {
  title: string;
  state: MilestoneState;
  milestoneLevels?: UserMilestoneLevel[];
  // @note there is a Claim Gems button rendered as a child, but it does not have an onClick handler. That click event is handled by the parent Milestone component
  onClick: FlexProps["onClick"];
  withRainbowBorder?: boolean;
  milestoneCode: MilestoneCode;
}

export const Milestone = ({
  milestoneCode,
  title,
  milestoneLevels = [],
  state,
  onClick,
  withRainbowBorder = false,
  ...props
}: MilestoneProps) => {
  const { t } = useTranslation();

  const currentLevel = getCurrentLevel(milestoneLevels);
  const { points, progress, goal } = currentLevel || ({ points: 0, progress: 0, goal: 1 } as const);

  // some special handling for Profile Setup milestone
  const isProfileSetup = milestoneCode === "PROFILE_SETUP";

  const boxShadowCard = useBoxShadowPreset("card");
  const boxShadowCardHover = useBoxShadowPreset("card-hover");

  const isMeasuredInWei = milestoneCode.endsWith("_ETH");
  const progressDisplay = isMeasuredInWei ? formatToSignificant(progress) : progress;
  const goalDisplay = isMeasuredInWei ? formatToSignificant(goal) : goal;

  const progressPercent = isMeasuredInWei
    ? divideWeiByWei(progress, goal) * 100
    : Math.floor((Number(progress) / Number(goal)) * 100);
  const progressBarValue = state === "wallet-disconnected" ? 0 : progressPercent;

  const getMilestoneLevelText = useGetMilestoneLevelText();
  const levelString = !currentLevel ? "" : getMilestoneLevelText(currentLevel).levelString;
  const rewardAmount = points ? formatNumberToLocale(points, 0, 0) : undefined;

  // derive styles from props
  const {
    bgGradient,
    bgColor,
    titleTextColor,
    rewardTextColor,
    isStepCountVisible,
    isProgressBarVisible,
    isLevelStringVisible,
  } = (() => ({
    bgGradient: state === "claimed" ? "" : "linear(to-t, ui-gradient-02-a, ui-gradient-02-b)",
    bgColor: state === "claimed" ? "transparent" : "ui-bg",
    titleTextColor: state === "claimed" ? "text-03" : "text-02",
    rewardTextColor: state === "claimed" ? "text-03" : "text-01",
    isStepCountVisible: ["ready-to-start", "in-progress"].includes(state) && (!!progress || !!goal),
    isProgressBarVisible:
      ["wallet-disconnected", "ready-to-start", "in-progress"].includes(state) || milestoneCode === "COMING_SOON",
    isLevelStringVisible:
      ["ready-to-start", "in-progress", "claimed"].includes(state) && !!levelString && !isProfileSetup,
  }))();

  const cardHoverSx = {
    cursor: "pointer",
    _hover: {
      background: "hover-ui",
      boxShadow: boxShadowCardHover,
      transform: "translateY(-2px)",
    },
    _active: {
      background: "onclick-ui",
      boxShadow: boxShadowCardHover,
      transform: "translateY(-2px)",
    },
  };

  const tagBorderColor = "green.600";
  const tagTextColor = "green.300";

  // containerProps and baseComponentProps are verbose, the only reason for these is to support the withRainbowBorder border option.
  // the rainbow boder is really a wrapper element with a gradient background and some padding, so the "baseElement" appears to have a rainbow border.
  const containerProps: FlexProps = {
    width: "100%",
    minWidth: "328px",
    maxWidth: "512px",
    boxShadow: boxShadowCard,
    transform: "translateY(0px)",
    transition: "all 200ms ease-out 0s",
    sx: !withRainbowBorder
      ? cardHoverSx
      : {
          cursor: "pointer",
          _hover: {
            boxShadow: boxShadowCardHover,
            transform: "translateY(-2px)",
          },
          _active: {
            boxShadow: boxShadowCardHover,
            transform: "translateY(-2px)",
          },
        },
  };

  const baseComponentProps = withRainbowBorder
    ? {
        border: "none",
        borderRadius: `${dialogRadius - 1}px`,
      }
    : {
        border: "1px solid",
        borderRadius: "dialog",
        borderColor: "border-01",
      };

  const baseComponent = (
    <Flex
      onClick={onClick}
      bgColor={bgColor}
      bgGradient={bgGradient}
      p={4}
      gap={4}
      alignItems="center"
      {...(!withRainbowBorder && containerProps)}
      {...baseComponentProps}
      {...props}
    >
      <MilestoneIcon milestoneCode={milestoneCode} flexGrow={0} flexShrink={0} />
      <Flex direction="column" width="100%" gap={1}>
        {/* Row 1 */}
        <Flex justifyContent="space-between" height="20px" gap={4}>
          <Text bold color={titleTextColor} minWidth={0} noOfLines={1}>
            {title}
          </Text>
          {!!rewardAmount && (
            <Flex gap={1} alignItems="center">
              <Text textStyle="helper" bold color={rewardTextColor}>
                {rewardAmount} Pts
              </Text>
            </Flex>
          )}
        </Flex>
        {/* Row 2 */}
        {state !== "ready-to-claim" && (
          <DescriptionText
            code={milestoneCode}
            textProps={{ textStyle: "detail", color: "text-03", noOfLines: 1, textAlign: "left" }}
          />
        )}

        {/* Row 3 */}
        <Flex direction="column" alignItems="flex-end" justifyContent="flex-end" flexGrow={1}>
          {isStepCountVisible && (
            <Flex width="100%" justifyContent="space-between" alignItems="center">
              <Box>
                {isLevelStringVisible && (
                  <Tag variant="outline" size="sm" borderColor={tagBorderColor}>
                    <TagLabel color={tagTextColor}>{levelString}</TagLabel>
                  </Tag>
                )}
              </Box>
              <Text textStyle="helper" color="text-03">{`${progressDisplay}/${goalDisplay}`}</Text>
            </Flex>
          )}
          {isProgressBarVisible && (
            <Progress
              height={1}
              value={progressBarValue}
              width="100%"
              // border left ensures the progress bar never looks completely empty
              borderLeft="8px solid"
              borderColor="interactive-03"
            />
          )}
          {state === "ready-to-claim" && !!currentLevel?.code && (
            <Flex alignItems="flex-end" height="56px">
              <Button size="sm">{t("Claim Points")}</Button>
            </Flex>
          )}
          {state === "claimed" && (
            <Flex gap={1} alignItems="center" color="link-01">
              <Text textStyle="helper" bold color="link-01">
                {t("Claimed")}
              </Text>
              <CheckmarkFilledIcon boxSize={4} />
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  );

  if (withRainbowBorder) {
    return (
      <Box bgGradient={gradients["gradient-fill-gem"]} borderRadius="dialog" padding="2px" {...containerProps}>
        {baseComponent}
      </Box>
    );
  }

  return baseComponent;
};
