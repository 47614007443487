import { ModalHeroHeader, WalletIcon } from "@looksrare/uikit";
import { useTranslation } from "next-i18next";

interface ClaimRakebackUnlockedHeaderProps {
  onClose: () => void;
}

export const ClaimRakebackUnlockedHeader = ({ onClose }: ClaimRakebackUnlockedHeaderProps) => {
  const { t } = useTranslation();

  return (
    <ModalHeroHeader
      icon={WalletIcon}
      title={t("Claim Rakeback")}
      label={t("Sign the transaction in your wallet to claim your rakeback.")}
      labelProps={{ textStyle: "body", color: "text-02" }}
      flexShrink={0}
      onClose={onClose}
      showCloseButton
    />
  );
};
