import { Button, ButtonProps } from "@looksrare/uikit";
import { addTokenToWallet, AddTokenToWalletParams, useAddressesByNetwork, useCurrentChainInfo } from "@looksrare/utils";

const AddTokenToWalletButton = ({
  tokenAddress,
  tokenSymbol,
  tokenImageSrc,
  tokenDecimals,
  ...props
}: ButtonProps & AddTokenToWalletParams) => {
  return (
    <Button onClick={() => addTokenToWallet({ tokenAddress, tokenSymbol, tokenImageSrc, tokenDecimals })} {...props} />
  );
};

export const AddYoloTokenToWalletButton = (props: ButtonProps) => {
  const addressesByNetwork = useAddressesByNetwork();
  const info = useCurrentChainInfo();
  return (
    <AddTokenToWalletButton
      tokenAddress={addressesByNetwork.YOLO}
      tokenImageSrc={`${info.appUrl}/images/tokens/YOLO.png`}
      {...props}
    />
  );
};

export const AddYoloVaultSharesTokenToWalletButton = (props: ButtonProps) => {
  const addressesByNetwork = useAddressesByNetwork();
  const info = useCurrentChainInfo();

  return (
    <AddTokenToWalletButton
      tokenAddress={addressesByNetwork.VAULT_SHARES_YYOLO_TOKEN}
      tokenImageSrc={`${info.appUrl}/images/tokens/yYOLO.png`}
      tokenDecimals={24}
      {...props}
    />
  );
};

export const AddEthVaultSharesTokenToWalletButton = (props: ButtonProps) => {
  const addressesByNetwork = useAddressesByNetwork();
  const info = useCurrentChainInfo();

  return (
    <AddTokenToWalletButton
      tokenAddress={addressesByNetwork.VAULT_SHARES_YETH_TOKEN}
      tokenImageSrc={`${info.appUrl}/images/tokens/yETH.png`}
      tokenDecimals={24}
      {...props}
    />
  );
};
