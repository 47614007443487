import { useTranslation } from "next-i18next";
import { Box, Flex, Stack } from "@chakra-ui/react";
import { Text } from "@looksrare/uikit";

export const AboutUs = () => {
  const { t } = useTranslation();

  return (
    <Box px={{ base: 4, lg: 12 }}>
      <Flex
        bg="interactive-02"
        flexDirection={{ base: "column", md: "row" }}
        alignItems={{ base: "center", md: "stretch" }}
        gap={{ base: 1, xl: 18 }}
        borderRadius="container"
        height="100%"
        position="relative"
        overflow="hidden"
        py={{ base: 6, md: 0 }}
        px={{ base: 4, md: 0 }}
      >
        <Stack spacing={{ base: 4, xl: 5 }} py={{ md: 12 }} px={{ md: 9 }} width="100%">
          <Text textStyle="display-03" bold maxWidth="440px">
            {t("The Home of Degen Gaming")}
          </Text>
          <Text color="text-02" maxWidth="720px">
            {t(
              "The only venue for serious degens seeking serious rewards. Built by a veteran team with a track record of delivering provably fair, fun on-chain games and innovative reward systems."
            )}
          </Text>
        </Stack>
        <Box
          width={{ base: "301px", md: "270px", xl: "234px" }}
          height={{ base: "213px", md: "253px", xl: "219px" }}
          backgroundImage="url('/images/yolo-games-coin.png')"
          backgroundRepeat="no-repeat"
          backgroundPosition="center"
          backgroundSize="cover"
        />
      </Flex>
    </Box>
  );
};
