import type { FC, PropsWithChildren } from "react";
import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Box, Flex, Link } from "@chakra-ui/react";
import { CloudinaryImage, Text, TextProps } from "@looksrare/uikit";
import { Trans, useTranslation } from "next-i18next";

// @todo-yg Global fee constant
const fee = "1%";

const AccordionText: FC<PropsWithChildren<TextProps>> = (props) => (
  <Text color="text-03" textStyle="detail" {...props} />
);

const dotSx = { ":before": { content: `" \u2022 "`, fontWeight: "bold", marginRight: 0.5, color: "text-03" } };

export const PtbFaqView = () => {
  const { t } = useTranslation();

  return (
    <Box width="100%" maxWidth="768px" mx="auto" pt={16}>
      <Flex alignItems="start" mb={8}>
        <Box flex={1}>
          <Text as="h1" bold textStyle="display-01" textTransform="uppercase" mb={4}>
            {t("ptb::Enter the Caves...")}
          </Text>
          <Text bold>{t("ptb::Take a seat.")}</Text>
          <Text bold>{t("ptb::Grab your stick.")}</Text>{" "}
          <Text bold mb={4}>
            {t("ptb::And wait for your turn to Poke the Bear.")}
          </Text>
          <Text color="text-03">{t("ptb::The loser gets mauled — but the winners take it all.")}</Text>
        </Box>
        <CloudinaryImage src="/images/poke-the-bear/logo.png" alt="Poke The Bear" width={128} height={144} />
      </Flex>
      <Accordion allowMultiple defaultIndex={[0, 1]}>
        <AccordionItem border={0}>
          <AccordionButton>{t("How does it work?")}</AccordionButton>
          <AccordionPanel>
            <AccordionText mb={4}>
              {t(
                "ptb::After every seat has been filled, the round starts, and players take turns in random order to poke."
              )}
            </AccordionText>
            <AccordionText>
              {t(
                "ptb::Every fifteen seconds or less, a different player will poke the bear. One of two things will happen:"
              )}
            </AccordionText>
            <AccordionText sx={dotSx}>
              {t("ptb::He'll keep sleeping, and you can breathe a sigh of relief")}
            </AccordionText>
            <AccordionText sx={dotSx} mb={4}>
              {t("ptb::He'll wake up and devour you. GG.")}
            </AccordionText>{" "}
            <AccordionText mb={4}>
              {t("ptb::When a player has been attacked by the bear, the round ends, and the next round starts.")}
            </AccordionText>
            <Trans i18nKey="ptbFaqFeeText" ns="ptb">
              <AccordionText>
                The victim&apos;s entry fee is split among the survivors — minus a <>{{ fee }}</> fee that will{" "}
                <Text as="span" color="text-error">
                  [CONTENT REDACTED]
                </Text>
                in future.
              </AccordionText>
            </Trans>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionButton>{t("Is it provably fair?")}</AccordionButton>
          <AccordionPanel>
            <AccordionText mb={4}>{t("ptb::You know it.")}</AccordionText>
            <Trans i18nKey="ptbFaqRandomnessText" ns="ptb">
              <AccordionText>
                PTB uses{" "}
                <Link href="https://www.gelato.network" isExternal color="link-01">
                  Gelato
                </Link>{" "}
                for provable randomness to bring you a game with cryptographic fairness baked in.
              </AccordionText>
            </Trans>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
};
