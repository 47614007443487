import { useMemo, type FC } from "react";
import { useTranslation } from "next-i18next";
import { getCurrencyDisplayDecimals, usePreviousValue } from "@looksrare/utils";
import { type IconProps } from "@looksrare/uikit";
import type { LiquidityPoolMetricsFilterInput } from "@looksrare/yolo-games-gql-typegen";
import { useGlobalLiqudityPoolMetrics } from "../../hooks";
import { getPercentageChange } from "../../utils";
import { ChartBox } from "./ChartBox";
import { PositionPnLChart } from "./PositionPnLChart";

interface CumulativePnLProps {
  filters: LiquidityPoolMetricsFilterInput;
  poolIcon: FC<IconProps>;
  currencySymbol: string;
}

export const CumulativePnL = ({ filters, poolIcon: PoolIcon, currencySymbol }: CumulativePnLProps) => {
  const { t } = useTranslation();
  const poolMetricsQuery = useGlobalLiqudityPoolMetrics({ filters });
  const pnlMetrics = poolMetricsQuery.data?.find((metric) => metric.metricType === "LP_PNL");
  const prevPnlMetrics = usePreviousValue(pnlMetrics);
  const currentPnlMetrics = pnlMetrics || prevPnlMetrics;
  const displayDecimals = getCurrencyDisplayDecimals(currentPnlMetrics?.currency.symbol ?? "ETH");
  const intervalPctChange = getPercentageChange(
    currentPnlMetrics?.currentCumulativeTotal,
    currentPnlMetrics?.previousCumulativeTotal
  );

  const normalizedPnlMetrics = useMemo(() => {
    if (!currentPnlMetrics || currentPnlMetrics.data.length === 0) {
      return;
    }

    const zeroPoint = currentPnlMetrics.data[0][1];

    return currentPnlMetrics.data.map((data) => [data[0], data[1] - zeroPoint]);
  }, [currentPnlMetrics]);

  return (
    <ChartBox
      isLoading={poolMetricsQuery.isFetching}
      hasData={!!normalizedPnlMetrics && normalizedPnlMetrics.length > 0}
      chartTitle={t("liq::Cumulative PNL")}
      timeInterval={filters.timeInterval}
      currencySymbol={currencySymbol}
      currencyIcon={PoolIcon}
      intervalPctChange={intervalPctChange}
      intervalTotal={currentPnlMetrics?.intervalTotal}
      valueVumUsd={0}
      enableRelativeText
    >
      <PositionPnLChart
        chartData={normalizedPnlMetrics || []}
        timeInterval={filters.timeInterval}
        displayDecimals={displayDecimals}
      />
    </ChartBox>
  );
};
