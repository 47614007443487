import { AspectRatio, Box, Stack } from "@chakra-ui/react";
import { CloudinaryImage, NextLink, Text } from "@looksrare/uikit";
import { shadows } from "@/theme/shadows";
import { HeroCarouselSlideProps } from "./HeroCarouselSlide";

export const HeroCarouselSlideMobile = ({
  title,
  description,
  href,
  imageSrc,
  blurImageSrc,
  ...props
}: HeroCarouselSlideProps) => {
  return (
    <Stack as={NextLink} href={href} spacing={0} borderRadius="dialog" overflow="hidden" {...props}>
      <AspectRatio ratio={4 / 3} maxHeight="332px">
        <CloudinaryImage src={imageSrc} alt="" layout="fill" sizes="442px" objectFit="cover" />
      </AspectRatio>

      <Box position="relative" height="176px" backgroundColor="ui-01">
        <CloudinaryImage
          src={imageSrc}
          alt=""
          layout="fill"
          sizes="442px"
          objectFit="cover"
          placeholder={blurImageSrc ? "blur" : "empty"}
          blurDataURL={blurImageSrc}
        />

        <Stack spacing={4} py={6} px={4} height="100%" backdropFilter="blur(128px)">
          <Text textStyle="heading-03" color="white" textShadow={shadows["shadow-text-on-image"]} bold>
            {title}
          </Text>

          <Text
            textStyle="detail"
            color="gray.200"
            textShadow={shadows["shadow-text-on-image"]}
            whiteSpace="pre-wrap"
            textOverflow="ellipsis"
            overflow="hidden"
          >
            {description}
          </Text>
        </Stack>
      </Box>
    </Stack>
  );
};
