import { ModalBody } from "@chakra-ui/react";
import { ModalHeaderLegacy } from "@looksrare/uikit";
import { useTranslation } from "next-i18next";
import { GroupedRakebacks } from "@looksrare/yolo-games-gql-typegen";
import { PendingRakebacks } from "./PendingRakebacks";

interface PendingRakebacksModalBodyProps {
  onClose: () => void;
  rakebacks?: GroupedRakebacks;
}

export const PendingRakebacksModalBody = ({ onClose, rakebacks }: PendingRakebacksModalBodyProps) => {
  const { t } = useTranslation();

  return (
    <>
      <ModalHeaderLegacy title={t("Rakeback Details")} onClose={onClose} showCloseButton />
      <ModalBody>
        <PendingRakebacks rakebacks={rakebacks} />
      </ModalBody>
    </>
  );
};
