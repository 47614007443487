import type { Address } from "viem";
import { type Pagination, graphql } from "@looksrare/utils";
import {
  graphql as generatedGql,
  type LiquidityPoolUserMetricsFilterInput,
  type QueryLiquidityPoolsOverviewArgs,
  type QueryLiquidityPoolMetricsArgs,
  type UserLiquidityPoolActionPreviewArgs,
} from "@looksrare/yolo-games-gql-typegen";

export const getLiquidityPools = async (user?: Address) => {
  const liquidityPoolsQuery = generatedGql(/* GraphQL */ `
    query LiquidityPools($user: Address) {
      liquidityPools(user: $user) {
        contract
        isActive
        userPoolPct
        userSharesValueVum
        assetsBalanceVum
        pendingDepositsAmountVum
        liquidityPoolConfig {
          finalizationIncentiveEthWei
          depositFeeBps
          minDepositVum
          maxDepositVum
          timelockDelaySecs
          assetsLimitVum
        }
        liquidityPoolSeason {
          dailyDroplets
          currentMultiplier
          nextIncreaseAt
          nextMultiplierIncrease
        }
        currency {
          address
          name
          decimals
          symbol
        }
      }
    }
  `);

  const res = await graphql({ query: liquidityPoolsQuery, params: { user } });
  return res.liquidityPools;
};

export const getLiquidityPoolTotalDroplets = async (address: Address) => {
  const query = generatedGql(/* GraphQL */ `
    query LiquidityPoolTotalDroplets($address: Address!) {
      user(address: $address) {
        liquidityPoolSeasonDroplets
      }
    }
  `);

  const res = await graphql({ query, params: { address } });
  return res.user?.liquidityPoolSeasonDroplets;
};

export const getUserLiquidityPendingActions = async (address: Address) => {
  const query = generatedGql(/* GraphQL */ `
    query LiquidyPoolPendingActions($address: Address!) {
      user(address: $address) {
        liquidityPoolPendingActions {
          contract
          type
          currency {
            address
            name
            decimals
            symbol
          }
          expectedAssetsAmountVum
          finalizationIncentiveEthWei
          protectedUntil
          startedAt
          transactionHash
          unlocksAt
        }
      }
    }
  `);

  const res = await graphql({ query, params: { address } });
  return !!res.user ? res.user.liquidityPoolPendingActions : [];
};

interface UserLiqudityEventsArgs {
  pagination: Pagination;
  address?: Address;
}

export const getUserLiqudityEvents = async ({ address, pagination }: UserLiqudityEventsArgs) => {
  const { first = 10, cursor } = pagination;
  const query = generatedGql(/* GraphQL */ `
    query UserLiquidityPoolEvents($address: Address!, $pagination: PaginationInput!) {
      user(address: $address) {
        liquidityPoolEvents(pagination: $pagination) {
          assetsAmountUsd
          assetsAmountVum
          contract
          createdAt
          isTransfer
          transactionHash
          type
          currency {
            address
            name
            decimals
            symbol
          }
        }
      }
    }
  `);

  const res = await graphql({ query, params: { address, pagination: { first, cursor } } });
  return !!res.user ? res.user.liquidityPoolEvents : [];
};

export interface UserLiqudityPoolMetricsArgs {
  address?: Address;
  filters: LiquidityPoolUserMetricsFilterInput;
}

export const getUserLiqudityPoolMetrics = async ({ address, filters }: UserLiqudityPoolMetricsArgs) => {
  const query = generatedGql(/* GraphQL */ `
    query UserLiquidityPoolMetrics($address: Address!, $filters: LiquidityPoolUserMetricsFilterInput!) {
      user(address: $address) {
        liquidityPoolMetrics(filters: $filters) {
          data
          dataGranularity
          gameContract
          currentCumulativeTotal
          previousCumulativeTotal
          intervalTotal
          lastAlignedAt
          metricType
          currency {
            address
            name
            decimals
            symbol
          }
        }
      }
    }
  `);

  const res = await graphql({ query, params: { address, filters } });
  return !!res.user ? res.user.liquidityPoolMetrics : [];
};

export const getGlobalLiqudityPoolMetrics = async ({ filters }: QueryLiquidityPoolMetricsArgs) => {
  const query = generatedGql(/* GraphQL */ `
    query LiquidityPoolMetrics($filters: LiquidityPoolMetricsFilterInput!) {
      liquidityPoolMetrics(filters: $filters) {
        data
        dataGranularity
        gameContract
        intervalTotal
        currentCumulativeTotal
        previousCumulativeTotal
        lastAlignedAt
        metricType
        currency {
          address
          name
          decimals
          symbol
        }
      }
    }
  `);

  const res = await graphql({ query, params: { filters } });
  return res.liquidityPoolMetrics;
};

export const getLiquidityPoolsGameOverview = async ({ filters }: QueryLiquidityPoolsOverviewArgs) => {
  const query = generatedGql(/* GraphQL */ `
    query LiquidityPoolsOverview($filters: LiquidityPoolOverviewFilterInput!) {
      liquidityPoolsOverview(filters: $filters) {
        gameContract
        totalPlayedRoundsEth
        previousTotalPlayedRoundsEth
        totalVolumeEth
        previousTotalVolumeEth
        totalFeesEth
        previousTotalFeesEth
        totalPlayedRoundsYolo
        previousTotalPlayedRoundsYolo
        totalVolumeYolo
        previousTotalVolumeYolo
        totalFeesYolo
        previousTotalFeesYolo
      }
    }
  `);

  const res = await graphql({ query, params: { filters } });
  return res.liquidityPoolsOverview;
};

export const getUserLiquidityPoolActionPreview = async ({
  address,
  contract,
  actionType,
  assetsAmountVum,
}: UserLiquidityPoolActionPreviewArgs & { address: Address }) => {
  const query = generatedGql(/* GraphQL */ `
    query UserLiquidityPoolActionPreview(
      $address: Address!
      $contract: LiquidityPoolContract!
      $actionType: LiquidityPoolEventType!
      $assetsAmountVum: BigNumber!
    ) {
      user(address: $address) {
        liquidityPoolActionPreview(contract: $contract, actionType: $actionType, assetsAmountVum: $assetsAmountVum) {
          dailyDroplets
          multiplier
        }
      }
    }
  `);

  const res = await graphql({ query, params: { address, contract, actionType, assetsAmountVum } });
  return res.user?.liquidityPoolActionPreview;
};
