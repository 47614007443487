import { Flex } from "@chakra-ui/react";
import { CheckmarkFilledIcon, type ModalProps, Text, Button, MetaMaskIcon } from "@looksrare/uikit";
import { useTranslation } from "next-i18next";
import { formatToSignificant, type BigIntish } from "@looksrare/utils";
import type { Currency } from "@looksrare/yolo-games-gql-typegen/src/generated/gql/graphql";
import {
  AddEthVaultSharesTokenToWalletButton,
  AddYoloVaultSharesTokenToWalletButton,
} from "@/components/Buttons/AddTokenToWalletButton";

interface ConfirmDepositViewProps {
  onClose: ModalProps["onClose"];
  finalizationIncentiveEthWei: BigIntish;
  currency: Currency;
}

export const ConfirmDepositView = ({ onClose, finalizationIncentiveEthWei, currency }: ConfirmDepositViewProps) => {
  const { t } = useTranslation();
  return (
    <Flex flexDirection="column" justifyContent="center" alignItems="center">
      <CheckmarkFilledIcon color="link-01" boxSize={16} mb={4} />
      <Text as="h1" textStyle="heading-03" bold mb={2}>
        {t("liq::Funds Added")}
      </Text>
      <Text as="p" mb={8} textAlign="center">
        {t("You've added your funds and reclaimed your temporary finalization fee of {{feeDisplay}}.", {
          feeDisplay: `${formatToSignificant(finalizationIncentiveEthWei, 6, { decimals: currency.decimals })} ETH`,
        })}
      </Text>
      {currency.symbol === "ETH" ? (
        <AddEthVaultSharesTokenToWalletButton mb={2} width="100%" colorScheme="secondary" gap={2}>
          <MetaMaskIcon />
          {t("Add {{token}} to MetaMask", { token: "yETH" })}
        </AddEthVaultSharesTokenToWalletButton>
      ) : (
        <AddYoloVaultSharesTokenToWalletButton mb={2} width="100%" colorScheme="secondary" gap={2}>
          <MetaMaskIcon />
          {t("Add {{token}} to MetaMask", { token: "yYOLO" })}
        </AddYoloVaultSharesTokenToWalletButton>
      )}
      <Button width="100%" onClick={onClose}>
        {t("Nice")}
      </Button>
    </Flex>
  );
};
