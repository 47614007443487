import { Box } from "@chakra-ui/react";
import { type BigIntish } from "@looksrare/utils";
import type { Currency, LiquidityPoolContract } from "@looksrare/yolo-games-gql-typegen/src/generated/gql/graphql";
import { ApproveWithdrawTransferStep } from "./ApproveWithdrawTransferStep";
import { InitiateWithdrawalStep } from "./InitiateWithdrawalStep";
import { FinalizeWithdrawalStep } from "./FinalizeWithdrawalStep";

interface WithdrawalConfirmationViewProps {
  isEthPool: boolean;
  contract: LiquidityPoolContract;
  currency: Currency;
  timelockDelaySecs: number;
  onFinalizeSuccess: () => void;
  finalizationIncentiveEthWei: BigIntish;
}

export const WithdrawalConfirmationView = ({
  isEthPool,
  contract,
  currency,
  timelockDelaySecs,
  onFinalizeSuccess,
  finalizationIncentiveEthWei,
}: WithdrawalConfirmationViewProps) => {
  return (
    <Box>
      <ApproveWithdrawTransferStep contract={contract} currency={currency} />
      <InitiateWithdrawalStep
        isEthPool={isEthPool}
        contract={contract}
        timelockDelaySecs={timelockDelaySecs}
        currency={currency}
        finalizationIncentiveEthWei={finalizationIncentiveEthWei}
      />
      <FinalizeWithdrawalStep
        contract={contract}
        currency={currency}
        finalizationIncentiveEthWei={finalizationIncentiveEthWei}
        onFinalizeSuccess={onFinalizeSuccess}
      />
    </Box>
  );
};
