import { ModalBody } from "@chakra-ui/react";
import { Button, Modal, ModalHeroHeader, OfferFillIcon } from "@looksrare/uikit";
import { useTranslation } from "next-i18next";

interface TakeABreakConfirmModalProps {
  isOpen: boolean;
  confirmLabel: string;
  onClose: () => void;
  onConfirm: () => void;
}

export const TakeABreakConfirmModal = ({ isOpen, confirmLabel, onClose, onConfirm }: TakeABreakConfirmModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalHeroHeader
        icon={OfferFillIcon}
        title={t("Block Wallet")}
        label={t(
          "This will block the currently connected wallet from playing YOLO Games for the timeframe you’ve selected, and cannot be undone."
        )}
        onClose={onClose}
      />

      <ModalBody>
        <Button onClick={onConfirm} variant="outline" colorScheme="red" width="100%">
          {confirmLabel}
        </Button>
      </ModalBody>
    </Modal>
  );
};
