import { useCallback } from "react";
import { useTranslation } from "next-i18next";
import { NoPublicClientError, formatToSignificant, type BigIntish } from "@looksrare/utils";
import { usePublicClient, useWalletClient } from "wagmi";
import { TransferManagerAbi } from "@looksrare/config";
import { getDefaultChain } from "@/utils";
import { gameAddresses } from "@/config";

interface InputValidationArgs {
  inputValueVum: bigint;
  minVum?: BigIntish;
  maxVum?: BigIntish;
  assetsBalanceVum?: BigIntish;
  pendingDepositsAmountVum?: BigIntish;
  assetsLimitVum?: BigIntish;
  tokenBalanceVum: bigint;
  tokenDecimals: number;
  tokenSymbol: string;
  validateTokenBalance?: boolean;
  displayDecimals?: number;
}

export const useInputValidation = ({
  inputValueVum,
  minVum = 0n,
  maxVum = 0n,
  assetsBalanceVum = 0n,
  pendingDepositsAmountVum = 0n,
  assetsLimitVum = 0n,
  tokenBalanceVum,
  tokenDecimals,
  tokenSymbol,
  validateTokenBalance = true,
  displayDecimals = 4,
}: InputValidationArgs) => {
  const { t } = useTranslation();

  const minDeposit = BigInt(minVum);
  const maxDeposit = BigInt(maxVum);
  const assetBalance = BigInt(assetsBalanceVum);
  const pendingDepositsAmount = BigInt(pendingDepositsAmountVum);
  const assetsLimit = BigInt(assetsLimitVum);
  const isValidMinDeposit = minDeposit > 0n ? inputValueVum >= minDeposit : true;
  const isValidMaxDeposit = maxDeposit > 0n ? inputValueVum <= maxDeposit : true;
  const hasReachedMaxPoolAmount =
    assetsLimit > 0n ? assetBalance + pendingDepositsAmount + inputValueVum >= assetsLimit : false;
  const hasSufficientFunds = validateTokenBalance ? inputValueVum <= tokenBalanceVum : true;
  const isValidInput = inputValueVum > 0n && isValidMinDeposit && isValidMaxDeposit && hasSufficientFunds;

  const remainingDepositableBalance = assetsLimit - (assetBalance + pendingDepositsAmount);

  const warningText = (() => {
    if (inputValueVum === 0n) {
      return;
    }
    if (!hasSufficientFunds) {
      return t("Insufficient Balance");
    }
    if (!isValidMinDeposit) {
      return t("Minimum amount is {{amount}} {{currency}}", {
        amount: formatToSignificant(minDeposit, displayDecimals, { decimals: tokenDecimals }),
        currency: tokenSymbol,
      });
    }
    if (!isValidMaxDeposit) {
      return t("Maximum amount is {{amount}} {{currency}}", {
        amount: formatToSignificant(maxDeposit, displayDecimals, { decimals: tokenDecimals }),
        currency: tokenSymbol,
      });
    }
    if (hasReachedMaxPoolAmount) {
      return t("liq::Exceeds current max deposit: {{maxDeposit}} {{symbol}}", {
        maxDeposit: formatToSignificant(remainingDepositableBalance, displayDecimals, { decimals: tokenDecimals }),
        symbol: tokenSymbol,
      });
    }
    return null;
  })();

  return { isValidInput, warningText };
};

interface GrantApprovalArgs {
  onBeforeSend: () => void;
  onSuccess: () => void;
}

export const useGrantApproval = () => {
  const { data: walletClient } = useWalletClient();
  const publicClient = usePublicClient();

  return useCallback(
    async ({ onBeforeSend, onSuccess }: GrantApprovalArgs) => {
      if (!walletClient) {
        throw Error("No wallet client found");
      }
      if (!publicClient) {
        throw NoPublicClientError;
      }

      onBeforeSend();

      const [account] = await walletClient.getAddresses();
      const chainId = getDefaultChain();
      const addresses = gameAddresses[chainId];

      // Check if transfer manager has already been approved
      const hasApproved = await publicClient.readContract({
        address: addresses.LIQUIDITY_TRANSFER_MANAGER,
        abi: TransferManagerAbi,
        functionName: "hasUserApprovedOperator",
        args: [account, addresses.LIQUIDITY_POOL_ROUTER],
      });

      if (hasApproved) {
        onSuccess();
        return;
      }

      // Approval
      const { request } = await publicClient.simulateContract({
        address: addresses.LIQUIDITY_TRANSFER_MANAGER,
        abi: TransferManagerAbi,
        functionName: "grantApprovals",
        args: [[addresses.LIQUIDITY_POOL_ROUTER]],
        account,
      });
      const hash = await walletClient.writeContract(request);
      await publicClient.waitForTransactionReceipt({ hash });
      onSuccess();
    },
    [publicClient, walletClient]
  );
};
