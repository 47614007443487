import { useState } from "react";
import { useAccount } from "wagmi";
import { useTranslation } from "next-i18next";
import { Box, Flex, Grid, GridItem, HStack, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { Button, CheckmarkFilledIcon, Chevron, EthTokenIcon, Text, currencyTokenIconMap } from "@looksrare/uikit";
import { getCurrencyDisplayDecimals, usePreviousValue } from "@looksrare/utils";
import type {
  LiquidityPoolContract,
  LiquidityPoolInterval,
  LiquidityPoolUserMetricsFilterInput,
} from "@looksrare/yolo-games-gql-typegen";
import { lpConfig } from "@/config";
import { useLiquidityPools, useSetInitialCurrentPool, useUserLiquidityPoolMetrics } from "../hooks";
import { getPercentageChange } from "../utils";
import { ChartBox, LiquidityPoolValueChart, PositionPnLChart } from "./Charts";
import { TimeIntervalSelectMenu } from "./TimeIntervalSelectMenu";

export const LiquidityPerformance = () => {
  const { address } = useAccount();
  const { t } = useTranslation();

  // Filters
  const [filters, setFilters] = useState<LiquidityPoolUserMetricsFilterInput>({
    contract: "GAMES_ETH_LP_V1_BLAST",
    timeInterval: "_1D",
  });

  // Queries
  const poolMetricsQuery = useUserLiquidityPoolMetrics({ address, filters });
  const liquidityPoolsQuery = useLiquidityPools({ address });

  // Currently selected pool data
  const currentPool = liquidityPoolsQuery.data?.find((pool) => pool.contract === filters.contract);
  const symbol = currentPool?.currency.symbol ?? "ETH";
  const poolName = !!currentPool ? t("liq::{{token}} Pool", { token: symbol }) : "—";
  const PoolIcon = (() => {
    if (!currentPool) {
      return EthTokenIcon;
    }
    return currencyTokenIconMap[symbol];
  })();

  /**
   * When liquidity pools data is loaded, if the user has no balance in the `currentPool` but does have a balance in the next pool,
   * we should update the `currentPool` to the next pool.
   */
  useSetInitialCurrentPool(setFilters);

  // Chart data
  // Note: we keep value of the old data so the chart doesn't unmount when loading new data
  const positionMetrics = poolMetricsQuery.data?.find((metric) => metric.metricType === "USER_LP_POSITION");
  const prevPositionMetrics = usePreviousValue(positionMetrics);
  const currentPositionMetricData = positionMetrics || prevPositionMetrics;
  const positionPctChange = getPercentageChange(
    currentPositionMetricData?.currentCumulativeTotal,
    currentPositionMetricData?.previousCumulativeTotal
  );

  const pnlMetrics = poolMetricsQuery.data?.find((metric) => metric.metricType === "USER_LP_PNL");
  const prevPnlMetrics = usePreviousValue(pnlMetrics);
  const currentPnlMetrics = pnlMetrics || prevPnlMetrics;
  const displayDecimals = getCurrencyDisplayDecimals(currentPnlMetrics?.currency.symbol ?? "ETH");
  const pnlPctChange = getPercentageChange(
    currentPnlMetrics?.currentCumulativeTotal,
    currentPnlMetrics?.previousCumulativeTotal
  );

  const handleUpdateTimeInterval = (newTimeInterval: LiquidityPoolInterval) => {
    setFilters((prevState) => ({
      ...prevState,
      timeInterval: newTimeInterval,
    }));
  };

  const handleUpdatePool = (newContract: LiquidityPoolContract) => {
    setFilters((prevState) => ({
      ...prevState,
      contract: newContract,
    }));
  };

  return (
    <Box mb={8}>
      <Flex
        flexDirection={{ base: "column", md: "row" }}
        alignItems={{ base: "start", md: "center" }}
        justifyContent={{ base: "start", md: "space-between" }}
        mb={4}
      >
        <Text textStyle="heading-03" bold mb={{ base: 2, md: 0 }}>
          {t("liq::Your Liquidity Performance")}
        </Text>
        <HStack>
          <Menu>
            <MenuButton
              as={Button}
              size="xs"
              colorScheme="secondary"
              isLoading={liquidityPoolsQuery.isLoading}
              leftIcon={<PoolIcon />}
            >
              {poolName}
              <Chevron direction="down" boxSize={5} ml={2} color="text-03" />
            </MenuButton>
            <MenuList>
              {liquidityPoolsQuery.data?.map(({ contract, currency }) => {
                const Icon = currencyTokenIconMap[currency.symbol];

                return (
                  <MenuItem key={contract} onClick={() => handleUpdatePool(contract)}>
                    <Icon boxSize={5} />
                    <Text flex={1} px={4} textStyle="detail">
                      {t("liq::{{token}} Pool", { token: currency.symbol })}
                    </Text>
                    {filters.contract === contract && <CheckmarkFilledIcon color="text-03" />}
                  </MenuItem>
                );
              })}
            </MenuList>
          </Menu>
          <TimeIntervalSelectMenu
            currentTimeInterval={filters.timeInterval}
            onSelectTimeInterval={handleUpdateTimeInterval}
          />
        </HStack>
      </Flex>
      <Grid gridGap={4} gridTemplateColumns={{ md: "repeat(2, minmax(0, 1fr))" }} minHeight="230px">
        <GridItem>
          <ChartBox
            isLoading={poolMetricsQuery.isFetching}
            hasData={!!currentPositionMetricData && currentPositionMetricData.data.length > 0}
            chartTitle={t("liq::Position's Value")}
            timeInterval={filters.timeInterval}
            currencySymbol={symbol}
            currencyIcon={PoolIcon}
            intervalPctChange={positionPctChange}
            intervalTotal={currentPositionMetricData?.intervalTotal}
            valueVumUsd={0}
          >
            <LiquidityPoolValueChart
              color={lpConfig[filters.contract].textColor}
              chartData={currentPositionMetricData?.data || []}
              timeInterval={filters.timeInterval}
            />
          </ChartBox>
        </GridItem>
        <GridItem>
          <ChartBox
            isLoading={poolMetricsQuery.isFetching}
            hasData={!!currentPnlMetrics && currentPnlMetrics.data.length > 0}
            chartTitle={t("liq::Position PNL")}
            timeInterval={filters.timeInterval}
            currencySymbol={symbol}
            currencyIcon={PoolIcon}
            intervalPctChange={pnlPctChange}
            intervalTotal={currentPnlMetrics?.intervalTotal}
            valueVumUsd={0}
            enableRelativeText
          >
            <PositionPnLChart
              chartData={currentPnlMetrics?.data || []}
              displayDecimals={displayDecimals}
              timeInterval={filters.timeInterval}
            />
          </ChartBox>
        </GridItem>
      </Grid>
    </Box>
  );
};
