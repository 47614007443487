import { useEffect, useState } from "react";
import { usePublicClient, useWalletClient } from "wagmi";
import { useTranslation } from "next-i18next";
import { useElapsedTime } from "use-elapsed-time";
import { Box } from "@chakra-ui/react";
import { Button, Text, TransactionStep, TransactionStepRow } from "@looksrare/uikit";
import { LiquidityPoolAbi, LiquidityPoolRouterAbi } from "@looksrare/config";
import {
  type BigIntish,
  NoPublicClientError,
  sleep,
  toDecimals,
  useHandleModalStep,
  formatToSignificant,
} from "@looksrare/utils";
import type { Currency, LiquidityPoolContract } from "@looksrare/yolo-games-gql-typegen/src/generated/gql/graphql";
import { getDefaultChain, getLiquidityPoolAddressFromContract } from "@/utils";
import { gameAddresses } from "@/config";
import { DepositWithdrawStep } from "../types";
import { useDepositWithdrawStore } from "../store";
import { FeeText } from "../shared";

interface InitiateWithdrawalStepProps {
  isEthPool: boolean;
  timelockDelaySecs: number;
  contract: LiquidityPoolContract;
  currency: Currency;
  finalizationIncentiveEthWei: BigIntish;
}

export const InitiateWithdrawalStep = ({
  isEthPool,
  timelockDelaySecs,
  currency,
  contract,
  finalizationIncentiveEthWei,
}: InitiateWithdrawalStepProps) => {
  const { t } = useTranslation();
  const [
    step,
    inputValue,
    stepStatuses,
    startInitiateWithdraw,
    startWithdrawTimelock,
    startWithdrawFinalization,
    setFailStatus,
  ] = useDepositWithdrawStore((state) => [
    state.step,
    state.inputValue,
    state.stepStatus,
    state.startInitiateWithdraw,
    state.startWithdrawalTimelock,
    state.startWithdrawalFinalization,
    state.setFailStatus,
  ]);
  const [isCountingDown, setIsCountingDown] = useState(false);
  const { elapsedTime } = useElapsedTime({
    duration: timelockDelaySecs,
    isPlaying: isCountingDown,
    updateInterval: 1,
    onComplete: () => {
      setIsCountingDown(false);
      startWithdrawFinalization();
    },
  });
  const timeLockRemaining = timelockDelaySecs - elapsedTime;
  const { data: walletClient } = useWalletClient();
  const publicClient = usePublicClient();
  const feeDisplay = `${formatToSignificant(finalizationIncentiveEthWei, 6)} ETH`;

  const initiateWithdraw = useHandleModalStep({
    onSubmit: async () => {
      if (!walletClient) {
        throw Error("No wallet client found");
      }
      if (!publicClient) {
        throw NoPublicClientError;
      }
      startInitiateWithdraw();

      const [account] = await walletClient.getAddresses();
      const inputVum = toDecimals(inputValue, currency.decimals);
      const chainId = getDefaultChain();
      const addresses = gameAddresses[chainId];

      const shares = await publicClient.readContract({
        address: getLiquidityPoolAddressFromContract(contract),
        abi: LiquidityPoolAbi,
        functionName: "convertToShares",
        args: [inputVum],
      });
      const { request } = await publicClient.simulateContract({
        address: addresses.LIQUIDITY_POOL_ROUTER,
        abi: LiquidityPoolRouterAbi,
        functionName: "redeem",
        args: [isEthPool ? addresses.WETH : currency.address, shares],
        value: BigInt(finalizationIncentiveEthWei),
        account,
      });
      const hash = await walletClient.writeContract(request);
      await publicClient.waitForTransactionReceipt({ hash });

      // Wait a little bit more just in case
      await sleep(2_000);

      startWithdrawTimelock();
      setIsCountingDown(true);
    },
    onError: () => {
      setFailStatus(DepositWithdrawStep.INITIATE_WITHDRAWAL);
    },
  });

  const handleRetryInitiateWithdraw = () => {
    initiateWithdraw.handleSubmit();
  };

  useEffect(() => {
    if (step === DepositWithdrawStep.INITIATE_WITHDRAWAL) {
      initiateWithdraw.handleSubmit({ callOnlyOnce: true });
    }
  }, [step, initiateWithdraw, inputValue]);

  return (
    <TransactionStep
      status={stepStatuses[DepositWithdrawStep.INITIATE_WITHDRAWAL].timingStatus}
      title={t("liq::Initiate Withdrawal")}
      width="100%"
      spacing={0}
      bodyWrapperProps={{ pl: 0 }}
      mb={4}
      collapse={stepStatuses[DepositWithdrawStep.INITIATE_WITHDRAWAL].timingStatus !== "current"}
    >
      <FeeText feeDisplay={feeDisplay} />
      <Box py={6} px={4} bg="ui-01" borderRadius="container">
        <Text textStyle="detail" mb={4}>
          {t("Waiting for you to confirm in wallet")}
        </Text>
        <TransactionStepRow
          status={stepStatuses[DepositWithdrawStep.INITIATE_WITHDRAWAL].status}
          transactionHash={stepStatuses[DepositWithdrawStep.INITIATE_WITHDRAWAL].hash}
          text={t("liq::Initiate Withdrawal")}
          mb={2}
        />
        {stepStatuses[DepositWithdrawStep.INITIATE_WITHDRAWAL].hasError && (
          <Button width="100%" size="sm" mt={2} mb={4} onClick={handleRetryInitiateWithdraw}>
            {t("Retry")}
          </Button>
        )}
        <TransactionStepRow
          status={stepStatuses[DepositWithdrawStep.WITHDRAWAL_TIMELOCK].status}
          text={t("liq::Time Lock ({{countdown}}s)", { countdown: timeLockRemaining })}
        />
      </Box>
    </TransactionStep>
  );
};
