import { useTranslation } from "next-i18next";
import { ButtonProps } from "@chakra-ui/react";
import { useGetFormattedErrorAndTitle, useSignInHandler } from "@looksrare/utils";
import { Button } from "@looksrare/uikit";
import { useToast } from "@/hooks/useToast";
import { useClaimMilestoneLevel } from "@/hooks/points";
import { MilestoneLevelCode } from "@/types";

interface ClaimMilestoneLevelButtonProps extends ButtonProps {
  milestoneLevelCode: MilestoneLevelCode;
}
export const ClaimMilestoneLevelButton = ({
  milestoneLevelCode,
  children,
  ...props
}: ClaimMilestoneLevelButtonProps) => {
  const { t } = useTranslation();

  const { toast } = useToast();
  const getFormattedErrorAndTitle = useGetFormattedErrorAndTitle();
  const { mutate: claimMilestoneLevel, isPending } = useClaimMilestoneLevel({
    onError: (error) => {
      const { title, description } = getFormattedErrorAndTitle(error);
      toast({ title, description, status: "error", dataIdSuffix: "claim-level-query-failure" });
    },
    onSuccess: () => {
      toast({
        title: t("Claimed"),
        description: t("Please allow a few moments for your balance to update."),
        status: "success",
        dataIdSuffix: "claim-level-success",
      });
    },
  });

  const handleAuthFailure = (error: any) => {
    const { title, description } = getFormattedErrorAndTitle(error);
    toast({ title, description, status: "error", dataIdSuffix: "claim-level-auth-failure" });
  };

  const { signInHandler } = useSignInHandler({
    onAuthSuccess: () => claimMilestoneLevel(milestoneLevelCode),
    onAuthFailure: handleAuthFailure,
  });

  return (
    <Button
      isLoading={isPending}
      onClick={(e) => {
        e.stopPropagation();
        signInHandler();
      }}
      {...props}
    >
      {children || t("Claim")}
    </Button>
  );
};
