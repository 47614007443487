import { ReactElement } from "react";
import { Box, Flex, IconButton, Spinner } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { CloseIcon, ConnectWalletModalGuard, DiscordIcon, TwitterIcon } from "@looksrare/uikit";
import { Button, ButtonProps, Text } from "@looksrare/uikit";
import { SupportedSocialPlatforms, useGetStartOAuthFlowForUser } from "@/hooks/oauth";

interface Props {
  handle?: string;
  props?: ButtonProps;
  onDisconnect?: () => void;
  onConnect?: () => void;
}

interface BaseProps extends Props {
  socialPlatform: SupportedSocialPlatforms;
  leftIcon: ReactElement;
  children: ReactElement;
}

const SocialConnectionButtonBase = ({
  socialPlatform,
  leftIcon,
  handle,
  onDisconnect,
  onConnect,
  children,
  ...props
}: BaseProps) => {
  const { connect, disconnect, isLoading } = useGetStartOAuthFlowForUser({
    socialPlatform,
    onDisconnectSuccess: onDisconnect,
    onConnectSuccess: onConnect,
  });

  return (
    <ConnectWalletModalGuard>
      <Flex
        width="100%"
        alignItems="center"
        borderRadius="button"
        border="1px solid"
        borderColor="border-01"
        {...props}
      >
        <Box textAlign="center" width={12}>
          {leftIcon}
        </Box>
        {handle ? (
          <>
            <Text flex={1} noOfLines={1}>
              {handle}
            </Text>
            <IconButton
              borderRadius="button"
              ml={1}
              aria-label="disconnect"
              colorScheme="gray"
              isDisabled={isLoading}
              onClick={disconnect}
            >
              {isLoading ? <Spinner /> : <CloseIcon />}
            </IconButton>
          </>
        ) : (
          <Button borderRadius={0} width="100%" flex={1} colorScheme="gray" onClick={connect}>
            {children}
          </Button>
        )}
      </Flex>
    </ConnectWalletModalGuard>
  );
};

export const TwitterConnectionButton = ({ handle, onDisconnect, ...props }: Props) => {
  const { t } = useTranslation();

  return (
    <SocialConnectionButtonBase
      onDisconnect={onDisconnect}
      handle={handle}
      socialPlatform="TWITTER"
      leftIcon={<TwitterIcon />}
      {...props}
    >
      {t("Connect X Account")}
    </SocialConnectionButtonBase>
  );
};

export const DiscordConnectionButton = ({ handle, onDisconnect, ...props }: Props) => {
  const { t } = useTranslation();

  return (
    <SocialConnectionButtonBase
      onDisconnect={onDisconnect}
      handle={handle}
      socialPlatform="DISCORD"
      leftIcon={<DiscordIcon />}
      {...props}
    >
      {t("Connect Discord Account")}
    </SocialConnectionButtonBase>
  );
};
