import type { Address } from "viem";
import { useAccount, useReadContract } from "wagmi";
import { LiquidityPoolAbi } from "@looksrare/config";

/**
 * Returns an the maximum amount of shares a user can withdraw from a pool
 */
export const useMaxRedeemableShares = (liqudityPool: Address) => {
  const { address } = useAccount();
  return useReadContract({
    address: liqudityPool,
    abi: LiquidityPoolAbi,
    functionName: "maxRedeem",
    args: [address!],
    query: { enabled: !!address },
  });
};
