import { useEffect } from "react";
import { useAccount } from "wagmi";
import { zeroAddress } from "viem";
import { Flex, ModalBody, Spinner } from "@chakra-ui/react";
import { isAddressEqual, type BigIntish } from "@looksrare/utils";
import { type ModalProps } from "@looksrare/uikit";
import type { Currency, LiquidityPoolContract } from "@looksrare/yolo-games-gql-typegen/src/generated/gql/graphql";
import { getUserLiquidityPendingActions } from "@/views/Liquidity/queries";
import { useDepositWithdrawStore } from "./store";
import { DepositWithdrawStep } from "./types";
import { WithdrawalConfirmationView } from "./WithdrawalConfirmationView";
import { ConfirmWithdrawalView } from "./ConfirmWithdrawalView";
import { ConfirmDepositView } from "./ConfirmDepositView";
import { DepositConfirmationView } from "./DepositConfirmationView";
import { DepositInputView } from "./DepositInputView";
import { WithdrawInputView } from "./WithdrawInputView";

export interface DepositWithdrawProps {
  contract: LiquidityPoolContract;
  currency: Currency;
  dailyDroplets?: BigIntish;
  depositFeeBps: number;
  minDepositVum: BigIntish;
  maxDepositVum: BigIntish;
  assetsBalanceVum: BigIntish;
  pendingDepositsAmountVum: BigIntish;
  assetsLimitVum: BigIntish;
  finalizationIncentiveEthWei: BigIntish;
  timelockDelaySecs: number;
  onClose: ModalProps["onClose"];
  onFinalizeSuccess: () => void;
  currentMultiplier?: number;
  initialAction?: DepositWithdrawStep.DEPOSIT_INPUT | DepositWithdrawStep.WITHDRAWAL_INPUT;
}

export const DepositWithdraw = ({
  contract,
  currency,
  dailyDroplets,
  depositFeeBps,
  minDepositVum,
  maxDepositVum,
  assetsBalanceVum,
  pendingDepositsAmountVum,
  assetsLimitVum,
  finalizationIncentiveEthWei,
  timelockDelaySecs,
  onClose,
  onFinalizeSuccess,
  currentMultiplier,
  initialAction = DepositWithdrawStep.DEPOSIT_INPUT,
}: DepositWithdrawProps) => {
  const { address } = useAccount();
  const [step, startInput, startDepositFinalization, startWithdrawalFinalization] = useDepositWithdrawStore((state) => [
    state.step,
    state.startInput,
    state.startDepositFinalization,
    state.startWithdrawalFinalization,
  ]);
  const isEthPool = isAddressEqual(currency.address, zeroAddress);

  useEffect(() => {
    const checkPendingActions = async () => {
      if (!address) {
        return;
      }
      try {
        const pendingActions = await getUserLiquidityPendingActions(address);
        const pendingAction = pendingActions.find((action) => action.contract === contract);
        if (!!pendingAction) {
          if (pendingAction.type === "DEPOSIT") {
            startDepositFinalization();
          } else {
            startWithdrawalFinalization();
          }
          return;
        }
        startInput(initialAction);
      } catch {
        // A fall back just in case
        startInput(initialAction);
      }
    };

    if (step === DepositWithdrawStep.IDLE) {
      checkPendingActions();
    }
  }, [startInput, startDepositFinalization, startWithdrawalFinalization, address, contract, step, initialAction]);

  return (
    <ModalBody py={8}>
      {(() => {
        if (!address) {
          return null;
        }
        switch (step) {
          case DepositWithdrawStep.IDLE:
          default:
            return (
              <Flex alignItems="center" justifyContent="center" height="325px">
                <Spinner />
              </Flex>
            );
          case DepositWithdrawStep.DEPOSIT_INPUT:
            return (
              <DepositInputView
                isEthPool={isEthPool}
                connectedAddress={address}
                currency={currency}
                contract={contract}
                dailyDroplets={dailyDroplets}
                depositFeeBps={depositFeeBps}
                minDepositVum={minDepositVum}
                maxDepositVum={maxDepositVum}
                assetsBalanceVum={assetsBalanceVum}
                pendingDepositsAmountVum={pendingDepositsAmountVum}
                assetsLimitVum={assetsLimitVum}
                finalizationIncentiveEthWei={finalizationIncentiveEthWei}
              />
            );
          case DepositWithdrawStep.WITHDRAWAL_INPUT:
            return (
              <WithdrawInputView
                isEthPool={isEthPool}
                currency={currency}
                contract={contract}
                dailyDroplets={dailyDroplets}
                currentMultiplier={currentMultiplier}
              />
            );
          case DepositWithdrawStep.DEPOSIT_GRANT_APPROVAL:
          case DepositWithdrawStep.DEPOSIT_APPROVE_TOKEN:
          case DepositWithdrawStep.INITIATE_DEPOSIT:
          case DepositWithdrawStep.DEPOSIT_TIMELOCK:
          case DepositWithdrawStep.FINALIZE_DEPOSIT:
            return (
              <DepositConfirmationView
                isEthPool={isEthPool}
                contract={contract}
                finalizationIncentiveEthWei={finalizationIncentiveEthWei}
                currency={currency}
                timelockDelaySecs={timelockDelaySecs}
                onFinalizeSuccess={onFinalizeSuccess}
              />
            );
          case DepositWithdrawStep.WITHDRAWAL_GRANT_APPROVAL:
          case DepositWithdrawStep.WITHDRAWAL_APPROVE_TOKEN:
          case DepositWithdrawStep.INITIATE_WITHDRAWAL:
          case DepositWithdrawStep.WITHDRAWAL_TIMELOCK:
          case DepositWithdrawStep.FINALIZE_WITHDRAWAL:
            return (
              <WithdrawalConfirmationView
                isEthPool={isEthPool}
                contract={contract}
                currency={currency}
                timelockDelaySecs={timelockDelaySecs}
                onFinalizeSuccess={onFinalizeSuccess}
                finalizationIncentiveEthWei={finalizationIncentiveEthWei}
              />
            );
          case DepositWithdrawStep.DEPOSIT_CONFIRMATION:
            return (
              <ConfirmDepositView
                onClose={onClose}
                currency={currency}
                finalizationIncentiveEthWei={finalizationIncentiveEthWei}
              />
            );
          case DepositWithdrawStep.WITHDRAWAL_CONFIRMATION:
            return (
              <ConfirmWithdrawalView
                onClose={onClose}
                currency={currency}
                finalizationIncentiveEthWei={finalizationIncentiveEthWei}
              />
            );
        }
        return null;
      })()}
    </ModalBody>
  );
};
