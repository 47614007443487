import { CheckmarkFilledIcon, ModalHeroHeader } from "@looksrare/uikit";
import { useTranslation } from "next-i18next";

interface ClaimRakebackSuccessHeaderProps {
  onClose: () => void;
}

export const ClaimRakebackSuccessHeader = ({ onClose }: ClaimRakebackSuccessHeaderProps) => {
  const { t } = useTranslation();

  return (
    <ModalHeroHeader
      icon={CheckmarkFilledIcon}
      title={t("Rakeback Claimed!")}
      label={t("Your rakeback has been sent to your wallet.")}
      labelProps={{ textStyle: "body", color: "text-02" }}
      flexShrink={0}
      onClose={onClose}
      showCloseButton
    />
  );
};
