import { BoxProps } from "@chakra-ui/react";
import { HeroCarouselSlideDesktop } from "./HeroCarouselSlideDesktop";
import { HeroCarouselSlideMobile } from "./HeroCarouselSlideMobile";

export interface HeroCarouselSlideProps extends BoxProps {
  title: string;
  description: string;
  href: string;
  imageSrc: string;
  blurImageSrc?: string;
}

export const HeroCarouselSlide = (props: HeroCarouselSlideProps) => {
  return (
    <>
      <HeroCarouselSlideDesktop display={{ base: "none", lg: "flex" }} {...props} />
      <HeroCarouselSlideMobile display={{ base: "flex", lg: "none" }} {...props} />
    </>
  );
};
