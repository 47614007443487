import { getTopicName, TopicName, useRealtimeSubscription } from "@looksrare/utils";

export const useLaserBlastPageViews = () => {
  const topicName = getTopicName({ name: TopicName.laserBlastActiveViews });
  return useRealtimeSubscription<{ count: number }>(topicName);
};

export const useLaserBlastActivePlayers = () => {
  const topicName = getTopicName({ name: TopicName.laserBlastActivePlayers });
  return useRealtimeSubscription<number>(topicName);
};
