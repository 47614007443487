import { Button, ButtonToggle } from "@looksrare/uikit";
import type { LiquidityPoolInterval } from "@looksrare/yolo-games-gql-typegen";
import { useTimeIntervalLabels } from "../hooks";

interface TimeIntervalSelectMenuProps {
  currentTimeInterval: LiquidityPoolInterval;
  onSelectTimeInterval: (newInterval: LiquidityPoolInterval) => void;
}

export const getBg = (isActive: boolean) => (isActive ? "interactive-02" : "transparent");
export const getColor = (isActive: boolean) => (isActive ? "link-01" : "link-02");

export const TimeIntervalSelectMenu = ({ currentTimeInterval, onSelectTimeInterval }: TimeIntervalSelectMenuProps) => {
  const intervalLabels = useTimeIntervalLabels();
  return (
    <ButtonToggle>
      {intervalLabels.map(([timeInterval, label]) => {
        return (
          <Button
            key={timeInterval}
            onClick={() => onSelectTimeInterval(timeInterval)}
            size="xs"
            minWidth={11}
            variant="outline"
            backgroundColor={getBg(currentTimeInterval === timeInterval)}
            color={getColor(currentTimeInterval === timeInterval)}
          >
            {label}
          </Button>
        );
      })}
    </ButtonToggle>
  );
};
