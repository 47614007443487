import { Flex, Box } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { Text, Button, CheckmarkFilledIcon, RadioButtonIcon } from "@looksrare/uikit";
import { DailyQuestCode } from "@/types/points";
import { ClaimDailyQuestButton } from "./ClaimDailyQuestButton";

interface SingleDailyQuestProps {
  code: DailyQuestCode;
  task: string;
  points: string;
  hasClaimed: boolean;
  canClaim: boolean;
}

type ClaimButtonProps = Pick<SingleDailyQuestProps, "points" | "hasClaimed" | "canClaim" | "code">;

const ClaimButton: React.FC<ClaimButtonProps> = ({ points, canClaim, hasClaimed, code }) => {
  const { t } = useTranslation();
  if (!canClaim && !hasClaimed) {
    return (
      <Flex gap={1} alignItems="center">
        <Text textColor="text-02" bold>
          {points} Pts
        </Text>
      </Flex>
    );
  }
  if (canClaim && !hasClaimed) {
    return (
      <ClaimDailyQuestButton size="xs" code={code}>
        {t("Claim {{points}}", { points })}
      </ClaimDailyQuestButton>
    );
  }
  return (
    <Button size="xs" isDisabled>
      {t("Claimed {{points}}", { points })}
    </Button>
  );
};

export const SingleDailyQuest: React.FC<SingleDailyQuestProps> = ({ task, code, points, canClaim, hasClaimed }) => {
  const icon = (() => {
    if (hasClaimed) {
      return <CheckmarkFilledIcon boxSize="32px" color="interactive-03" />;
    }
    if (!!canClaim) {
      return <RadioButtonIcon boxSize="32px" color="interactive-03" />;
    }
    return <RadioButtonIcon boxSize="32px" color="text-disabled" />;
  })();

  return (
    <Flex
      width="100%"
      alignItems="center"
      py={3}
      borderRadius="mini"
      borderBottom="1px solid"
      borderColor="border-01"
      gap={3}
    >
      {icon}
      <Text textColor="text-02" textStyle="detail" bold flexGrow="3">
        {task}
      </Text>
      <Box>
        <ClaimButton points={points} canClaim={canClaim} hasClaimed={hasClaimed} code={code} />
      </Box>
    </Flex>
  );
};
