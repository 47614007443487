import { useAccount } from "wagmi";
import { Trans } from "next-i18next";
import { intervalToDuration, isBefore } from "date-fns";
import { useElapsedTime } from "use-elapsed-time";
import { AlertDescription, Link } from "@chakra-ui/react";
import { Alert, NextLink, Text } from "@looksrare/uikit";
import { formatToSignificant, type BigIntish } from "@looksrare/utils";
import type { Currency, LiquidityPoolContract } from "@looksrare/yolo-games-gql-typegen/src/generated/gql/graphql";
import { lpConfig } from "@/config";
import { useUserLiquidityPendingActions } from "@/views/Liquidity/hooks";

interface FinalizationAlertProps {
  contract: LiquidityPoolContract;
  finalizationIncentiveEthWei: BigIntish;
  currency: Currency;
}

export const FinalizationAlert = ({ contract, finalizationIncentiveEthWei, currency }: FinalizationAlertProps) => {
  const { address } = useAccount();
  const pendingActionsQuery = useUserLiquidityPendingActions(address);
  const pendingAction = pendingActionsQuery.data?.find((action) => action.contract === contract);
  const hasProtectedUntilPassed = !!pendingAction ? isBefore(pendingAction.protectedUntil, new Date()) : true;

  // Re-renders component every second
  useElapsedTime({ isPlaying: !hasProtectedUntilPassed, updateInterval: 1 });

  if (!pendingAction || hasProtectedUntilPassed) {
    return null;
  }

  const duration = intervalToDuration({
    start: Date.now(),
    end: pendingAction.protectedUntil,
  });

  const { minutes = 0, seconds = 0 } = duration;
  const countdown = minutes === 0 ? `${seconds}s` : `${minutes}m ${seconds}s`;
  const fee = formatToSignificant(finalizationIncentiveEthWei, lpConfig[contract].displayDecimals, {
    decimals: currency.decimals,
  });

  return (
    <Alert status="warning" bg="support-warning-bg-inverse" mt={4}>
      <AlertDescription color="text-inverse" textStyle="detail">
        <Trans i18nKey="liqFinalizationCountdown" ns="liq">
          If you don&apos;t finalize within{" "}
          <Text as="span" color="currentcolor" textStyle="detail" bold>
            <>{{ countdown }}</>
          </Text>
          , another user may finalize your deposit and claim your <>{{ fee }}</> ETH finalization fee instead.{" "}
          <Link
            as={NextLink}
            href="https://docs.yologames.io/games/liquidity-and-probabilities/liquidity-pools"
            fontWeight="bold"
          >
            Learn More
          </Link>
        </Trans>
      </AlertDescription>
    </Alert>
  );
};
