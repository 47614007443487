import Highcharts from "highcharts";
import { HStack, type StackProps } from "@chakra-ui/react";
import { PercentChangeLabel, Text } from "@looksrare/uikit";

export const defaultHighchartsOptions: Highcharts.Options = {
  chart: {
    backgroundColor: "var(--yg-colors-ui-bg)",
    height: "256px",
    style: {
      fontFamily: "'Space Grotesk', sans-serif",
    },
  },
  tooltip: {
    valueDecimals: 2,
  },
  time: {
    timezoneOffset: new Date().getTimezoneOffset(),
  },
  credits: {
    enabled: false,
  },
  title: {
    text: "",
  },
  subtitle: {
    text: "",
  },
  legend: {
    enabled: false,
  },
  yAxis: {
    title: undefined,
  },
};

interface ValuePercTextProps extends StackProps {
  percentage?: number;
  timeIntevalLabel: string;
}

export const ValuePercText = ({ percentage, timeIntevalLabel, ...props }: ValuePercTextProps) => (
  <HStack spacing={1} {...props}>
    {typeof percentage === "undefined" ? (
      <Text color="text-03" textStyle="helper">
        —
      </Text>
    ) : (
      <PercentChangeLabel value={percentage} textStyle="helper" />
    )}
    <Text textStyle="helper" color="text-03">
      {timeIntevalLabel}
    </Text>
  </HStack>
);
