import { useCallback } from "react";
import { fromDecimals, useAddressesByNetwork, useCoinPrices, useGetCurrencyConfigBySymbol } from "@looksrare/utils";
import { Rakeback } from "@looksrare/yolo-games-gql-typegen";
import { supportedRakebackCurrencies } from "@/hooks/rakeback";
import { getDefaultChain } from "@/utils";
import { CurrencyRowProps } from "../components/CurrencyRow";

export const useMapRakebacksToCurrencyRows = () => {
  const chainId = getDefaultChain();
  const getCurrencyConfig = useGetCurrencyConfigBySymbol(chainId);
  const addresses = useAddressesByNetwork(chainId);

  const { data: coinPrices } = useCoinPrices();
  const ethUsdPrice = coinPrices?.eth.price ?? 0;

  const getDefaultRakebackAmounts = useCallback(
    (): CurrencyRowProps[] =>
      supportedRakebackCurrencies.map((currencySymbol) => {
        const currencyConfig = getCurrencyConfig(currencySymbol);

        return {
          amountWei: BigInt(0),
          amountDollar: 0,
          currency: {
            address: addresses[currencySymbol],
            symbol: currencyConfig.symbol,
            name: currencyConfig.name,
            decimals: currencyConfig.decimals,
          },
        };
      }),
    [addresses, getCurrencyConfig]
  );

  return useCallback(
    (rakebacks: Rakeback[]) =>
      rakebacks.reduce((amounts, { currency, amountWei }) => {
        const amountsCurrencyIndex = amounts.findIndex((amount) => amount.currency.address === currency.address);

        // TODO-rakeback: This is temporary until we can get the proper dollar amount for currencies from backend
        const amountFloat = Number(fromDecimals(amountWei));
        const amountDollar = amountFloat * ethUsdPrice;

        if (amountsCurrencyIndex === -1) {
          amounts.push({
            amountWei: BigInt(amountWei),
            amountDollar: amountDollar,
            currency: currency,
          });

          return amounts;
        }

        amounts[amountsCurrencyIndex].amountWei += BigInt(amountWei);
        amounts[amountsCurrencyIndex].amountDollar += amountDollar;

        return amounts;
      }, getDefaultRakebackAmounts()),
    [getDefaultRakebackAmounts, ethUsdPrice]
  );
};
