import { useCallback } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { type UserLiqudityPoolMetricsArgs, getUserLiqudityPoolMetrics } from "../queries";

export const useUserLiquidityPoolMetrics = ({ address, filters }: UserLiqudityPoolMetricsArgs) => {
  return useQuery({
    queryKey: ["user-liquidity-pool-metrics", address, filters],
    queryFn: () => getUserLiqudityPoolMetrics({ address, filters }),
  });
};

export const useInvalidateUserLiquidityPoolMetrics = () => {
  const queryClient = useQueryClient();
  return useCallback(() => {
    queryClient.invalidateQueries({ queryKey: ["user-liquidity-pool-metrics"], exact: false });
  }, [queryClient]);
};
