import type {
  LiquidityPoolGame,
  LiquidityPoolTimeseries,
} from "@looksrare/yolo-games-gql-typegen/src/generated/gql/graphql";

interface AggregatedMetrics {
  previousCumulativeTotal: number;
  currentCumulativeTotal: number;
}

export const aggregateMetrics = (metrics: LiquidityPoolTimeseries[]): AggregatedMetrics => {
  return metrics.reduce(
    (accum, metric) => {
      accum.previousCumulativeTotal += metric.previousCumulativeTotal;
      accum.currentCumulativeTotal += metric.currentCumulativeTotal;
      return accum;
    },
    { previousCumulativeTotal: 0, currentCumulativeTotal: 0 }
  );
};

export const getPercentageChange = (current: number = 0, previous: number = 0): number => {
  return previous ? (100 * (current - previous)) / Math.abs(previous) : current ? 100 : 0;
};

export const liquidityPoolGames = [
  "FLIPPER_V1_BLAST",
  "QUANTUM_V1_BLAST",
  "LASER_BLAST_V1_BLAST",
  "DONT_FALL_IN_V1_BLAST",
] as const;

export function gameContractToDisplay(gameContract: LiquidityPoolGame | "ALL_GAMES") {
  switch (gameContract) {
    case "FLIPPER_V1_BLAST":
      return { name: "Flipper", color: "blue.400" };
    case "LASER_BLAST_V1_BLAST":
      return { name: "LaserBlast", color: "purple.400" };
    case "QUANTUM_V1_BLAST":
      return { name: "Quantum", color: "orange.400" };
    case "DONT_FALL_IN_V1_BLAST":
      return { name: "Dont Fall In", color: "magenta.500" };
    case "ALL_GAMES":
      return { name: "All Games", color: "gray.400" };
  }
}
