import { useEffect } from "react";
import { usePublicClient, useWalletClient } from "wagmi";
import { useTranslation } from "next-i18next";
import { Box } from "@chakra-ui/react";
import { LiquidityPoolRouterAbi } from "@looksrare/config";
import { Button, Text, TransactionStep, TransactionStepRow } from "@looksrare/uikit";
import { NoPublicClientError, sleep, useHandleModalStep, type BigIntish } from "@looksrare/utils";
import type { Currency, LiquidityPoolContract } from "@looksrare/yolo-games-gql-typegen/src/generated/gql/graphql";
import { getDefaultChain } from "@/utils";
import { gameAddresses } from "@/config";
import { useDepositWithdrawStore } from "../store";
import { DepositWithdrawStep } from "../types";
import { FinalizationAlert } from "../FinalizationAlert";

interface FinalizeDepositStepProps {
  contract: LiquidityPoolContract;
  finalizationIncentiveEthWei: BigIntish;
  currency: Currency;
  onFinalizeSuccess: () => void;
}

export const FinalizeDepositStep = ({
  contract,
  finalizationIncentiveEthWei,
  currency,
  onFinalizeSuccess,
}: FinalizeDepositStepProps) => {
  const [step, inputValue, setFailStatus, confirmDeposit, finalizeStepStatus] = useDepositWithdrawStore((state) => [
    state.step,
    state.inputValue,
    state.setFailStatus,
    state.confirmDeposit,
    state.stepStatus[DepositWithdrawStep.FINALIZE_DEPOSIT],
  ]);
  const { t } = useTranslation();

  const { data: walletClient } = useWalletClient();
  const publicClient = usePublicClient();

  const finalizeDeposit = useHandleModalStep({
    onSubmit: async () => {
      if (!walletClient) {
        throw Error("No wallet client found");
      }
      if (!publicClient) {
        throw NoPublicClientError;
      }

      const [account] = await walletClient.getAddresses();
      const chainId = getDefaultChain();
      const addresses = gameAddresses[chainId];
      const { request } = await publicClient.simulateContract({
        address: addresses.LIQUIDITY_POOL_ROUTER,
        abi: LiquidityPoolRouterAbi,
        functionName: "finalizeDeposit",
        args: [account],
        account,
      });
      const hash = await walletClient.writeContract(request);
      await publicClient.waitForTransactionReceipt({ hash });
    },
    onSuccess: async () => {
      // Wait for the BE to catch up.
      await sleep(2_000);

      confirmDeposit();
      onFinalizeSuccess();
    },
    onError: () => {
      setFailStatus(DepositWithdrawStep.FINALIZE_DEPOSIT);
    },
  });

  const handleRetryFinalize = () => {
    finalizeDeposit.handleSubmit();
  };

  useEffect(() => {
    if (step === DepositWithdrawStep.FINALIZE_DEPOSIT) {
      finalizeDeposit.handleSubmit({ callOnlyOnce: true });
    }
  }, [step, finalizeDeposit, inputValue]);

  return (
    <TransactionStep
      status={finalizeStepStatus.timingStatus}
      title={t("liq::Finalize & Reclaim Fee")}
      width="100%"
      collapse={finalizeStepStatus.timingStatus !== "current"}
      bodyWrapperProps={{ pl: 0, pt: 0 }}
    >
      <Box py={6} px={4} bg="ui-01" borderRadius="container">
        <Text textStyle="detail" mb={4}>
          {t("Waiting for you to confirm in wallet")}
        </Text>
        <TransactionStepRow
          status={finalizeStepStatus.status}
          transactionHash={finalizeStepStatus.hash}
          text={t("liq::Finalize Deposit")}
          mb={2}
        />
        {finalizeStepStatus.hasError && (
          <Button width="100%" size="sm" mt={4} onClick={handleRetryFinalize}>
            {t("Retry")}
          </Button>
        )}
      </Box>
      <FinalizationAlert
        contract={contract}
        finalizationIncentiveEthWei={finalizationIncentiveEthWei}
        currency={currency}
      />
    </TransactionStep>
  );
};
