import { useTranslation } from "next-i18next";
import { usePreviousValue } from "@looksrare/utils";
import type { LiquidityPoolGame, LiquidityPoolMetricsFilterInput } from "@looksrare/yolo-games-gql-typegen";
import sumBy from "lodash/sumBy";
import { useGlobalLiqudityPoolMetrics } from "../../hooks";
import { aggregateMetrics, getPercentageChange } from "../../utils";
import { ChartBox } from "./ChartBox";
import { LiquidityPoolBarChart } from "./LiquidityPoolBarChart";

interface GlobalRoundPlayedProps {
  gameContract: LiquidityPoolGame | "ALL_GAMES";
  filters: LiquidityPoolMetricsFilterInput;
  currencySymbol: string;
}

export const GlobalRoundPlayed = ({ gameContract, filters, currencySymbol }: GlobalRoundPlayedProps) => {
  const { t } = useTranslation();
  const poolMetricsQuery = useGlobalLiqudityPoolMetrics({ filters });
  const roundsPlayedMetrics =
    poolMetricsQuery.data?.filter((metric) => metric.metricType === "LP_GAMES_ROUNDS_PLAYED") ?? [];
  const prevRoundsPlayedMetrics = usePreviousValue(roundsPlayedMetrics);
  const currentRoundsPlayedMetrics = roundsPlayedMetrics || prevRoundsPlayedMetrics;
  const currentRoundsPlayedMetricsChart =
    gameContract === "ALL_GAMES"
      ? currentRoundsPlayedMetrics
      : currentRoundsPlayedMetrics.filter((metric) => metric.gameContract === gameContract);
  const currentGameVolumeTotal = sumBy(currentRoundsPlayedMetricsChart, "intervalTotal");
  const aggregatedMetrics = aggregateMetrics(currentRoundsPlayedMetricsChart);
  const intervalPctChange = getPercentageChange(
    aggregatedMetrics.currentCumulativeTotal,
    aggregatedMetrics.previousCumulativeTotal
  );

  return (
    <ChartBox
      isLoading={poolMetricsQuery.isFetching}
      hasData={!!currentRoundsPlayedMetricsChart && currentRoundsPlayedMetricsChart.length > 0}
      chartTitle={t("liq::Rounds Played")}
      timeInterval={filters.timeInterval}
      currencySymbol={currencySymbol}
      intervalPctChange={intervalPctChange}
      intervalTotal={currentGameVolumeTotal}
      valueVumUsd={0}
    >
      <LiquidityPoolBarChart chartData={currentRoundsPlayedMetricsChart} timeInterval={filters.timeInterval} />
    </ChartBox>
  );
};
