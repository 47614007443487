import { useTranslation } from "next-i18next";
import { AlertDescription, Box, Flex, VStack } from "@chakra-ui/react";
import { Alert, Button, NotificationAddedIcon, Text } from "@looksrare/uikit";
import { FormLabel } from "@/components/Form";
import { useEmailUpdate } from "@/hooks/user";
import { EmailNotificationInput } from "./EmailNotificationInput";
import { SettingContentProps } from "./ProfileContent";

const EmailSubscribedView = ({ onUnsubscribe, isLoading }: { onUnsubscribe: () => void; isLoading?: boolean }) => {
  const { t } = useTranslation();
  return (
    <Flex
      p={2}
      px={4}
      mb={12}
      h={13}
      width={{ base: "100%" }}
      rounded="button"
      border="1px solid"
      borderColor="border-01"
      justifyContent="space-between"
      alignItems="center"
    >
      <Flex justifyContent="space-between" alignItems="center" gap={2}>
        <NotificationAddedIcon color="interactive-03" />
        <Text color="text-01" textStyle="detail">
          {t("You’ll get notifications to your inbox.")}
        </Text>
      </Flex>
      <Button
        variant="unstyled"
        color="interactive-03"
        colorScheme="transparent"
        onClick={onUnsubscribe}
        px={3}
        size="sm"
        isLoading={isLoading}
      >
        {t("Unsubscribe")}
      </Button>
    </Flex>
  );
};

export const SettingsNotificationContent = ({ user }: SettingContentProps) => {
  const { t } = useTranslation();
  const { mutate: unsubscribeEmail, isPending } = useEmailUpdate(user.address, null);

  return (
    <Box mb={12}>
      <Text mb={2} textStyle="heading-03" bold>
        {t("Email Updates")}
      </Text>
      <Text mb={4} textStyle="detail" color="text-02">
        {t("Subscribe to receive email updates about the YOLO Games platform and ecosystem.")}
      </Text>
      <FormLabel mt={8}>{t("Email Address")}</FormLabel>
      {user?.isEmailVerified ? (
        <EmailSubscribedView onUnsubscribe={unsubscribeEmail} isLoading={isPending} />
      ) : (
        <>
          <EmailNotificationInput user={user} />
          {!user.email && (
            <Alert status="info" mt={12}>
              <VStack spacing={4} alignItems="flex-start">
                <AlertDescription color="white">
                  {t(
                    "This email won’t be visible to other people. Your email address can only be tied to one wallet at a time."
                  )}
                </AlertDescription>
              </VStack>
            </Alert>
          )}
        </>
      )}
    </Box>
  );
};
