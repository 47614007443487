import { Dispatch, SetStateAction, useCallback, useEffect } from "react";
import { useAccount } from "wagmi";
import type { Address } from "viem";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { usePreviousValue } from "@looksrare/utils";
import { LiquidityPoolUserMetricsFilterInput } from "@looksrare/yolo-games-gql-typegen";
import { getLiquidityPools, getLiquidityPoolTotalDroplets } from "../queries";

interface UseLiquidityPoolsParams {
  address?: Address | undefined;
  enabled?: boolean;
}
export const useLiquidityPools = ({ address, enabled = true }: UseLiquidityPoolsParams) => {
  return useQuery({
    queryKey: ["liquidity-pools", address],
    queryFn: () =>
      // We want to ensure that ETH pool is sorted first.
      // Network sort order isn't guaranteed.
      getLiquidityPools(address).then((pools) => pools?.sort((a) => (a.contract === "GAMES_ETH_LP_V1_BLAST" ? -1 : 1))),
    enabled,
  });
};

// Wrap useLiquidityPools and return no data if there is no address.
export const useUserLiquidityPools = () => {
  const { address } = useAccount();
  return useLiquidityPools({ address, enabled: !!address });
};

// Calls setFilters with the first pool that has assets.
export const useSetInitialCurrentPool = (setFilters: Dispatch<SetStateAction<LiquidityPoolUserMetricsFilterInput>>) => {
  const { data: userLiquidityPools } = useUserLiquidityPools();
  const previousUserLiquidityPools = usePreviousValue(userLiquidityPools);
  const isJustLoaded = !!userLiquidityPools && !previousUserLiquidityPools;
  useEffect(() => {
    if (isJustLoaded) {
      const poolWithBalance = userLiquidityPools.find((pool) => BigInt(pool.userSharesValueVum) > 0n);
      if (poolWithBalance) {
        setFilters((prevState) => ({
          ...prevState,
          contract: poolWithBalance.contract,
        }));
      }
    }
  }, [isJustLoaded, setFilters, userLiquidityPools]);
};

export const useInvalidateLiquidityPools = () => {
  const queryClient = useQueryClient();
  return useCallback(
    (address?: Address) => {
      queryClient.invalidateQueries({ queryKey: ["liquidity-pools", address] });
    },
    [queryClient]
  );
};

export const useLiquidityPoolTotalDroplets = (address?: Address) => {
  return useQuery({
    queryKey: ["liquidity-pool-total-droplets", address],
    queryFn: () => getLiquidityPoolTotalDroplets(address!),
    enabled: !!address,
  });
};
