import { useEffect, useRef } from "react";
import { Box, useToken } from "@chakra-ui/react";
import Highcharts from "highcharts/highstock";
import { LiquidityPoolGranularity, LiquidityPoolInterval } from "@looksrare/yolo-games-gql-typegen";
import { liquidityChartLabelFormatter } from "@/utils";
import { defaultHighchartsOptions } from "./shared";
import { granularityToMins } from "./LiquidityPoolBarChart";

interface LiquidityInflowsChartProps {
  chartData: number[][];
  granularity: LiquidityPoolGranularity;
  timeInterval: LiquidityPoolInterval;
}

export const LiquidityInflowsChart = ({ chartData, granularity, timeInterval }: LiquidityInflowsChartProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [green400, textPlaceholder, border01, ui01, text01, red400] = useToken("colors", [
    "green.400",
    "text-placeholder",
    "border-01",
    "ui-01",
    "text-01",
    "red.400",
  ]);

  useEffect(() => {
    if (ref.current) {
      const groupingTimeMinutes = granularityToMins(granularity) * (chartData.length / 24);

      const options: Highcharts.Options = {
        chart: {
          type: "column",
          marginLeft: 0,
        },
        tooltip: {
          backgroundColor: ui01,
          borderColor: border01,
          borderWidth: 1,
          borderRadius: 4,
          style: {
            color: text01,
            fontWeight: "bold",
          },
        },
        xAxis: {
          type: "datetime",
          dateTimeLabelFormats: {
            second: "%H:%M",
            minute: "%H:%M",
            hour: "%H:%M",
          },
          gridLineColor: border01,
          gridLineWidth: 1,
          lineWidth: 0,
          tickWidth: 0,
          labels: {
            formatter: (input) => liquidityChartLabelFormatter(input, timeInterval),
            style: { color: textPlaceholder },
          },
        },
        yAxis: {
          opposite: true,
          gridLineColor: border01,
          gridLineWidth: 1,
          lineWidth: 0,
          tickWidth: 0,
          labels: {
            style: { color: textPlaceholder },
          },
        },
        series: [
          {
            type: "column",
            data: chartData,
            borderWidth: 0,
            borderRadius: 2,
            dataGrouping: {
              enabled: true,
              forced: true,
              units: [["minute", [groupingTimeMinutes]]],
              approximation: "sum",
            },
            zones: [
              {
                value: 0,
                color: red400,
                fillColor: `${red400}40`,
              },
              {
                color: green400,
                fillColor: `${green400}40`,
              },
            ],
          },
        ],
      };
      Highcharts.chart(ref.current, Highcharts.merge(defaultHighchartsOptions, options));
    }
  }, [ref, chartData, green400, border01, ui01, text01, textPlaceholder, red400, granularity, timeInterval]);

  return <Box ref={ref} />;
};
