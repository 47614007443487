import { useRef } from "react";
import { Box, Stack } from "@chakra-ui/react";
import { CloudinaryImage, NextLink, Text } from "@looksrare/uikit";
import { textStyles } from "@/theme/textStyles";
import { shadows } from "@/theme/shadows";
import { HeroCarouselSlideProps } from "./HeroCarouselSlide";

export const HeroCarouselSlideDesktop = ({
  title,
  description,
  href,
  imageSrc,
  blurImageSrc,
  ...props
}: HeroCarouselSlideProps) => {
  const longDescriptionRef = useRef<HTMLParagraphElement>(null);

  return (
    <Box
      as={NextLink}
      href={href}
      position="relative"
      width="100%"
      height="480px"
      borderRadius="dialog"
      overflow="hidden"
      cursor="pointer"
      transition="transform 200ms"
      backgroundColor="ui-01"
      sx={{
        ".hero-slide-content": {
          backdropFilter: "blur(0)",
          backgroundColor: "transparent",
        },
        ".hero-description-wrapper": {
          height: textStyles.body.lineHeight,
        },
        ".hero-short-description": {
          opacity: 1,
        },
        ".hero-long-description": {
          opacity: 0,
        },
        _hover: {
          transform: "translateY(-8px)",
          ".hero-slide-content": {
            backdropFilter: "blur(10px)",
            backgroundColor: "blackAlpha.100",
          },
          ".hero-description-wrapper": {
            height: longDescriptionRef.current
              ? `${longDescriptionRef.current.scrollHeight}px !important`
              : textStyles.body.lineHeight,
          },
          ".hero-short-description": {
            opacity: 0,
          },
          ".hero-long-description": {
            opacity: 1,
          },
        },
        _active: {
          transform: "translateY(0)",
        },
      }}
      {...props}
    >
      <CloudinaryImage
        src={imageSrc}
        alt=""
        layout="fill"
        sizes="2560px"
        objectFit="cover"
        placeholder={blurImageSrc ? "blur" : "empty"}
        blurDataURL={blurImageSrc}
      />

      <Box
        className="hero-slide-content"
        position="absolute"
        top={0}
        bottom={0}
        left={0}
        py={6}
        px={8}
        width="480px"
        transition="backdrop-filter 200ms, background-color 200ms"
      >
        <Stack position="relative" justifyContent="end" height="100%" spacing={4}>
          <Text textStyle="display-03" color="white" textShadow={shadows["shadow-text-on-image"]} bold>
            {title}
          </Text>

          <Box className="hero-description-wrapper" position="relative" width="100%" transition="height 200ms">
            <Text
              className="hero-short-description"
              textStyle="body"
              color="white"
              textShadow={shadows["shadow-text-on-image"]}
              whiteSpace="pre-wrap"
              noOfLines={1}
              transition="opacity 200ms"
            >
              {description}
            </Text>
            <Text
              ref={longDescriptionRef}
              className="hero-long-description"
              position="absolute"
              top={0}
              left={0}
              right={0}
              textStyle="body"
              color="white"
              textShadow="1px 2px 4px rgba(0, 0, 0, 0.30)"
              whiteSpace="pre-wrap"
              transition="opacity 200ms"
            >
              {description}
            </Text>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};
