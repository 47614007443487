import { useTranslation } from "next-i18next";
import { Box, Divider, Flex, Stack } from "@chakra-ui/react";
import { Text, YoloGamesGrayscaleIcon } from "@looksrare/uikit";
import { BuiltOnBlast } from "@/components/Assets";

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <Stack spacing={6} width="100%" p={8} pt={6}>
      <Divider />
      <Flex width="100%" gap={8} justifyContent="space-between" flexDirection={{ base: "column", sm: "row" }}>
        <Flex alignItems="center" gap={3}>
          <YoloGamesGrayscaleIcon />
          <Flex alignItems="center">
            <Text textStyle="detail" color="text-03">
              <Box as="span" mr="2px">
                ©
              </Box>
              {t("2024 YOLO Games")}
            </Text>
          </Flex>
        </Flex>
        <Box>
          <BuiltOnBlast width="240px" height="96px" />
        </Box>
      </Flex>
    </Stack>
  );
};
