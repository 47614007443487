import { Flex, type FlexProps } from "@chakra-ui/react";
import { ModalCloseButton, ModalProps, Text } from "@looksrare/uikit";
import { useDepositWithdrawStore } from "./store";
import { DepositWithdrawStep } from "./types";

interface ModalHeaderProps extends FlexProps {
  onCloseModal: ModalProps["onClose"];
}

export const ModalHeader = ({ children, onCloseModal, ...props }: ModalHeaderProps) => {
  const step = useDepositWithdrawStore((state) => state.step);
  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      borderBottom="1px solid"
      borderBottomColor="border-01"
      py={2}
      px={4}
      {...props}
    >
      {[DepositWithdrawStep.DEPOSIT_CONFIRMATION, DepositWithdrawStep.WITHDRAWAL_CONFIRMATION].includes(step) ? (
        <div />
      ) : (
        <Text textStyle="heading-04" bold>
          {children}
        </Text>
      )}
      <ModalCloseButton
        onClick={onCloseModal}
        isDisabled={[DepositWithdrawStep.FINALIZE_DEPOSIT, DepositWithdrawStep.FINALIZE_WITHDRAWAL].includes(step)}
      />
    </Flex>
  );
};
