import { ClockIcon, ModalHeroHeader } from "@looksrare/uikit";
import { formatDuration } from "@looksrare/utils";
import { addSeconds } from "date-fns/addSeconds";
import { intervalToDuration } from "date-fns/intervalToDuration";
import { useTranslation } from "next-i18next";

interface ClaimRakebackLockedHeaderProps {
  onClose: () => void;
  nextUnlockAt: string;
}

export const ClaimRakebackLockedHeader = ({ onClose, nextUnlockAt }: ClaimRakebackLockedHeaderProps) => {
  const { t } = useTranslation();

  const now = new Date();
  const nextRakebackUnlocksAt = new Date(nextUnlockAt);
  const nextUnlockIn = intervalToDuration({ start: now, end: nextRakebackUnlocksAt });
  const isUnlockingSoon = nextRakebackUnlocksAt < addSeconds(now, 60);

  const title = t("Next Unlock: {{time}}", {
    time: isUnlockingSoon
      ? t("Soon")
      : formatDuration(nextUnlockIn, {
          shortLabels: true,
          format: ["months", "days", "hours", "minutes"],
        }),
  });

  return (
    <ModalHeroHeader
      icon={ClockIcon}
      title={title}
      label={t("Play games with fees to increase your claim!")}
      labelProps={{ textStyle: "body", color: "text-02" }}
      flexShrink={0}
      onClose={onClose}
      showCloseButton
    />
  );
};
