import { useMemo } from "react";
import { useTranslation } from "next-i18next";
import type { LiquidityPoolInterval } from "@looksrare/yolo-games-gql-typegen";

export const useTimeIntervalLabels = () => {
  const { t } = useTranslation();
  return useMemo<[LiquidityPoolInterval, string][]>(
    () => [
      ["_1D", t("24h")],
      ["_1W", t("7d")],
      ["_1M", t("30d")],
      ["MAX", t("All")],
    ],
    [t]
  );
};
