import type { QueryLiquidityPoolsOverviewArgs } from "@looksrare/yolo-games-gql-typegen";
import { useQuery } from "@tanstack/react-query";
import { getLiquidityPoolsGameOverview } from "../queries";

export const useLiquidityPoolsGameOverview = ({ filters }: QueryLiquidityPoolsOverviewArgs) => {
  return useQuery({
    queryKey: ["user-liquidity-pool-game-overview", filters.timeInterval],
    queryFn: () => getLiquidityPoolsGameOverview({ filters }),
  });
};
