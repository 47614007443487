import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import times from "lodash/times";
import { Box, Flex, Stack } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { useForm } from "react-hook-form";
import { Input, Text } from "@looksrare/uikit";
import { InputCell } from "@/views/Onboarding/components/InputCell";
import { useIsReferralCodeValid } from "@/queries/referral";

type Inputs = {
  code: string;
};

interface EnterCodeProps {
  onValidCode: (code: string) => void;
  isError?: boolean;
}

export const EnterCode = ({ onValidCode }: EnterCodeProps) => {
  const { t } = useTranslation();
  const router = useRouter();

  // react-hook-form setup
  const { register, setFocus, watch, getValues, setValue } = useForm<Inputs>();

  watch("code");
  const input = getValues("code") || "";
  const { onChange: originalOnChange, onBlur: originalOnBlur, ...registerProps } = register("code");

  const { data: isReferralCodeValid, isSuccess } = useIsReferralCodeValid(input, {
    onSuccess: (isValid) => {
      if (isValid) {
        onValidCode(input);
      }
    },
    enabled: input.length === 5,
  });

  // If there is a code in the query, prefill the input.
  // Oauth2 also uses a "code" query parameter so we can distinguish between
  // ours and theirs by checking the length since we have a "5" char code.
  const code = router.query.code?.length === 5 ? (router.query.code as string) || "" : "";
  const [hasSetCodeFromQuery, setHasSetCodeFromQuery] = useState(false);
  useEffect(() => {
    if (code && !hasSetCodeFromQuery) {
      setValue("code", code);
      setHasSetCodeFromQuery(true);
    }
  }, [code, hasSetCodeFromQuery, input, setValue]);

  const isError = input.length === 5 && isSuccess && !isReferralCodeValid;

  useEffect(() => {
    setFocus("code");
  }, [setFocus]);

  return (
    <Stack
      borderRadius="24px"
      width="100%"
      px={{ base: 2, md: 16 }}
      py={{ base: 6, md: 16 }}
      gap={6}
      bg="rgba(255, 255, 255, 0.04)"
      onClick={() => setFocus("code")}
      position="relative"
    >
      <Text color="text-03" textAlign="center">
        {t("Enter your invite code to claim your")}{" "}
        <Text as="span" color="link-01">
          {t("BOOST Airdrop")}
        </Text>
      </Text>

      {/* Use an input element, but hide it and display the value in the InputCells below */}
      <Input
        position="absolute"
        opacity="0"
        width="100%"
        height="100%"
        {...registerProps}
        onChange={(e) => {
          if (e.target.value.length > 5) {
            setValue("code", input.slice(0, 5));
            return e.preventDefault();
          }
          originalOnChange(e);
        }}
        onBlur={(e) => {
          // Force focus to remain on the input
          originalOnBlur(e);
          setFocus("code");
        }}
      />

      {/* Input area */}
      <Stack spacing={3}>
        <Flex gap={3} onClick={() => setFocus("code")}>
          {times(5).map((_, index) => {
            const value = input[index] || "";
            const isFocused = index === input.length || (index === 4 && input.length === 5);
            return <InputCell key={index} value={value} isFocused={isFocused} isError={isError} />;
          })}
        </Flex>
        {/* Reserve space for error message to avoid container resizing */}
        <Box height={4} mb={-4}>
          {isError && input.length === 5 && (
            <Text textStyle="helper" color="text-error" width="100%" textAlign="center">
              {t("Invalid code")}
            </Text>
          )}
          {isSuccess && isReferralCodeValid && (
            <Text textStyle="helper" color="text-error" width="100%" textAlign="center">
              {t("Success!")}
            </Text>
          )}
        </Box>
      </Stack>
    </Stack>
  );
};
