import { useEffect } from "react";
import { usePublicClient, useWalletClient } from "wagmi";
import { useTranslation } from "next-i18next";
import { Box } from "@chakra-ui/react";
import { Button, Text, TransactionStep, TransactionStepRow, useHandleModalStep } from "@looksrare/uikit";
import { NoPublicClientError, toDecimals } from "@looksrare/utils";
import { Erc20Abi, SAFE_MAX_UINT_256 } from "@looksrare/config";
import type { Currency } from "@looksrare/yolo-games-gql-typegen/src/generated/gql/graphql";
import { getDefaultChain } from "@/utils";
import { gameAddresses } from "@/config";
import { useDepositWithdrawStore } from "../store";
import { DepositWithdrawStep } from "../types";
import { useGrantApproval } from "../hooks";

interface ApproveTokenStepProps {
  isEthPool: boolean;
  currency: Currency;
}

export const ApproveTokenStep = ({ isEthPool, currency }: ApproveTokenStepProps) => {
  const { t } = useTranslation();
  const { data: walletClient } = useWalletClient();
  const publicClient = usePublicClient();

  const [
    step,
    inputValue,
    grantApprovalStepStatus,
    approveTokenStepStatus,
    startDepositGrantApproval,
    startDepositApproval,
    startInitiateDeposit,
    setFailStatus,
  ] = useDepositWithdrawStore((state) => [
    state.step,
    state.inputValue,
    state.stepStatus[DepositWithdrawStep.DEPOSIT_GRANT_APPROVAL],
    state.stepStatus[DepositWithdrawStep.DEPOSIT_APPROVE_TOKEN],
    state.startDepositGrantApproval,
    state.startDepositApproval,
    state.startInitiateDeposit,
    state.setFailStatus,
  ]);
  const grantTransferManagerApproval = useGrantApproval();

  const approveTransfer = useHandleModalStep({
    onSubmit: async () => {
      if (!walletClient) {
        throw Error("No wallet client found");
      }
      if (!publicClient) {
        throw NoPublicClientError;
      }

      startDepositApproval();

      const [account] = await walletClient.getAddresses();
      const inputVum = toDecimals(inputValue, currency.decimals);
      const chainId = getDefaultChain();
      const addresses = gameAddresses[chainId];

      // Check allowance
      const allowance = await publicClient.readContract({
        address: currency.address,
        abi: Erc20Abi,
        functionName: "allowance",
        args: [account, addresses.LIQUIDITY_TRANSFER_MANAGER],
      });

      if (allowance >= inputVum) {
        startInitiateDeposit();
        return;
      }

      // Approval
      const { request } = await publicClient.simulateContract({
        address: currency.address,
        abi: Erc20Abi,
        functionName: "approve",
        args: [addresses.LIQUIDITY_TRANSFER_MANAGER, SAFE_MAX_UINT_256],
        account,
      });
      const hash = await walletClient.writeContract(request);
      await publicClient.waitForTransactionReceipt({ hash });
      startInitiateDeposit();
    },
    onError: () => {
      setFailStatus(DepositWithdrawStep.DEPOSIT_GRANT_APPROVAL);
    },
  });

  const handleRetryApproveTransfer = () => {
    approveTransfer.handleSubmit();
  };

  const grantApproval = useHandleModalStep({
    onSubmit: async () => {
      grantTransferManagerApproval({
        onBeforeSend: () => {
          startDepositGrantApproval;
        },
        onSuccess: () => {
          if (isEthPool) {
            startInitiateDeposit();
            return;
          }
          startDepositApproval();
        },
      });
    },
    onError: () => {
      setFailStatus(DepositWithdrawStep.DEPOSIT_GRANT_APPROVAL);
    },
  });

  const handleRetryGrantApprovals = () => {
    grantApproval.handleSubmit();
  };

  useEffect(() => {
    if (step === DepositWithdrawStep.DEPOSIT_GRANT_APPROVAL) {
      grantApproval.handleSubmit({ callOnlyOnce: true });
    }
    if (step === DepositWithdrawStep.DEPOSIT_APPROVE_TOKEN) {
      approveTransfer.handleSubmit({ callOnlyOnce: true });
    }
  }, [step, approveTransfer, grantApproval]);

  return (
    <TransactionStep
      status={grantApprovalStepStatus.timingStatus}
      title={t("liq::Approve Transfer")}
      width="100%"
      collapse={grantApprovalStepStatus.timingStatus !== "current"}
      bodyWrapperProps={{ pl: 0, pt: 0 }}
      mb={4}
    >
      <Box py={6} px={4} bg="ui-01" borderRadius="container">
        <Text textStyle="detail" mb={4}>
          {t("Waiting for you to confirm in wallet")}
        </Text>
        <TransactionStepRow
          status={grantApprovalStepStatus.status}
          transactionHash={grantApprovalStepStatus.hash}
          text={t("Transfer Manager")}
          mb={2}
        />
        {grantApprovalStepStatus.hasError && (
          <Button width="100%" size="sm" my={4} onClick={handleRetryGrantApprovals}>
            {t("Retry")}
          </Button>
        )}
        <TransactionStepRow
          status={approveTokenStepStatus.status}
          transactionHash={approveTokenStepStatus.hash}
          text={t("liq::Approve Share Transfer")}
          mb={2}
        />
        {approveTokenStepStatus.hasError && (
          <Button width="100%" size="sm" mt={4} onClick={handleRetryApproveTransfer}>
            {t("Retry")}
          </Button>
        )}
      </Box>
    </TransactionStep>
  );
};
