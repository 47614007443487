import type { Address } from "viem";
import { fromDecimals, useCoinPrices, useEthBalance, useTokenBalance } from "@looksrare/utils";
import { useAccount } from "wagmi";

interface GetTokenBalanceInfoForLPReturn {
  isLoading: boolean;
  tokenBalanceVum: bigint;
  tokenBalanceUsd: number;
}

/**
 * Helper hook to get the token balance of a wallet
 */
export const useGetTokenBalanceInfoForLP = (
  isEthPool: boolean,
  currencyAddress: Address,
  currencyDecimals: number
): GetTokenBalanceInfoForLPReturn => {
  const { address } = useAccount();
  const coinPriceQuery = useCoinPrices();

  const ethTokenBalancequery = useEthBalance(address!, { enabled: isEthPool });
  const tokenBalanceQuery = useTokenBalance({
    contractAddress: currencyAddress,
    address: address!,
    queryOptions: {
      enabled: !isEthPool,
    },
  });
  const isLoadingBalances = ethTokenBalancequery.isLoading || tokenBalanceQuery.isLoading;

  const ethBalance = ethTokenBalancequery.isSuccess ? ethTokenBalancequery.data : 0n;
  const ethPriceUsd = coinPriceQuery.data ? coinPriceQuery.data.eth.price : 0;
  const ethBalanceUsd = parseFloat(fromDecimals(ethBalance, { decimals: currencyDecimals })) * ethPriceUsd;

  if (isEthPool) {
    return {
      isLoading: isLoadingBalances,
      tokenBalanceVum: ethBalance,
      tokenBalanceUsd: ethBalanceUsd,
    };
  }

  return {
    isLoading: isLoadingBalances,
    tokenBalanceVum: tokenBalanceQuery.data || 0n,
    // @todo-liq get YOLO token price
    tokenBalanceUsd: 0,
  };
};
