import { graphql } from "@looksrare/utils";
import { graphql as generatedGql, RecentGameWinSort } from "@looksrare/yolo-games-gql-typegen";

export const getRecentGameWins = async (sort: RecentGameWinSort) => {
  const query = generatedGql(/* GraphQL */ `
    query RecentGameWins($sort: RecentGameWinSort!) {
      recentGameWins(sort: $sort) {
        amountWonWei
        createdAt
        entryAmountWei
        game
        winner {
          address
          name
          isVerified
          avatar {
            image {
              src
              contentType
            }
          }
        }
        currency {
          address
          symbol
          decimals
        }
      }
    }
  `);

  const res = await graphql({ query, params: { sort } });
  return res.recentGameWins;
};
