import { Box, BoxProps } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { gradients } from "@looksrare/chakra-theme";
import { Text } from "@looksrare/uikit";

export const DailyQuestsContainer: React.FC<React.PropsWithChildren<BoxProps>> = ({ children }) => {
  const { t } = useTranslation();
  return (
    <Box
      p={4}
      width="100%"
      bgGradient={gradients["ui-gradient-v-01"]}
      borderRadius="container"
      border="1px solid"
      borderColor="border-01"
    >
      {children}
      <Text pt={2} textColor="text-03" textStyle="helper" textAlign="right">
        {t("Rounds must go ahead and not be canceled to count.")}
      </Text>
    </Box>
  );
};
