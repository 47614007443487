import { UserMilestoneLevel } from "@/types";

/**
 * Parse milestone levels and return the level that is claimable or in progress
 */
export const getCurrentLevel = (levels: UserMilestoneLevel[]): UserMilestoneLevel | undefined => {
  /**
   * Special handling for the PROFILE milestone.
   * If there is a claimable level, return that.
   * Otherwise, return the last level.
   */
  if (levels[0]?.milestone === "PROFILE_SETUP") {
    const isClaimableProfileLevel = levels.find(
      (level) => !level.claimedAt && BigInt(level.progress) >= BigInt(level.goal)
    );
    if (isClaimableProfileLevel) {
      return isClaimableProfileLevel;
    }

    return levels[levels.length - 1];
  }

  const isClaimableLevel = levels.find((level) => !level.claimedAt && BigInt(level.progress) >= BigInt(level.goal));
  if (isClaimableLevel) {
    return isClaimableLevel;
  }

  // none are claimable. find the first one that is in progress
  const isInProgressLevel = levels.find((level) => BigInt(level.progress) < BigInt(level.goal));
  if (isInProgressLevel) {
    return isInProgressLevel;
  }

  // none are in progress. return the last one
  return levels[levels.length - 1];
};
