import { useTranslation } from "next-i18next";
import { Flex, Stack } from "@chakra-ui/react";
import { GamesFillIcon } from "@looksrare/uikit";
import { SectionHeader } from "@/views/Home/components/SectionHeader";
import { PopularGameCard, PopularGameCardProps } from "@/views/Home/components/PopularGameCard";

interface PopularGamesProps {
  cards: PopularGameCardProps[];
}

export const PopularGames = ({ cards }: PopularGamesProps) => {
  const { t } = useTranslation();
  return (
    <Stack spacing={4} px={{ base: 4, lg: 12 }}>
      <SectionHeader icon={GamesFillIcon} title={t("Popular Games")} />
      <Flex flexDirection={{ base: "column", md: "row" }} width="100%" gap={4}>
        {cards.map((card) => {
          return <PopularGameCard key={card.gameId} flex={1} {...card} />;
        })}
      </Flex>
    </Stack>
  );
};
