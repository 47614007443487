import { useTranslation } from "next-i18next";
import { TooltipText } from "@looksrare/uikit";

export const DropletTooltipText = () => {
  const { t } = useTranslation();
  return (
    <>
      <TooltipText>
        {t(
          "liq::The greater your position, the more Droplets you earn. You'll also earn a larger Droplet multiplier the longer you leave your position without withdrawing."
        )}
      </TooltipText>
      <TooltipText>
        {t("liq::Droplets work like Points: the more you earn, the more YOLO you'll earn at the end of the Season")}
      </TooltipText>
    </>
  );
};

export const SlippageTooltipText = () => {
  const { t } = useTranslation();
  return (
    <TooltipText>
      {t(
        "liq::Due to slippage, the total value of your deposits and withdrawals may change slightly in the time between initiating and finalizing your transactions"
      )}
    </TooltipText>
  );
};

interface DepositFeeTooltipTextProps {
  feeDisplay: string;
}

export const DepositFeeTooltipText = ({ feeDisplay }: DepositFeeTooltipTextProps) => {
  const { t } = useTranslation();
  return (
    <TooltipText>
      {t("liq::A {{fee}} is applied to each deposit to fund future incentives and operations.", { fee: feeDisplay })}
    </TooltipText>
  );
};
