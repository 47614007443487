import { Box, Grid } from "@chakra-ui/react";
import { Text, useToast } from "@looksrare/uikit";
import { useTranslation } from "next-i18next";
import { SignedUserGuard } from "@/components";
import { OAuthURLParamError, useOAuthRedirectEvaluation } from "@/hooks/oauth";
import { SideMenu } from "./components/SideMenu";
import { ProfileContent } from "./components/ProfileContent";

export const ProfileView = () => {
  const { t } = useTranslation();
  const { toast } = useToast();

  useOAuthRedirectEvaluation({
    disabled: false,
    onSuccess: () => {
      toast({
        status: "success",
        title: t("Confirmed"),
        description: t("Your social account was linked successfully"),
      });
    },
    onError: (err) => {
      err instanceof OAuthURLParamError
        ? toast({
            status: "error",
            title: t("Couldn't Link Social Account"),
            description: t("Please try approving access again."),
            dataIdSuffix: "account-oauth-connect",
          })
        : toast({
            status: "error",
            title: t("Error"),
            description: t("An error occurred while linking your social account"),
            dataIdSuffix: "account-oauth-connect",
          });
    },
  });

  return (
    <Grid gridTemplateColumns={{ base: "minmax(0, 1fr)", md: "320px minmax(0, 1fr)" }} gridGap={8} my={8}>
      <SideMenu />
      <Box
        maxW={{ base: "100%", md: "512px" }}
        borderLeft="1px solid"
        borderLeftColor="border-01"
        p={8}
        alignItems="flex-start"
      >
        <Text as="h1" bold textStyle="display-03" letterSpacing="-2%" mb={8}>
          {t(`Profile Settings`)}
        </Text>
        <SignedUserGuard>{(user, addr) => <ProfileContent address={addr} user={user} />}</SignedUserGuard>
      </Box>
    </Grid>
  );
};
