import { RQueryOptionsForFetcher } from "@looksrare/utils";
import { RecentGameWinSort } from "@looksrare/yolo-games-gql-typegen";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "next-i18next";
import { useCallback } from "react";
import { getRecentGameWins } from "@/queries/recent-wins";

export const useRecentGameWins = (
  sort: RecentGameWinSort,
  options?: RQueryOptionsForFetcher<typeof getRecentGameWins>
) => {
  return useQuery({
    queryKey: ["recentGameWins", sort],
    queryFn: async () => getRecentGameWins(sort),
    ...options,
  });
};

export type GameType = "YOLO" | "PTB" | "MOD" | "DONT_FALL_IN" | "FLIPPER" | "QUANTUM" | "LASER_BLAST";

export const useGetGameInfoByGameType = () => {
  const { t } = useTranslation();

  return useCallback(
    (gameType: GameType) => {
      switch (gameType) {
        case "YOLO":
          return {
            gameName: t("YOLO"),
            thumbnail: "/images/yolo/yolo-thumbnail.png",
          };
        case "PTB":
          return {
            gameName: t("Poke the Bear"),
            thumbnail: "/images/poke-the-bear/logo.png",
          };
        case "MOD":
          return {
            gameName: t("Moon or Doom"),
            thumbnail: "/images/moon-or-doom/moon-or-doom-thumbnail.png",
          };
        case "DONT_FALL_IN":
          return {
            gameName: t("Don't Fall In"),
            thumbnail: "/images/dont-fall-in/thumbnail.png",
          };
        case "FLIPPER":
          return {
            gameName: t("Flipper"),
            thumbnail: "/images/flipper/thumbnail.png",
          };
        case "QUANTUM":
          return {
            gameName: t("Quantum"),
            thumbnail: "/images/quantum/thumbnail.png",
          };
        case "LASER_BLAST":
          return {
            gameName: t("LaserBlast"),
            thumbnail: "/images/laser-blast/thumbnail.png",
          };
      }
    },
    [t]
  );
};
