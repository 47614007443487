import { StackProps, useDisclosure } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { useAccount } from "wagmi";
import { Button } from "@looksrare/uikit";
import { useUserRakebackData } from "@/hooks/rakeback";
import { PendingRakebacksModal } from "@/components/Modals/PendingRakebacksModal/PendingRakebacksModal";
import { useMapRakebacksToCurrencyRows } from "../hooks/useMapRakebacksToCurrencyRows";
import { RakebackCard } from "./RakebackCard";

export const UnlockingSoonCard = (props: StackProps) => {
  const { t } = useTranslation();

  const { address } = useAccount();
  const breakdownModalDisclosure = useDisclosure();
  const mapToCurrencyRows = useMapRakebacksToCurrencyRows();

  const { data: userRakebackData, refetch: refetchRakebackUserData } = useUserRakebackData(address!, {
    enabled: !!address,
  });

  const lockedRakebacks = userRakebackData?.rakebacks.rakebacks.locked ?? [];
  const currencyRows = mapToCurrencyRows(lockedRakebacks);
  const totalPendingAmountWei = currencyRows.reduce((total, { amountWei }) => total + BigInt(amountWei), 0n);

  const nextRakeback = lockedRakebacks[0];
  const nextRakebackUnlocksAt = nextRakeback ? new Date(nextRakeback.unlocksAt) : new Date();

  return (
    <>
      <RakebackCard
        title={t("Unlocking Soon")}
        timerExpiresAt={nextRakebackUnlocksAt}
        currencyRows={currencyRows}
        showTimer={totalPendingAmountWei > 0n}
        {...props}
      >
        <Button onClick={breakdownModalDisclosure.onOpen} colorScheme="secondary">
          {t("View Breakdown")}
        </Button>
      </RakebackCard>

      <PendingRakebacksModal
        isOpen={breakdownModalDisclosure.isOpen}
        onClose={breakdownModalDisclosure.onClose}
        rakebacks={userRakebackData?.rakebacks.rakebacks}
      />
    </>
  );
};
